import React, { useCallback, useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { isNil } from 'lodash';

import { DropdownOption } from 'components/Dropdown';
import DropdownButton from 'components/DropdownButton';
import { ShareScoopsModalMessage } from 'components/ShareScoopsModalMessage';
import {
  I18N_AVANT_PROPERTY_COMMON_TEXT_PATH,
  I18N_AVANT_PROPERTY_TEXT_PATH,
} from 'constants/i18n';
import { NotificationContext } from 'contexts/NotificationContext';
import { SHARE_ACTIVITIES_MESSAGE_MUTATION } from 'graphql/share';
import { IActivitiesSearch } from 'interfaces/IActivitiesSearch';
import { translateText } from 'utils/i18n';

import { shareActivitiesOptions } from './constants';
import { getStartEndDates } from './utils';

interface Props {
  wrapperClassName?: string;
  queryVariables?: IActivitiesSearch;
  disabled?: boolean;
}

const DEFAULT_ERROR_MESSAGE = translateText(
  `${I18N_AVANT_PROPERTY_COMMON_TEXT_PATH}.unexpectedErrorMessage`,
);
const NO_ACTIVITIES_MESSAGE = translateText(
  `${I18N_AVANT_PROPERTY_TEXT_PATH}.recentActivity.noActivities`,
);

const ShareActivitiesButton = ({
  wrapperClassName,
  queryVariables,
  disabled,
}: Props) => {
  const { setNotification } = useContext(NotificationContext);
  const [showModal, setShowModal] = useState(false);

  const [shareMessage, { error: sendMessageError }] = useMutation(
    SHARE_ACTIVITIES_MESSAGE_MUTATION,
  );

  const handleShareScoop = useCallback(
    async (selectedOption: DropdownOption) => {
      if (!selectedOption) return;

      const { startDate, endDate } = getStartEndDates(selectedOption);
      let error = '';

      try {
        const {
          data: { shareActivitiesMessage },
        } = await shareMessage({
          variables: {
            search: {
              filter: {
                ...queryVariables?.filter,
                startDate: startDate.toString(),
                endDate: endDate.toString(),
              },
              includeScoopsWithNoProperties: true,
              order: queryVariables?.order,
              shouldReturnPricingQuotes: true,
            },
            numberOfDays: parseInt(selectedOption.value),
          },
        });

        if (sendMessageError) {
          error = sendMessageError.message;
        } else if (isNil(shareActivitiesMessage)) {
          error = NO_ACTIVITIES_MESSAGE;
        } else {
          // If there's no error, we show the message modal to the user.
          setShowModal(true);
        }
      } catch (_) {
        error = DEFAULT_ERROR_MESSAGE;
      } finally {
        setNotification({
          title: error,
          show: !!error,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryVariables?.filter, queryVariables?.order, shareMessage],
  );

  return (
    <>
      <DropdownButton
        icon="share"
        options={shareActivitiesOptions}
        onClick={option => handleShareScoop(option)}
        wrapperClassName={wrapperClassName}
        disabled={disabled}
      />
      {showModal && (
        <ShareScoopsModalMessage onClose={() => setShowModal(false)} />
      )}
    </>
  );
};

export default ShareActivitiesButton;
