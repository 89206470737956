import React, { useEffect, useMemo, useState } from 'react';
import { IdName } from 'interfaces/IdName';
import { NetworkStatus, useQuery } from '@apollo/client';
import { ISearchResult } from 'interfaces/ISearchResult';
import { IActivity } from 'interfaces/IActivity';
import { ErrorLogger } from 'services/ErrorLogger';
import ActivityFeedScrollContent from 'components/ActivityFeedScrollContent';
import { uniqBy } from 'lodash';
import { GET_ACTIVITIES_QUERY } from 'graphql/activities';
import { SortDirections } from 'constants/sortDirections';
import { IActivitiesSearchFilters } from 'interfaces/IActivitiesSearch';
import { ICompany } from 'interfaces/ICompany';
import { IProperty } from 'interfaces/IProperty';
import { PropertyTypeNames } from 'constants/propertyTypes';

interface Props {
  filter: IActivitiesSearchFilters;
  preselectedCompany?: ICompany;
  preselectedProperty?: IProperty;
  selectedScoopCategories: IdName[];
  setHasActivities?: (hasActivities: boolean) => void;
  setSelectedScoopCategories: (tags: IdName[]) => void;
  setShowAddScoopModal: (value: boolean) => void;
  showAddScoopModal: boolean;
  feedContentClassName?: string;
  isDropdownFilterSticked?: boolean;
}

const PAGE_SIZE = 15;

const RecentActivityContent: React.FC<Props> = ({
  filter,
  preselectedCompany,
  preselectedProperty,
  selectedScoopCategories,
  setHasActivities,
  setSelectedScoopCategories,
  setShowAddScoopModal,
  showAddScoopModal,
  feedContentClassName,
  isDropdownFilterSticked,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [hasDataChanges, setHasDataChanges] = useState(false);
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);

  const tagsFilter = selectedScoopCategories
    ?.filter(sc => !isNaN(sc.id))
    ?.map(sc => sc.id);

  const getQueryVariables = (page: number) => ({
    variables: {
      search: {
        page: {
          page,
          size: PAGE_SIZE,
        },
        shouldReturnPricingQuotes: true,
        order: {
          field: 'activityDate',
          direction: SortDirections.desc,
        },
        filter: {
          ...filter,
          propertyType: filter.propertyType
            ? PropertyTypeNames[filter.propertyType.id]
            : undefined,
          tags: tagsFilter?.length ? tagsFilter : undefined,
        },
        includeScoopsWithNoProperties: true,
      },
    },
  });

  const { data, loading, refetch, fetchMore, networkStatus } = useQuery<
    ISearchResult<IActivity>
  >(GET_ACTIVITIES_QUERY, {
    ...getQueryVariables(1),
  });

  const activities = useMemo(() => data?.activities?.results || [], [data]);

  const loadNextPage = async () => {
    const hasData = !!data?.activities;
    const isRefetching = networkStatus === NetworkStatus.refetch;
    const hasMorePages = (data?.activities.totalPages || 0) > currentPage;
    const shouldStopLoadNextPage =
      !hasData ||
      loading ||
      isRefetching ||
      !hasMorePages ||
      fetchMore === undefined ||
      isLoadingNextPage;
    if (shouldStopLoadNextPage) return;

    try {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      setIsLoadingNextPage(true);

      await fetchMore({
        ...getQueryVariables(nextPage),

        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }

          const hasResults =
            fetchMoreResult?.activities?.results?.length > 0 && prev;

          if (!hasResults) {
            return prev;
          }

          return {
            activities: {
              ...prev.activities,
              currentPage: prev.activities.currentPage! + 1,
              results: uniqBy(
                [
                  ...prev.activities.results,
                  ...fetchMoreResult.activities.results,
                ],
                'id',
              ),
            },
          };
        },
      });
    } catch (e) {
      ErrorLogger.log(e as any, 'Unexpected error loading more scoops');
    } finally {
      setIsLoadingNextPage(false);
    }
  };

  useEffect(() => {
    if (hasDataChanges) {
      refetch?.();
      setCurrentPage(1);
      setHasDataChanges(false);
    }
  }, [hasDataChanges, refetch]);

  useEffect(() => {
    setHasActivities?.(activities.length > 0);
  }, [setHasActivities, activities]);

  return (
    <ActivityFeedScrollContent
      isDropdownFilterSticked={isDropdownFilterSticked}
      feedContentClassName={feedContentClassName}
      loading={loading}
      refetch={refetch}
      activities={activities}
      preselectedCompany={preselectedCompany}
      preselectedProperty={preselectedProperty}
      loadNextPage={loadNextPage}
      isLoadingNextPage={isLoadingNextPage}
      showAddScoopModal={showAddScoopModal}
      scoopsOnChange={setSelectedScoopCategories}
      setShowAddScoopModal={setShowAddScoopModal}
    />
  );
};

export default RecentActivityContent;
