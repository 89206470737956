import { IProperty } from 'interfaces/IProperty';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import { UnitOfMeasurementCode } from 'constants/unitOfMeasurement';
import { RegionAvailability } from 'components/CreateComps/FormSection/PropertyForm/types';

export const isUKProperty = (property: IProperty | IPropertyInput) =>
  property.propertyCountry?.code === UnitOfMeasurementCode.UK ||
  property.measurementSystem === UnitOfMeasurementCode.UK;

export const updateRegionalFields = (
  property: IPropertyInput,
  updateField: (field: string, value: any) => void,
): IPropertyInput => {
  const isUK = isUKProperty(property);

  if (!isUK) {
    [
      'architectCompany',
      'currentYearTaxAssessment',
      'fitwell',
      'numberOfElevators',
      'planningStatus',
      'siteNumber',
      'siteNumberProviderCompany',
      'uarn',
      'well',
    ].forEach(field => updateField(field, undefined));

    if (property.office) {
      const office = {
        ...(property.office || {}),
        storiesAboveGround: undefined,
        storiesBelowGround: undefined,
      };
      updateField('office', office);
      property.office = office;
    }
  }
  return property;
};

export const updateDataCenterFields = (
  property: IPropertyInput,
  regionAvailability: RegionAvailability,
  updateField: (field: string, value: any) => void,
) => {
  if (!property.dataCenter) return;
  if (regionAvailability.isEurope) {
    updateField('dataCenter', {
      ...(property.dataCenter || {}),
      underConstSf: undefined,
      plannedSf: undefined,
      commissionedSf: undefined,
      raisedFloorHeight: undefined,
      typicalCeilingHeightFeet: undefined,
      typicalCeilingHeightInches: undefined,
    });
  } else if (regionAvailability.isUK) {
    updateField('dataCenter', {
      ...(property.dataCenter || {}),
      underConstMt: undefined,
      plannedMt: undefined,
      commissionedMt: undefined,
      raisedFloorHeightMt: undefined,
      typicalCeilingHeightFeet: undefined,
      typicalCeilingHeightInches: undefined,
    });
  } else {
    updateField('dataCenter', {
      ...(property.dataCenter || {}),
      underConstMt: undefined,
      plannedMt: undefined,
      commissionedMt: undefined,
      raisedFloorHeightMt: undefined,
      typicalCeilingHeightMt: undefined,
    });
  }
};

export const updateMultifamilyFields = (
  property: IPropertyInput,
  regionAvailability: RegionAvailability,
  updateField: (field: string, value: any) => void,
) => {
  if (!property.multifamily) return;
  regionAvailability.isEurope
    ? updateField('multifamily', {
        ...(property.multifamily || {}),
        avgUnitSizeSf: undefined,
      })
    : updateField('multifamily', {
        ...(property.multifamily || {}),
        avgUnitSizeMt: undefined,
      });
};

export const updateIndustrialFields = (
  property: IPropertyInput,
  regionAvailability: RegionAvailability,
  updateField: (field: string, value: any) => void,
) => {
  if (!property.industrial) return;
  if (regionAvailability.isUK || regionAvailability.isEurope) {
    updateField('industrial', {
      ...(property.industrial || {}),
      typicalClearHeightMin: undefined,
      typicalClearHeightMax: undefined,
    });
  } else {
    updateField('industrial', {
      ...(property.industrial || {}),
      typicalClearHeightMinMt: undefined,
      typicalClearHeightMaxMt: undefined,
    });
  }
};

export const updateLandAndDevelopmentFields = (
  regionAvailability: RegionAvailability,
  updateField: (field: string, value: any) => void,
) => {
  if (regionAvailability.isEurope) {
    updateField('siteSize', undefined);
    updateField('siteSizeSf', undefined);
  } else {
    updateField('siteSizeMt', undefined);
    updateField('siteSizeHectares', undefined);
  }
};

export const updateOfficeFields = (
  property: IPropertyInput,
  regionAvailability: RegionAvailability,
  updateField: (field: string, value: any) => void,
) => {
  if (!property.office) return;
  if (regionAvailability.isUK || regionAvailability.isEurope) {
    updateField('office', {
      ...(property.office || {}),
      columnSpacingMin: undefined,
      columnSpacingMax: undefined,
      typicalCeilingHeightFeet: undefined,
      typicalCeilingHeightInches: undefined,
    });
  } else {
    updateField('office', {
      ...(property.office || {}),
      columnSpacingMinMt: undefined,
      columnSpacingMaxMt: undefined,
      typicalCeilingHeightMt: undefined,
    });
  }
};

export const updateLifeScienceFields = (
  property: IPropertyInput,
  regionAvailability: RegionAvailability,
  updateField: (field: string, value: any) => void,
) => {
  if (!property.lifeScience) return;
  if (regionAvailability.isUK || regionAvailability.isEurope) {
    updateField('lifeScience', {
      ...(property.lifeScience || {}),
      columnSpacingMin: undefined,
      columnSpacingMax: undefined,
      typicalCeilingHeightFeet: undefined,
      typicalCeilingHeightInches: undefined,
    });
  } else {
    updateField('lifeScience', {
      ...(property.lifeScience || {}),
      columnSpacingMinMt: undefined,
      columnSpacingMaxMt: undefined,
      typicalCeilingHeightMt: undefined,
    });
  }
};

export const updateBuildingFields = (
  regionAvailability: RegionAvailability,
  updateField: (field: string, value: any) => void,
) => {
  if (regionAvailability.isEurope) {
    updateField('buildingSize', undefined);
    updateField('siteSize', undefined);
    updateField('floorPlateSize', undefined);
  } else {
    updateField('buildingSizeMt', undefined);
    updateField('siteSizeHectares', undefined);
    updateField('floorPlateSizeMt', undefined);
  }
};
