import React from 'react';

import { MarketPricingRentType } from 'interfaces/inputs/IMarketPricingInput';
import RadioInput from 'components/Inputs/RadioInput';
import { I18N_HISTORICAL_PERFORMANCE_TEXT_PATH } from 'components/HistoricalPerformance/constants';
import { I18N_AVANT_PLATFORM_LEASE_LABEL_PATH } from 'constants/i18n';
import {
  TIME_TOGGLE_OPTIONS,
  TimeMeasurement,
} from 'constants/timeMeasurement';
import { RENT_TYPE_OPTIONS } from 'constants/rentTypes';
import { translateText } from 'utils/i18n';

import styles from './SegmentPricing.module.scss';
import { SegmentPricingParams } from '../interfaces';

interface Props {
  segmentPricingParams: SegmentPricingParams | undefined;
  setSegmentPricingParams: (data: SegmentPricingParams) => void;
}

export const RadioControl = ({
  segmentPricingParams,
  setSegmentPricingParams,
}: Props) => (
  <div className={styles['radio-controls']}>
    <div className={styles['radio-controls-section']}>
      {translateText(
        `${I18N_HISTORICAL_PERFORMANCE_TEXT_PATH}.radioControlMessage`,
      )}

      <RadioInput
        selectedItem={segmentPricingParams?.timeMeasurement}
        data={TIME_TOGGLE_OPTIONS}
        onChange={item =>
          setSegmentPricingParams({
            ...segmentPricingParams!,
            timeMeasurement: item.value as TimeMeasurement,
          })
        }
      />
    </div>

    <div className={styles['radio-controls-section']}>
      {translateText(`${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.rentType`)}
      <RadioInput
        selectedItem={segmentPricingParams?.rentType}
        data={RENT_TYPE_OPTIONS}
        onChange={item =>
          setSegmentPricingParams({
            ...segmentPricingParams!,
            rentType: item.value as MarketPricingRentType,
          })
        }
      />
    </div>
  </div>
);
