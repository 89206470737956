import React from 'react';
import { getCurrentPerformanceQuery } from 'components/HistoricalPerformance/Graphs/CurrentPerformance/utils';
import { buildDonutGraphQueryParams } from '../Graphs/CurrentPerformance/utils';
import {
  HISTORICAL_PERFORMANCE_BY_YEAR,
  BUILDING_HISTORICAL_RENT,
} from 'graphql/historicalPerformance/queries';
import { IdName } from 'interfaces/IdName';
import { getMarketIds } from '../Graphs/HistoricalAvailability/utils';

export enum HistoricalPerformanceTabs {
  AVAILABILITY_VACANCY = 'AVAILABILITY_VACANCY',
  SEGMENT_PRICING = 'SEGMENT_PRICING',
}

export type ModalContentType = {
  availability: React.ReactNode;
  segment: React.ReactNode;
};

export const getTabsData = (content: ModalContentType) => [
  {
    name: HistoricalPerformanceTabs.AVAILABILITY_VACANCY,
    header: 'Availability & Vacancy',
    content: content.availability,
  },
  {
    name: HistoricalPerformanceTabs.SEGMENT_PRICING,
    header: 'Segment Pricing',
    content: content.segment,
  },
];

export const getRefetchQueries = (
  companyId?: number,
  propertyId?: number,
  marketsFilter?: IdName[],
  propertySetId?: number,
) => [
  {
    query: getCurrentPerformanceQuery(!!companyId),
    variables: buildDonutGraphQueryParams(propertyId, companyId, marketsFilter),
  },
  {
    query: HISTORICAL_PERFORMANCE_BY_YEAR,
    variables: {
      propertyId,
      companyId,
      marketIds: getMarketIds(marketsFilter),
      propertySetId,
    },
  },
  {
    query: BUILDING_HISTORICAL_RENT,
    variables: {
      propertyId,
      propertySetId,
    },
  },
];
