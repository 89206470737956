import { translateText } from 'utils/i18n';
import { LeaseRentType } from './leases';

export enum RentTypes {
  RENT_TYPE = 'rentType',
  FREE_RENT_TYPE = 'freeRentType',
  ASKING_RENT_TYPE = 'askingRentType',
}

export const freeRentTypes = {
  [LeaseRentType.FS]: 1,
  [LeaseRentType.NNN]: 2,
  [LeaseRentType.MG]: 3,
  [LeaseRentType.IG]: 4,
  [LeaseRentType.NN]: 5,
  [LeaseRentType.N]: 6,
};

export const RENT_TYPE_OPTIONS = [
  {
    label: translateText('avantPlatform.attributes.lease.label.rentTypeFS'),
    value: LeaseRentType.FS,
  },
  {
    label: translateText('avantPlatform.attributes.lease.label.rentTypeNNN'),
    value: LeaseRentType.NNN,
  },
];
