import {
  CustomSearchCriteriaType,
  LeasesSearchCriteriaType,
  SalesSearchCriteriaType,
  SearchCriteriaType,
} from 'components/FindComps/EditSearchCriteria/types';
import { PropertyTypes } from 'constants/propertyTypes';
import { LEASE_RECENT_TIME_OPTIONS } from 'constants/leaseTime';
import { LEASE_SORT_OPTIONS, RECENT_LEASES_ID } from 'constants/leaseSort';
import { PAST_2_YEARS_ID, SALE_DATE_OPTIONS } from 'constants/saleDate';
import { IProperty } from 'interfaces/IProperty';
import { IPropertySet } from 'interfaces/inputs/IPropertySet';
import { SearchParamsService } from 'services/SearchParamsService';
import { idNameBuilder } from 'utils/objects';
import { removeTypenameKey } from 'utils/graphql/typename';

export const SHORT_VERSION_PREFIX = '&short=1';
export const CUSTOM_CRITERIA_PREFIX = 'c';
export const OPEN_SELECTION_PREFIX = 'o';
export const LEASES_PREFIX = 'l';
export const SALES_PREFIX = 's';
export const TIMS_PREFIX = 't';
export const BUILDING_SET_PREFIX = 'b';

export class FindCompsSearchCriteriaService {
  public static parseFiltersToUrlParams(filters: SearchCriteriaType): string {
    const finalFilters = filters;
    const buildingSetFilter = finalFilters.openSelectionCriteria?.list;
    let defaultCompSet;
    // Clear the properties in the list, in order to send only ids in the URL.
    //if no id, we have default comp set
    if (buildingSetFilter?.properties?.length) {
      if (!buildingSetFilter.id) {
        defaultCompSet = true;
        const property = buildingSetFilter.targetProperty;
        const cleanPropertyData = removeTypenameKey(property);

        const isIndustrial =
          cleanPropertyData?.propertyType?.name === PropertyTypes.industrial;
        const isFlexIndustrial =
          cleanPropertyData?.propertyType?.name ===
          PropertyTypes.flexIndustrial;

        finalFilters.openSelectionCriteria =
          isIndustrial || isFlexIndustrial
            ? {
                markets: property?.market,
                submarkets: property?.submarket,
                propertyTypes: property?.propertyType,
                subtypes: property?.propertySubtype,
                classes: property?.propertyClass,
              }
            : {
                markets: property?.market,
                submarkets: property?.submarket,
                propertyTypes: property?.propertyType,
                classes: property?.propertyClass,
              };

        FindCompsSearchCriteriaService.removeUndefinedKeys(
          finalFilters.openSelectionCriteria,
        );
      } else {
        // These 2 variables are to avoid DRY.
        const propertyIds = buildingSetFilter?.properties.map(p => p.id!);
        const targetPropertyId = buildingSetFilter?.targetProperty?.id;

        const propertiesIds = !!targetPropertyId
          ? propertyIds.concat(targetPropertyId)
          : propertyIds;
        const listSearch = {
          id: buildingSetFilter.id,
          name: buildingSetFilter.name,
          properties: propertiesIds,
          targetPropertyId: buildingSetFilter?.targetProperty?.id,
        };
        finalFilters.openSelectionCriteria = {
          listSearch,
          listSearchPropertiesIds: propertiesIds,
        };
      }
    }
    const customCriteria = SearchParamsService.parseCustomCriteriaObjectToURLParams(
      finalFilters.customCriteria,
    );
    const openSelectionCriteria = SearchParamsService.parseOpenSelectionCriteriaToURLParams(
      finalFilters.openSelectionCriteria,
      defaultCompSet,
    );

    const leasesCriteria = SearchParamsService.parseLeaseCriteriaToURLParams(
      finalFilters.leasesCriteria,
    );
    const timsCriteria = SearchParamsService.parseLeaseCriteriaToURLParams(
      finalFilters.timsCriteria,
    );
    const salesCriteria = SearchParamsService.parseSaleCriteriaToURLParams(
      finalFilters.salesCriteria,
    );

    const url = [
      customCriteria,
      openSelectionCriteria,
      leasesCriteria,
      salesCriteria,
      timsCriteria,
    ]
      .filter(Boolean)
      .join('&');

    return `${url}${SHORT_VERSION_PREFIX}`;
  }

  public static parseUrlParamsToFilters(
    urlParams: string | null,
  ): SearchCriteriaType {
    if (!urlParams) return {};

    const searchCriteria = SearchParamsService.parseUrlParamsToObject(
      urlParams,
    );

    return {
      customCriteria: searchCriteria[CUSTOM_CRITERIA_PREFIX],
      openSelectionCriteria: searchCriteria[OPEN_SELECTION_PREFIX],
      leasesCriteria: searchCriteria[LEASES_PREFIX],
      salesCriteria: searchCriteria[SALES_PREFIX],
      timsCriteria: searchCriteria[TIMS_PREFIX],
    };
  }

  /**
   * This function builds the query params to redirect a Property to the FindComps page.
   *
   * Usage:
   *    const findCompsURL = locations.findComps({
   *      [FIND_COMPS_QUERY_PARAM]: SearchCriteriaService.buildPropertyFilters(property),
   *    });
   */
  public static buildPropertyFilters(
    property: IProperty,
    addLeaseSortOption = false,
    addSaleSortOption = false,
    customCriteria?: CustomSearchCriteriaType,
    leasesCriteria?: LeasesSearchCriteriaType,
    salesCriteria?: SalesSearchCriteriaType,
  ): string {
    const {
      market,
      submarket,
      propertyType,
      propertyClass,
      propertySubtype,
    } = property;

    // Market is required
    if (!market?.id || !market.name) return '';

    const searchCriteria: SearchCriteriaType = {
      customCriteria: customCriteria || {},
      leasesCriteria: leasesCriteria || {},
      salesCriteria: salesCriteria || {},
    };

    searchCriteria.customCriteria!.markets = [idNameBuilder(market)];

    searchCriteria.customCriteria!.types = propertyType ? [propertyType] : [];
    !!submarket &&
      (searchCriteria.customCriteria!.submarkets = [idNameBuilder(submarket)]);

    if (propertyType?.name === PropertyTypes.office) {
      !!propertyClass?.id &&
        (searchCriteria.customCriteria!.classes = [
          idNameBuilder(propertyClass),
        ]);
    } else if (propertySubtype?.id) {
      searchCriteria.customCriteria!.subtypes = [
        idNameBuilder(propertySubtype),
      ];
    }

    /**
     * Check if it should sort leases by recent and filter by the last 2 years
     */
    if (addLeaseSortOption) {
      const leasesSortOption = LEASE_SORT_OPTIONS.find(
        o => o.id === RECENT_LEASES_ID,
      );

      searchCriteria.leasesCriteria!.sort = idNameBuilder(leasesSortOption!);
      searchCriteria.leasesCriteria!.signTime = LEASE_RECENT_TIME_OPTIONS[0];
    }

    /**
     * Check if it should sort sales by date and filter by the last 2 years
     */
    if (addSaleSortOption) {
      const saleDateFilter = SALE_DATE_OPTIONS.find(
        o => o.id === PAST_2_YEARS_ID,
      );
      searchCriteria.salesCriteria!.date = idNameBuilder(saleDateFilter!);
    }

    return this.parseFiltersToUrlParams(searchCriteria);
  }

  public static buildCompSetFilters(
    activePropertySet?: IPropertySet | null,
  ): string {
    const searchCriteria: SearchCriteriaType = {
      customCriteria: {},
      leasesCriteria: {},
      salesCriteria: {},
      openSelectionCriteria: {
        list: activePropertySet,
      },
    };
    return this.parseFiltersToUrlParams(searchCriteria);
  }

  public static removeUndefinedKeys(filters: any) {
    for (const key of Object.keys(filters)) {
      if (!filters[key]) {
        delete filters[key];
      }
    }
  }
}
