import React from 'react';
import classnames from 'classnames';

import { LeaseRentType } from 'constants/leases';
import { TIME_SWITCH_TOGGLE_OPTIONS } from 'constants/timeMeasurement';
import SwitchOptionButton from 'components/SwitchOptionButton';
import { getTranslatedNNN, getTranslatedFS } from 'utils/leases';

import styles from './LeaseToggles.module.scss';

export type LeaseToggleOptions = {
  rentType: string;
  timeMeasurement: string;
};

type Props = {
  className?: string;
  rentType: string;
  timeMeasurement: string;
  handleChange: Function;
};

const LeaseToggles: React.FC<Props> = ({
  className,
  rentType,
  timeMeasurement,
  handleChange,
}) => (
  <div className={classnames(styles['lease-toggles'], className)}>
    {rentType && (
      <SwitchOptionButton
        buttonClass={styles['switch-button']}
        wrapperClassName={styles['types-switch']}
        activeOption={{
          value: rentType,
          text: rentType,
        }}
        onChange={({ value }) => handleChange({ rentType: value })}
        options={[
          {
            value: LeaseRentType.NNN,
            text: getTranslatedNNN(),
          },
          {
            value: LeaseRentType.FS,
            text: getTranslatedFS(),
          },
        ]}
      />
    )}
    {timeMeasurement && (
      <SwitchOptionButton
        buttonClass={styles['switch-button']}
        wrapperClassName={styles['types-switch']}
        activeOption={{
          value: timeMeasurement,
          text: timeMeasurement,
        }}
        onChange={({ value }) => handleChange({ timeMeasurement: value })}
        options={TIME_SWITCH_TOGGLE_OPTIONS}
      />
    )}
  </div>
);

export default LeaseToggles;
