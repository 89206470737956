import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { ISaleInput } from 'interfaces/inputs/ISaleInput';
import { IProperty } from 'interfaces/IProperty';
import { IdName } from 'interfaces/IdName';
import ISale from 'interfaces/ISale';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import {
  getUnitOfMeasurementForSale,
  updateSaleFieldsBasedOnUnit,
} from 'components/CreateComps/FormSection/SaleForm/unitOfMeasurementFields';
import FormFooter from 'components/FormFooter';
import UpdateSummary, { getUpdateSummaryData } from 'components/UpdateSummary';
import { CountryCodes } from 'constants/countryCodes';
import { useProperty } from 'hooks/useProperty';
import { getCurrencySymbol } from 'utils/formatters/currency';
import { formatNumberWithDecimals } from 'utils/formatters/number';
import { fieldHasValue } from 'utils/objects';
import { isCondoSale } from 'utils/sales';
import {
  getFieldNameForSale,
  getFieldValueForProperty,
  getFieldValueForSale,
} from 'utils/unitsOfMeasurement';

import SaleTypes from './SaleTypes';
import SaleDetails from './SaleDetails';
import BasicInformation from './BasicInformation';
import DatesAndCompanies from './DatesAndCompanies';
import { handleFormStatusUpdate } from '../formUtils';
import { processSaleBeforeSubmit } from './processSaleBeforeSubmit';
import { processSaleBeforeEdit } from './processSaleBeforeEdit';
import { convertIdNameArray } from '../converters';
import { getSaleDateSuggestions } from './formDates';
import styles from '../FormSection.module.scss';

const DEFAULT_REQUIRED_FIELDS = ['date', 'interestPercent'];
const REQUIRED_OBJECT_FIELDS = {
  property: 'property',
};

export interface Props {
  sale?: ISaleInput;
  sourceSale?: ISale;
  onSubmitForm: (saleInput: ISaleInput) => void;
  onCancel?: () => void;
  submitLabel?: string;
  isSubmitting?: boolean;
  salePropertyBuildingSize?: number;
  sourceProperty?: IProperty;
}

export interface SaleFormSectionProps {
  saleInput: ISaleInput;
  updateSaleProperty: (property: string, propertyValue: any) => void;
}

const SaleForm: React.FC<Props> = ({
  sale,
  onSubmitForm,
  onCancel,
  submitLabel,
  isSubmitting,
  salePropertyBuildingSize,
  sourceProperty,
  sourceSale,
}) => {
  const processedSale = processSaleBeforeEdit(sale || {});
  const saleDateSuggestions = getSaleDateSuggestions(processedSale.date);

  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [saleInput, setSaleInput] = useState<ISaleInput>(processedSale);

  const { property: saleProperty } = useProperty(saleInput.property?.id);

  const [
    temporarySalePropertyBuildingSize,
    setTemporarySalePropertyBuildingSize,
  ] = useState<number | undefined>(salePropertyBuildingSize);

  const [unitOfMeasurement, setUnitOfMeasurement] = useState<string>(
    UnitOfMeasurement.sf,
  );

  const isMultiFamilyProperty = saleProperty?.propertyType?.id === 4;
  const isUkSales = saleProperty?.currencyCode === 'GBP';

  const saleCurrencySymbol = getCurrencySymbol(saleProperty?.currencyCode);
  const updateSummaryValue =
    sourceSale &&
    sourceSale.createdAt &&
    sourceSale.createdUser &&
    getUpdateSummaryData(
      sourceSale.createdAt,
      sourceSale.createdUser,
      sourceSale.updatedAt,
      sourceSale.updatedUser,
    );

  const updateSaleProperty = (property: string, propertyValue: any) => {
    setSaleInput(value => ({ ...value, [property]: propertyValue }));
  };

  const isValidForm = () => {
    const { property } = REQUIRED_OBJECT_FIELDS;
    const requiredFields = isCondoSale(saleInput)
      ? [
          ...DEFAULT_REQUIRED_FIELDS,
          'floor',
          getFieldNameForSale(
            'sf',
            saleInput,
            saleProperty?.propertyCountry?.code ||
              saleProperty?.measurementSystem,
          ),
        ]
      : DEFAULT_REQUIRED_FIELDS;

    return (
      requiredFields.every(field => fieldHasValue(saleInput[field])) &&
      !!saleInput[property] &&
      !!Object.keys(saleInput[property])?.length
    );
  };

  const onSubmit = () => {
    const isUk = saleProperty?.measurementSystem === CountryCodes.UK;
    onSubmitForm(processSaleBeforeSubmit(saleInput, isUk));
  };

  const updatePricePsf = (
    salePrice?: number,
    saleType?: IdName,
    condoSize?: number,
  ) => {
    const isCondo = isCondoSale({ type: saleType });
    const finalCondoSize =
      condoSize ||
      getFieldValueForSale(
        'sf',
        saleInput,
        saleProperty?.propertyCountry?.code || saleProperty?.measurementSystem,
      );

    if (fieldHasValue(salePrice)) {
      const size = isCondo
        ? +finalCondoSize
        : temporarySalePropertyBuildingSize;
      updateSaleProperty(
        'pricePerSF',
        size ? formatNumberWithDecimals(salePrice! / size) : 0,
      );
    }
  };

  const handleSelectProperty = React.useCallback((property: IProperty) => {
    updateSaleProperty('property', {
      id: property.id,
      name: property.name,
      primaryAddress: property.primaryAddress,
      propertyTypeId: property.propertyTypeId || property?.propertyType?.id,
      marketId: property.marketId || property.market?.id,
    });

    const buildingSize = getFieldValueForProperty('buildingSize', property);
    setTemporarySalePropertyBuildingSize(buildingSize);

    if (property.owners?.length) {
      updateSaleProperty('saleSellers', convertIdNameArray(property.owners));
    }

    updateSaleProperty('currencyCode', property.currencyCode);
    updateSaleProperty('pricePerSF', undefined);
    updateSaleProperty('price', undefined);
    updateSaleProperty('interestPercent', undefined);
    updateSaleProperty('partialInvVol', undefined);
  }, []);

  const calculatePricePerUnit = useCallback(() => {
    if (!saleInput?.price || !saleInput?.units) {
      updateSaleProperty('pricePerUnit', null);
      return;
    }
    const pricePerUnit = +formatNumberWithDecimals(
      +saleInput.price / +saleInput?.units!,
    );
    updateSaleProperty('pricePerUnit', pricePerUnit);
  }, [saleInput.price, saleInput.units]);

  useEffect(() => {
    if (!saleProperty) return;

    const unit = getUnitOfMeasurementForSale(saleProperty);
    setUnitOfMeasurement(unit);
    updateSaleFieldsBasedOnUnit(unit, updateSaleProperty);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleProperty]);

  useEffect(
    () => handleFormStatusUpdate(processedSale, saleInput, setIsDirty),
    [saleInput, isDirty, processedSale],
  );

  useEffect(() => {
    if (isMultiFamilyProperty) {
      updateSaleProperty('units', saleProperty?.multifamily?.totalUnits);
    }
  }, [isMultiFamilyProperty, saleProperty?.multifamily?.totalUnits]);

  useEffect(() => {
    if (sourceProperty) {
      handleSelectProperty(sourceProperty);
    }
  }, [sourceProperty, handleSelectProperty]);

  useEffect(() => {
    calculatePricePerUnit();
  }, [calculatePricePerUnit]);

  return (
    <>
      <div
        className={classNames(styles.container, {
          [styles['is-last']]: !updateSummaryValue,
        })}
      >
        <BasicInformation
          saleInput={saleInput}
          handleSelectProperty={handleSelectProperty}
          updateSaleProperty={updateSaleProperty}
          unitOfMeasurement={unitOfMeasurement}
          saleCurrencySymbol={saleCurrencySymbol}
          updatePricePsf={updatePricePsf}
          isMultiFamilyProperty={isMultiFamilyProperty}
          isUkSales={isUkSales}
        />

        <DatesAndCompanies
          saleInput={saleInput}
          updateSaleProperty={updateSaleProperty}
          saleDateSuggestions={saleDateSuggestions}
          isUkSales={isUkSales}
        />

        <SaleTypes
          saleCurrencySymbol={saleCurrencySymbol}
          saleInput={saleInput}
          updateSaleProperty={updateSaleProperty}
          updatePricePsf={updatePricePsf}
          unitOfMeasurement={unitOfMeasurement}
          saleProperty={saleProperty}
        />

        <SaleDetails
          saleInput={saleInput}
          updateSaleProperty={updateSaleProperty}
          processedSale={processedSale}
        />
      </div>
      {updateSummaryValue && (
        <UpdateSummary
          data={updateSummaryValue}
          wrapperClassName={classNames(styles.container, styles['is-last'])}
        />
      )}
      <FormFooter
        onSubmit={onSubmit}
        onCancel={onCancel!}
        isCancelDisabled={isSubmitting}
        submitButtonLabel={submitLabel}
        isSubmitDisabled={isSubmitting || !isValidForm() || !isDirty}
      />
    </>
  );
};

export default SaleForm;
