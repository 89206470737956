export const REGEX_INTEGERS = /^[0-9]*$/;
export const REGEX_PROPERTY_SUBCLASS = /[+-]/g;
export const REGEX_EMAIL_FORMAT = /^[^\s@]+@[^\s@]+\.[^\s@]+$/g;
export const REGEX_EMPTY_SPACES = /\s/g;
export const REGEX_DECIMALS = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/;
export const REGEX_DECIMALS_WITH_COMMA = /^\d+(,\d+)*(,|\.\d{0,2})?$/;
export const REGEX_PERCENTAGE = /^(100(\.0{0,2})?|\d{1,2}(\.\d{0,2})?|0(\.\d{1,2})?)$/;
export const REGEX_NEGATIVE_PERCENTAGE = /^-?\d+$/;
export const REGEX_THREE_DECIMALS = /^(\d+(\.\d{0,3})?|\.?\d{1,2})$/;
export const REGEX_THOUSAND_SEP = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g;
export const REGEX_THOUSAND_SEP_WITH_DECIMALS = /^(\d*,?(,?\d+)*,?(\d+(\.?\d{0,2}))?|\.?\d{1,2})$/;
export const REGEX_CURRENCY = /^(?:\d{1,3}){1}(?:([,]\d{3})+)?([.]\d{1,2})?$/;
