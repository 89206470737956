import { isEmpty, maxBy, meanBy, noop } from 'lodash';
import mapboxgl, { Layer } from 'mapbox-gl';

import {
  IAmenitiesGroup,
  IAmenitiesPOILocation,
  IAmenityPOI,
} from 'interfaces/IAmenityPOI';
import { IProperty } from 'interfaces/IProperty';
import {
  generateMarkers,
  generateMarkersForAmenities,
  getGeoJSON,
  PIN_TYPES,
  PinTypes,
} from 'utils/maps/mapBox';

import {
  HEATMAP_COLORS,
  HEATMAP_LAYER,
  HEATMAP_SOURCE,
  MapConstants,
  VisibilityLayer,
} from './constants';

export interface ICoords {
  latitude: number | null;
  longitude: number | null;
}

interface IHeatMapStatus {
  min: number;
  max: number;
  avg: number;
}

export const createMap = (
  container: string | HTMLElement,
  center: any,
  zoom: number,
  interactive = true,
  attributionControl = true,
) =>
  new mapboxgl.Map({
    container,
    style: window._env_.MAPBOX_STYLE_URL,
    center,
    zoom,
    interactive,
    attributionControl,
    logoPosition: attributionControl ? 'bottom-left' : 'bottom-right',
  });

export const addMarker = (
  map: mapboxgl.Map,
  lat: number,
  long: number,
  markerClass: string,
  pinType: PinTypes | undefined = PinTypes.default,
) => {
  const pin = document.createElement('div');
  if (pinType && pinType !== PIN_TYPES['default']) {
    pin.innerHTML = PIN_TYPES[pinType];
  }

  pin.className = markerClass || '';
  pin.style.transform = 'rotate(55deg)';

  return new mapboxgl.Marker(pin).setLngLat([long!, lat!]).addTo(map);
};

/**
 *
 * @param map
 * @param heatMapStatus
 */
export const addHeatmapLayer = (
  map: mapboxgl.Map,
  heatMapStatus: IHeatMapStatus,
  sourceId = HEATMAP_SOURCE,
  visibility = VisibilityLayer.none,
  restOfLayerOptions: Omit<
    Layer,
    'id' | 'type' | 'source' | 'paint' | 'layout'
  > = {},
) => {
  if (!map || map.getLayer(HEATMAP_LAYER)) return;

  map.addLayer({
    id: HEATMAP_LAYER,
    type: 'heatmap',
    source: sourceId,
    paint: {
      'heatmap-color': [
        'interpolate-hcl',
        ['linear'],
        ['heatmap-density'],
        0,
        'transparent',
        0.17,
        HEATMAP_COLORS[1],
        0.33,
        HEATMAP_COLORS[2],
        0.5,
        HEATMAP_COLORS[3],
        0.67,
        HEATMAP_COLORS[4],
        0.83,
        HEATMAP_COLORS[5],
        1.0,
        HEATMAP_COLORS[6],
      ],
      'heatmap-opacity': 0.8,
      'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 5, 22, 50],
      'heatmap-intensity': 2,
      'heatmap-weight': [
        'interpolate',
        ['linear'],
        ['get', MapConstants.weightPropertyName],
        heatMapStatus.min,
        0,
        heatMapStatus.avg,
        0.5,
        heatMapStatus.max,
        1,
      ],
    },
    layout: {
      visibility,
    },
    ...restOfLayerOptions,
  });
};

const addHeatmapSource = (map: mapboxgl.Map, properties: IProperty[]) => {
  if (!map) return;

  const heatmapGeoJSON = getGeoJSON(properties, property => ({
    propertyId: property.id,
    [MapConstants.weightPropertyName]: property.buildingSize,
  }));

  if (!map.getSource(HEATMAP_SOURCE)) {
    map.addSource(HEATMAP_SOURCE, {
      type: 'geojson',
      data: heatmapGeoJSON,
    });
  }
};

export const setHeatmapLayerVisibility = (
  map: mapboxgl.Map,
  visibility: VisibilityLayer,
) => {
  if (map.getLayer(HEATMAP_LAYER)) {
    map.setLayoutProperty(HEATMAP_LAYER, 'visibility', visibility);
  }
};

export const generateHeatMapData = (
  map: mapboxgl.Map,
  properties?: IProperty[],
  isMapReady?: boolean,
) => {
  if (!map || !properties?.length) return;

  const isStyleLoaded = map.isStyleLoaded();

  if (isStyleLoaded || isMapReady) {
    addHeatmapSource(map, properties);

    const max = maxBy(properties, 'buildingSize')?.buildingSize || 0;
    const avg = meanBy(properties, 'buildingSize');

    addHeatmapLayer(map, { min: 0, max, avg });
  }
};

// TODO: We should improve this function since it's pretty similar to `loadPropertiesPinsOnMap`.
export const loadPropertyPinOnMap = (
  map: mapboxgl.Map,
  property: IProperty,
) => {
  const geoJSON = getGeoJSON([property], property => ({
    propertyId: property.id,
  }));
  const bounds = new mapboxgl.LngLatBounds();

  geoJSON.features.forEach(marker => {
    const pin = generateMarkers(marker, true);

    if (pin) {
      pin.getElement().style.pointerEvents = 'none';
      const lng = marker?.geometry?.coordinates?.[0];
      const lat = marker?.geometry?.coordinates?.[1];

      if (lng && lat) {
        bounds.extend([lng, lat]);
        pin.setLngLat([lng, lat]).addTo(map);
      }
    }
  });

  map.fitBounds(bounds, { padding: 50, maxZoom: 13 });
};

export const loadPropertiesPinsOnMap = (
  map: mapboxgl.Map,
  properties?: IProperty[],
  onClickPropertyPin?: (propertyId: string | null) => void,
  pinLabelFn?: (properties: IProperty[], propertyId: number) => number | string,
  isPinOpaque?: boolean,
  onMouseEnterPropertyPin?: (propertyId?: number) => void,
  targetPropertyIds?: (number | undefined)[],
  maxZoom?: number,
  pinType?: PinTypes,
) => {
  if (!properties) return;
  const markers: mapboxgl.Marker[] = [];

  const geoJSON = getGeoJSON(properties, property => ({
    propertyId: property.id,
    onClickPropertyPin,
    label: pinLabelFn?.(properties, property.id!),
    onMouseEnterPropertyPin,
    targetPropertyId: targetPropertyIds?.includes(Number(property.id))
      ? property?.id
      : undefined,
  }));
  const bounds = new mapboxgl.LngLatBounds();

  geoJSON.features.forEach(marker => {
    const pin = generateMarkers(marker, true, isPinOpaque, pinType);

    if (pin) {
      const lng = marker?.geometry?.coordinates?.[0];
      const lat = marker?.geometry?.coordinates?.[1];

      if (lng && lat) {
        bounds.extend([lng, lat]);
        pin.setLngLat([lng, lat]).addTo(map);
      }

      markers.push(pin);
    }
  });

  map.fitBounds(bounds, { padding: 50, maxZoom: maxZoom || 13 });
  return markers;
};

interface LoadAmenitiesPinsOnMapProps {
  map: mapboxgl.Map;
  amenitiesLocations?: IAmenitiesPOILocation[];
  getAmenityPinColorByLocation: (
    amenitiesLocation: IAmenitiesPOILocation,
  ) => string;
  getAmenityPinColor: (amenity: IAmenityPOI) => string;
  getPinValueByLocation: (
    amenitiesLocation: IAmenitiesPOILocation,
    amenitiesTopFive?: IAmenitiesGroup[],
  ) => string;
  getPinValue: (amenity: IAmenityPOI) => string;
  getPinId: (amenity: IAmenityPOI) => string;
  isOutOfTopFive: (amenitiesLocation?: IAmenitiesPOILocation) => boolean;
  maxZoom?: number;
  amenitiesTopFive?: IAmenitiesGroup[];
}

export const loadAmenitiesPinsOnMap = ({
  map,
  amenitiesLocations,
  getAmenityPinColorByLocation,
  getAmenityPinColor,
  getPinValueByLocation,
  getPinValue,
  getPinId,
  isOutOfTopFive,
  maxZoom,
  amenitiesTopFive,
}: LoadAmenitiesPinsOnMapProps) => {
  const markers: mapboxgl.Marker[] = [];
  if (isEmpty(amenitiesLocations)) return markers;

  const geoJSON = getGeoJSON(amenitiesLocations!, amenityLocation => ({
    id: amenityLocation.id,
    amenities: amenityLocation.amenities,
    amenitiesLocation: amenityLocation,
  }));
  const bounds = new mapboxgl.LngLatBounds();

  geoJSON.features.forEach(marker => {
    const pin = generateMarkersForAmenities({
      marker,
      getAmenityPinColor,
      getAmenityPinColorByLocation,
      getPinValueByLocation,
      getPinValue,
      getPinId,
      isOutOfTopFive,
      amenitiesTopFive,
    });
    const lng = marker?.geometry?.coordinates?.[0];
    const lat = marker?.geometry?.coordinates?.[1];

    if (lng && lat) {
      bounds.extend([lng, lat]);
      pin.setLngLat([lng, lat]).addTo(map);
    }

    markers.push(pin);
  });

  map.fitBounds(bounds, { padding: 50, maxZoom: maxZoom || 13 });
  return markers;
};

export const clearCurrentPins = (currentPins: mapboxgl.Marker[]) => {
  currentPins.forEach(pin => {
    pin.remove();
  });
};

export const loadImage = (
  map: mapboxgl.Map,
  image: string,
  imageId: string,
  callback: () => void = noop,
) => {
  if (!map) {
    return;
  }

  map.loadImage(image, (error: any, image: any) => {
    if (!error) {
      map.addImage(imageId, image);
      callback();
    }
  });
};
