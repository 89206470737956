import React, { useState, useMemo } from 'react';

import Tabs, { TabType } from 'components/Tabs';
import ConfirmationModal from 'components/ConfirmationModal';
import LoadingMessage from 'components/LoadingMessage';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { ModelsWithUnitsOfMeasurement } from 'constants/unitOfMeasurement';
import { TimeMeasurement } from 'constants/timeMeasurement';
import { MarketPricingRentType } from 'interfaces/inputs/IMarketPricingInput';
import { IdName } from 'interfaces/IdName';
import { IProperty } from 'interfaces/IProperty';
import { getUnitOfMeasurementForProperty } from 'utils/unitsOfMeasurement';
import { getMissingFields } from 'utils/propertySections';
import { translateText } from 'utils/i18n';

import {
  I18N_HISTORICAL_PERFORMANCE_LABEL_PATH,
  I18N_HISTORICAL_PERFORMANCE_TEXT_PATH,
} from '../constants';
import { useProperty } from 'hooks/useProperty';
import ErrorMessage from './ErrorMessage';
import { ConfirmUnsavedChanges, SegmentPricingParams } from './interfaces';
import HistoricalPerformanceModal from '.';

import {
  getTabsData,
  HistoricalPerformanceTabs,
  ModalContentType,
} from './utils';
import { SegmentPricing } from './SegmentPricing';
import { AvailabilityVacancy } from './AvailabilityVacancy';

type Props = {
  propertyId: number;
  onClose: () => void;
  marketsFilter?: IdName[];
  propertySetId?: number;
  companyId?: number;
};

const INITIAL_SEGMENT_PARAMS = {
  timeMeasurement: TimeMeasurement.ANNUAL,
  rentType: MarketPricingRentType.FS,
  currencyCode: '',
  market: null,
  propertyClass: null,
  propertyId: 0,
  propertySubtype: null,
  propertyType: null,
  submarket: null,
  unitOfMeasurement: '',
};

const EditHistoricalPerformance: React.FC<Props> = ({
  propertyId,
  onClose,
}) => {
  const [activeTabName, setActiveTabName] = useState<string>(
    HistoricalPerformanceTabs.AVAILABILITY_VACANCY,
  );
  const [segmentPricingParams, setSegmentPricingParams] = useState<
    SegmentPricingParams
  >(INITIAL_SEGMENT_PARAMS);
  const [unsavedChanges, setUnsavedChanges] = useState<ConfirmUnsavedChanges>({
    onConfirm: undefined,
    hasChanges: false,
    isConfirmModalVisible: false,
    shouldResetAvailability: false,
    shouldResetSegment: false,
  });

  const onPropertyLoadComplete = (property: IProperty) => {
    if (!property) return;

    const unitOfMeasurement = getUnitOfMeasurementForProperty(
      'buildingSize',
      ModelsWithUnitsOfMeasurement.Property,
      property?.propertyCountry?.code || property?.measurementSystem,
    );
    const currencyCode =
      property.propertyCountry?.currencyCode || property.currencyCode;

    setSegmentPricingParams({
      ...segmentPricingParams,
      market: property.market,
      propertyClass: property.propertyClass,
      propertyId: property.id,
      propertySubtype: property.propertySubtype,
      propertyType: property.propertyType,
      submarket: property.submarket,
      currencyCode,
      unitOfMeasurement,
    } as SegmentPricingParams);
  };

  const { property, isLoading: isLoadingProperty } = useProperty(
    propertyId,
    onPropertyLoadComplete,
  );

  const onCloseConfirmModal = () =>
    setUnsavedChanges({
      ...unsavedChanges,
      isConfirmModalVisible: false,
    });

  const confirmChangeTab = (tab: TabType) => {
    if (unsavedChanges.hasChanges) {
      setUnsavedChanges({
        ...unsavedChanges,
        isConfirmModalVisible: true,
        onConfirm: () => {
          if (tab) setActiveTabName(tab?.name);
          onCloseConfirmModal();
        },
      });
    } else {
      setActiveTabName(tab.name);
    }
  };

  const confirmOnClose = () => {
    if (unsavedChanges.hasChanges) {
      setUnsavedChanges({
        ...unsavedChanges,
        isConfirmModalVisible: true,
        onConfirm: () => {
          onClose();
          onCloseConfirmModal();
        },
      });
    } else {
      onClose();
    }
  };

  const undoUnsavedChanges = () => {
    unsavedChanges.onConfirm?.();
    setUnsavedChanges({
      ...unsavedChanges,
      hasChanges: false,
      isConfirmModalVisible: false,
      shouldResetAvailability:
        activeTabName === HistoricalPerformanceTabs.AVAILABILITY_VACANCY,
      shouldResetSegment:
        activeTabName === HistoricalPerformanceTabs.SEGMENT_PRICING,
    });
  };

  const canModifyData = useMemo(
    () =>
      !!segmentPricingParams?.market?.id &&
      !!segmentPricingParams?.submarket?.id &&
      !!segmentPricingParams?.propertyClass?.id &&
      !!segmentPricingParams?.propertySubtype?.id &&
      !!segmentPricingParams?.propertyType?.id,
    [segmentPricingParams],
  );

  const renderSegmentPricingContent = () => {
    if (canModifyData) {
      return (
        <>
          <SegmentPricing
            segmentPricingParams={segmentPricingParams}
            setSegmentPricingParams={setSegmentPricingParams}
            onClose={confirmOnClose}
          />
        </>
      );
    }

    const missingFields = getMissingFields(segmentPricingParams, [
      {
        id: 'market',
        name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.market`),
      },
      {
        id: 'submarket',
        name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.submarket`),
      },
      {
        id: 'propertyClass',
        name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.class`),
      },
      {
        id: 'propertySubtype',
        name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.subtype`),
      },
      {
        id: 'propertyType',
        name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.type`),
      },
    ]);

    return (
      <ErrorMessage
        message={translateText(
          `${I18N_HISTORICAL_PERFORMANCE_TEXT_PATH}.editDisabledError`,
          {
            count: missingFields.length,
            missingFields: missingFields.join(', '),
          },
        )}
      />
    );
  };

  const renderAvailabilityContent = () => {
    if (isLoadingProperty) return <LoadingMessage />;

    return (
      <AvailabilityVacancy
        propertyId={propertyId}
        onClose={onClose}
        segmentPricingParams={segmentPricingParams}
        propertyType={property?.propertyType?.name!}
      />
    );
  };

  const tabsContent: ModalContentType = {
    availability: renderAvailabilityContent(),
    segment: renderSegmentPricingContent(),
  };

  return (
    <HistoricalPerformanceModal
      onClose={confirmOnClose}
      title={translateText(
        `${I18N_HISTORICAL_PERFORMANCE_LABEL_PATH}.editPerformanceLabel`,
      )}
    >
      <Tabs
        activeTabName={activeTabName}
        tabs={getTabsData(tabsContent)}
        onActiveTabChange={confirmChangeTab}
      />

      {unsavedChanges.isConfirmModalVisible && (
        <ConfirmationModal
          onCloseModal={onCloseConfirmModal}
          negativeButton={{
            label: translateText(
              `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.yesUndoChanges`,
            ),
            onclick: undoUnsavedChanges,
          }}
          positiveButton={{
            label: translateText(
              `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.noKeepIt`,
            ),
            onclick: onCloseConfirmModal,
          }}
          header={translateText(
            `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.unsavedChanges`,
          )}
          paragraph={[
            translateText(
              `${I18N_HISTORICAL_PERFORMANCE_TEXT_PATH}.unsavedChangeMessage`,
            ),
          ]}
        />
      )}
    </HistoricalPerformanceModal>
  );
};

export default EditHistoricalPerformance;
