import { useCallback, useState } from 'react';
import { FocusedCell } from './index';

export const useFocusedCell = () => {
  const [focusedCell, setFocusedCell] = useState<FocusedCell | null>(null);

  const defineIsFocusedCell = useCallback(
    (rowIndex: number, columnId: string) =>
      focusedCell?.row === rowIndex && focusedCell?.column === columnId,
    [focusedCell],
  );

  const onCellFocus = useCallback(
    (rowIndex: number, columnId: string) => {
      if (focusedCell?.row !== rowIndex || focusedCell?.column !== columnId) {
        setFocusedCell({ row: rowIndex, column: columnId });
      }
    },
    [focusedCell],
  );

  return { focusedCell, setFocusedCell, defineIsFocusedCell, onCellFocus };
};
