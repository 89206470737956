import { useCallback } from 'react';
import { REGEX_DECIMALS_WITH_COMMA } from 'constants/regex';
import { AvailabilityVacancyIds } from '../interfaces';

export const useInputHandlers = (
  onChangeData: (row: number, col: string, val: any) => void,
) => {
  const handleDateChange = useCallback(
    (rowIndex: number, columnId: string, value: string | number | null) => {
      onChangeData(rowIndex, columnId, value);
    },
    [onChangeData],
  );

  const handleInputChange = useCallback(
    (rowIndex: number, columnId: string, value: string | number | null) => {
      switch (columnId as AvailabilityVacancyIds) {
        case AvailabilityVacancyIds.rentType:
        case AvailabilityVacancyIds.timeMeasurement:
        case AvailabilityVacancyIds.notes: {
          onChangeData(rowIndex, columnId, value);
          break;
        }
        default:
          if (REGEX_DECIMALS_WITH_COMMA.test(String(value)) || !value) {
            onChangeData(rowIndex, columnId, value);
          }
      }
    },
    [onChangeData],
  );

  return { handleDateChange, handleInputChange };
};
