import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import FormControl from 'components/FormControl';
import RadioInput from 'components/Inputs/RadioInput';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import CurrencyInput from 'components/Inputs/CurrencyInput';
import Checkbox from 'components/Checkbox';
import {
  getEscalationFrequencyOptions,
  getEscalationTypeOptions,
} from 'components/CreateComps/FormSection/LeaseForm/staticData';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { REGEX_DECIMALS } from 'constants/regex';
import { EscalationType } from 'constants/leases';
import { IdName } from 'interfaces/IdName';
import { translateText } from 'utils/i18n';

import {
  getInputValue,
  isEscalationFlat,
  isEscalationTypeIndexOmr,
  isEscalationTypePercentage,
} from './utils';
import { CapCollarFields } from './CapCollarFields';
import {
  getEscalationSuggestionsCurrency,
  generateRadioInputsLabels,
  getEscalationSuggestionsValue,
} from './constants';
import styles from '../../FormSection.module.scss';

type FormType = 'lease' | 'priceQuote';

interface Props {
  formType: FormType;
  isPropertyUk: boolean;
  currencySymbol: string | null;
  escalation: string | null;
  escalationType: string | null;
  escalationIncrement: string | null;
  onUpdateHandler: (
    field: string,
    value: string | boolean | IdName | null,
  ) => void;
  escalationCap?: number;
  escalationCollar?: number;
  upwardOnly?: boolean;
  multipleBasis?: boolean;
  escalationIncrementType?: IdName | undefined;
}
const EscalationFields = ({
  formType,
  isPropertyUk,
  currencySymbol,
  escalation,
  escalationType,
  escalationIncrement,
  onUpdateHandler,
  escalationCap,
  escalationCollar,
  upwardOnly,
  multipleBasis,
  escalationIncrementType,
}: Props) => {
  const { t } = useTranslation();
  const isFlat =
    escalationIncrementType?.id === 2 || isEscalationFlat(escalationIncrement);

  const updateEscalationIncrement = (items: IdName[], id: number) => {
    const foundItem = items.find(v => v.id === id);

    if (formType === 'lease') {
      const escalationIncrementTypeValue = foundItem?.id
        ? { id: foundItem?.id }
        : null;
      onUpdateHandler('escalationIncrementType', escalationIncrementTypeValue);
      onUpdateHandler('escalationIncrement', null);
    } else {
      onUpdateHandler('escalationIncrement', foundItem?.name);
    }

    if (isFlat) {
      onUpdateHandler('escalationType', '');
      onUpdateHandler('escalation', '');
    }
  };

  const getEscalationIncrementValue = () => {
    return formType === 'lease'
      ? { id: escalationIncrementType?.id }
      : {
          id: escalationIncrement,
          name: escalationIncrement,
        };
  };

  const displayCapCollarUK =
    isPropertyUk && escalationType === EscalationType.IndexLinked;

  const updateEscalation = (value: string) =>
    onUpdateHandler('escalation', value);

  const updateEscalationType = (value: string) => {
    const currentTypeIsIndexLinked =
      escalationType?.toLowerCase() === EscalationType.IndexLinked;

    const newValueIsIndexLinked =
      value?.toLowerCase() === EscalationType.IndexLinked;

    if (newValueIsIndexLinked || currentTypeIsIndexLinked) {
      onUpdateHandler('escalation', '');
    }
    onUpdateHandler('escalationType', value ?? null);
  };

  return (
    <>
      <FormControl
        label={translateText(
          'avantProperties.labels.common.escalationFrequency',
        )}
        wrapperClassName={styles['escalation-frequency-list']}
        isMultiline
      >
        <ChoiceInput
          selectedItem={getEscalationIncrementValue()}
          data={getEscalationFrequencyOptions(t)}
          containerWrapperClassName={styles['radio-input-container-multiline']}
          onChange={item => {
            const items = getEscalationFrequencyOptions(t);
            updateEscalationIncrement(items, item?.id);
          }}
        />
      </FormControl>

      <FormControl
        label={translateText('avantProperties.labels.common.escalation')}
        wrapperClassName={styles['form-row']}
        columnClassName={styles['escalation-cap-container']}
      >
        <div className={styles['wrapper-radio-container']}>
          <ChoiceInput
            selectedItem={{
              id: escalationType,
              name: escalationType,
            }}
            isDisabled={isFlat}
            data={getEscalationTypeOptions(currencySymbol, isPropertyUk)}
            containerWrapperClassName={styles['radio-input-container']}
            itemWrapperClassName={styles['button-input-item']}
            onChange={item => updateEscalationType(item?.id)}
          />
          {!displayCapCollarUK && (
            <CurrencyInput
              currencySymbol={
                !isEscalationTypePercentage(escalationType)
                  ? currencySymbol
                  : null
              }
              id="lease-escalation"
              iconPosition={
                isEscalationTypePercentage(escalationType) ? 'right' : 'left'
              }
              pattern={REGEX_DECIMALS}
              value={getInputValue(escalation, escalationType)}
              wrapperClassName={classNames({
                [styles['percentage-input']]: isEscalationTypePercentage(
                  escalationType,
                ),
                [styles['with-padding']]: !isEscalationTypePercentage(
                  escalationType,
                ),
              })}
              placeholder={translateText(
                `${I18N_PLATFORM_COMMON_WORD_PATH}.enter`,
              )}
              isDisabled={isEscalationTypeIndexOmr(escalationType) || isFlat}
              onChange={value => updateEscalation(value)}
            />
          )}

          {!isEscalationTypeIndexOmr(escalationType) && (
            <RadioInput
              selectedItem={
                (escalation && getEscalationSuggestionsValue(escalation)[0]) ||
                escalation
              }
              data={
                escalationType === EscalationType.Currency
                  ? getEscalationSuggestionsCurrency(currencySymbol)
                  : generateRadioInputsLabels()
              }
              isAllDisabled={isEscalationTypeIndexOmr(escalationType) || isFlat}
              onChange={item =>
                !!item.value && updateEscalation(String(item.value))
              }
            />
          )}
        </div>

        {displayCapCollarUK && (
          <CapCollarFields
            escalationCap={escalationCap}
            escalationCollar={escalationCollar}
            onChange={onUpdateHandler}
          />
        )}
      </FormControl>
      {isPropertyUk && (
        <div className={styles['escalation-checkboxes']}>
          <Checkbox
            label={translateText('avantProperties.labels.common.upwardOnly')}
            selected={!!upwardOnly}
            onChange={checked => onUpdateHandler('upwardOnly', checked)}
            labelClassName={styles['checkbox-label']}
          />
          <Checkbox
            label={translateText('avantProperties.labels.common.multipleBasis')}
            selected={!!multipleBasis}
            onChange={checked => onUpdateHandler('multipleBasis', checked)}
            labelClassName={styles['checkbox-label']}
          />
        </div>
      )}
    </>
  );
};

export default EscalationFields;
