import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import Icon from 'components/Icon';
import styles from './NavBarGridControl.module.scss';
import { colors } from 'constants/colors';
import { Text } from 'components/@codelitt/ay-design-library';
import { useSubscriptions } from 'contexts/SubscriptionsContext';
import {
  APPLICATIONS,
  EXTERNAL_LINKS as EXTERNAL_LINKS_ACCESS,
} from 'constants/subscriptions';
import { translateText } from 'utils/i18n';
import { useOutsideElementClick } from '../../hooks/useOutsideElementClick';

const NavBarGridControl = () => {
  const { checkSubscriptions } = useSubscriptions();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const I18N_PATH = `avantProperties.components.gridControl`;
  const I18N_COMMON_PATH = `avantPlatform.common.words`;

  const capitalizeAvant = translateText(
    `${I18N_COMMON_PATH}.avant`,
  ).toUpperCase();

  const EXTERNAL_LINKS = [
    {
      title: translateText(`${I18N_PATH}.salesforce`),
      link: 'https://avisonyoungglobal.my.salesforce.com/',
      access: checkSubscriptions(EXTERNAL_LINKS_ACCESS.SALESFORCE),
    },
    {
      title: translateText(`${I18N_PATH}.sharpLaunch`),
      link: 'https://admin.sharplaunch.com/login',
      access: checkSubscriptions(EXTERNAL_LINKS_ACCESS.SHARP_LAUNCH),
    },
    {
      title: translateText(`${I18N_PATH}.costar`),
      link: 'http://costar.com/',
      access: checkSubscriptions(EXTERNAL_LINKS_ACCESS.COSTAR),
    },
    {
      title: translateText(`${I18N_PATH}.altus`),
      link: 'https://www.altusinsite.com/',
      access: checkSubscriptions(EXTERNAL_LINKS_ACCESS.ALTUS),
    },
  ];

  const isExternalLinks = EXTERNAL_LINKS.some(link => link.access);

  const AVANT_LINKS = [
    {
      title: capitalizeAvant,
      subTitle: translateText(`${I18N_PATH}.subTitles.avant`),
      icon: 'performance',
      iconColor: colors.primaryColor500,
      link: '/',
      target: '_self',
      access: checkSubscriptions(APPLICATIONS.PROPERTIES),
    },
    {
      title: `${capitalizeAvant} ${translateText(
        `${I18N_COMMON_PATH}.cities`,
      )} `,
      subTitle: translateText(`${I18N_PATH}.subTitles.cities`),
      icon: 'world',
      iconColor: colors.supportive500,
      link: '/cities',
      target: '_blank',
      access: checkSubscriptions(APPLICATIONS.CITIES),
    },
  ];

  useOutsideElementClick(menuRef, () => setIsOpen(false));

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <div className={styles.menu} ref={menuRef}>
      <button
        className={styles['grid-control']}
        onClick={toggleMenu}
        aria-expanded={isOpen}
        aria-haspopup="true"
      >
        <Icon name="gridLarge" className={styles.gridIcon} />
      </button>
      {isOpen && (
        <div className={styles.menulist}>
          <div className={styles['links-container']}>
            <div className={styles['internal-links-container']}>
              {AVANT_LINKS.map(
                ({
                  title,
                  subTitle,
                  icon,
                  iconColor,
                  access,
                  link,
                  target,
                }) => {
                  if (!access) return null;
                  return (
                    <div className={styles['links']} key={title}>
                      <Link
                        to={link}
                        target={target}
                        rel="noopener"
                        className={styles['link-wrapper']}
                      >
                        <Icon
                          name={icon}
                          color={iconColor}
                          className={styles['link-icon']}
                        />
                        <div>
                          <Text
                            type="title"
                            value={title}
                            wrapperClassName={styles['link-title']}
                          />
                          <Text
                            type="body-content"
                            value={subTitle}
                            wrapperClassName={styles['link-subtitle']}
                          />
                        </div>
                      </Link>
                    </div>
                  );
                },
              )}
            </div>

            {isExternalLinks && (
              <div className={styles['external-links']}>
                <Text
                  type="body-content"
                  value={translateText(`${I18N_PATH}.externalLinks`)}
                  wrapperClassName={styles['external-link-title']}
                />
                {EXTERNAL_LINKS.map(({ title, link, access }) => {
                  if (!access) return null;
                  return (
                    <a
                      key={title}
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles['external-link']}
                    >
                      {title}
                    </a>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export { NavBarGridControl };
