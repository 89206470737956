import { useEffect, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';

import {
  GET_OBJECT_TYPES,
  GET_THIRD_PARTY_TYPES,
  GET_THIRD_PARTY_INFO,
  CREATE_THIRD_PARTY_MUTATION,
  DELETE_THIRD_PARTY_MUTATION,
  UPDATE_THIRD_PARTY_MUTATION,
} from 'graphql/thirdParty';
import { ErrorLogger } from 'services/ErrorLogger';
import { removeTypenameKey } from 'utils/graphql/typename';

export type ThirdPartyInfoType = {
  id: number | string;
  objectId: number;
  objectTypeId: number;
  thirdPartyTypeId: number;
  thirdPartyTypeName: string;
  value: string;
  hasChanged: boolean;
};

export const useThirdParty = (
  objectType: string,
  objectId?: number | string,
): any => {
  const client = useApolloClient();
  const [objectTypeId, setObjectTypeId] = useState(null);
  const [thirdPartyInfo, setThirdPartyInfo] = useState<ThirdPartyInfoType[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(true);

  const [deleteThirdPartyId] = useMutation(DELETE_THIRD_PARTY_MUTATION, {
    onError: error => {
      ErrorLogger.log(
        error.message,
        'UPDATE_THIRD_PARTY_MUTATION > Unexpected error deleting third party id',
      );
    },
  });

  const [createThirdPartyId] = useMutation(CREATE_THIRD_PARTY_MUTATION, {
    onError: error => {
      ErrorLogger.log(
        error.message,
        'UPDATE_THIRD_PARTY_MUTATION > Unexpected error creating third party id',
      );
    },
  });

  const [updateThirdPartyId] = useMutation(UPDATE_THIRD_PARTY_MUTATION, {
    onError: error => {
      ErrorLogger.log(
        error.message,
        'UPDATE_THIRD_PARTY_MUTATION > Unexpected error updating third party id',
      );
    },
  });

  const onSubmit = (objectId: number) => {
    const getThirdPartyInput = (thirdParty: ThirdPartyInfoType) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { thirdPartyTypeName, hasChanged, ...input } = thirdParty;
      return removeTypenameKey(input);
    };

    const create = async (thirdParty: ThirdPartyInfoType) => {
      await createThirdPartyId({
        variables: {
          thirdPartyIds: [
            {
              ...getThirdPartyInput(thirdParty),
              objectId,
              objectTypeId,
            },
          ],
        },
      });
    };

    const deleteThirdParty = async (thirdParty: ThirdPartyInfoType) => {
      await deleteThirdPartyId({
        variables: {
          id: thirdParty.id,
        },
      });
    };

    const update = async (thirdParty: ThirdPartyInfoType) => {
      await updateThirdPartyId({
        variables: {
          thirdPartyId: {
            ...getThirdPartyInput(thirdParty),
          },
        },
      });
    };

    thirdPartyInfo.map(tp => {
      if (tp.hasChanged) {
        if (tp.id) {
          if (tp.value && tp.value != '') {
            update(tp);
          } else {
            deleteThirdParty(tp);
          }
        } else {
          create(tp);
        }
      }
    });
  };

  const updateThirdPartyInfoValue = (index: number, value: string) => {
    const newArr = [...thirdPartyInfo];
    newArr[index].value = value;
    newArr[index].hasChanged = true;

    setThirdPartyInfo(newArr);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: objectTypesData } = await client.query({
          query: GET_OBJECT_TYPES,
        });

        if (objectTypesData?.objectTypes.length > 0) {
          const { id } = objectTypesData?.objectTypes.find(
            (object: { name: string }) =>
              object.name.toLowerCase() === objectType,
          );

          setObjectTypeId(id);

          let thirdPartyIds = null;
          if (objectId && id) {
            const { data } = await client.query<{
              thirdPartyInfo: ThirdPartyInfoType[];
            }>({
              query: GET_THIRD_PARTY_INFO,
              fetchPolicy: 'no-cache',
              variables: {
                objectTypeId: id,
                objectId,
              },
            });
            thirdPartyIds = data.thirdPartyInfo;
          } else {
            const { data } = await client.query<{
              thirdPartyTypes: ThirdPartyInfoType[];
            }>({
              query: GET_THIRD_PARTY_TYPES,
              fetchPolicy: 'no-cache',
              variables: {
                objectTypeId: id,
              },
            });

            thirdPartyIds = data.thirdPartyTypes;
          }

          setThirdPartyInfo(
            thirdPartyIds.sort((a, b) =>
              a.thirdPartyTypeName.localeCompare(b.thirdPartyTypeName),
            ),
          );
        }
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [client, objectId, objectType]);

  return {
    thirdPartyInfo,
    updateThirdPartyInfoValue,
    onSubmit,
    isLoading,
  };
};
