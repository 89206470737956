import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { sum } from 'd3';
import { scaleLinear, ScaleLinear } from 'd3-scale';
import { useHistory } from 'react-router-dom';

import { getCompsQueryParams } from 'components/Graphs/utils/getCompsQueryParams';
import { LEASE_SORT_OPTIONS, EXPIRING_LEASES_ID } from 'constants/leaseSort';
import { authContext } from 'contexts/AuthContext';
import { FindCompTabs } from 'constants/FindCompTabs';
import { SearchOperations } from 'constants/searchOperations';
import dateFormats from 'constants/dateFormats';
import { ModelsWithUnitsOfMeasurement } from 'constants/unitOfMeasurement';
import { DASH_PLACEHOLDER } from 'constants/placeholders';
import {
  I18N_AVANT_PROPERTY_CHART_TEXT_PATH,
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
} from 'constants/i18n';
import { useUserMarkets } from 'hooks/useUserMarkets';
import {
  LEASES_EXPIRATION_SIZED_QUERY,
  SEARCH_LEASES_QUERY,
} from 'graphql/leases';
import { IProperty } from 'interfaces/IProperty';
import { ICompany } from 'interfaces/ICompany';
import { IdName } from 'interfaces/IdName';
import {
  ExpiringLeasePayload,
  ExpiringLeaseResponse,
  IExpiringLease,
  ISegmentLease,
} from 'interfaces/IExpiringLease';
import locations from 'routes';
import { convertIMarketIntoIdName } from 'utils/markets';
import { getUnitOfMeasurementForProperty } from 'utils/unitsOfMeasurement';
import { translateText } from 'utils/i18n';

import {
  HorizontalSegmentedBarChart,
  PopUpSettings,
} from '../HorizontalSegmentedBarChart';
import { DEFAULT_POPUP_SETTINGS } from '../HorizontalSegmentedBarChart/graphConstants';

interface Props {
  graphId: number;
  hasDataChanges?: boolean;
  company?: ICompany;
  isTenant?: boolean;
  marketsFilter?: IdName[];
  onChangeData?: () => void;
  property?: IProperty;
  showComponent?: (value: boolean) => void;
}

const ExpiringLeasesGraph: React.FC<Props> = props => {
  const {
    company,
    graphId,
    isTenant,
    property,
    showComponent,
    marketsFilter,
    hasDataChanges,
    onChangeData,
  } = props;

  const { user } = useContext(authContext);
  const history = useHistory();
  const { userMarkets } = useUserMarkets(user);

  const [popUp, setPopUp] = useState<PopUpSettings>(DEFAULT_POPUP_SETTINGS);

  const companyFilter = isTenant
    ? { tenantCompanyId: company?.id }
    : { ownerId: company?.id, includeLongLeaseHolders: true };

  const search = property?.id ? { propertyId: property.id } : companyFilter;

  const { data, refetch } = useQuery<
    ExpiringLeaseResponse,
    ExpiringLeasePayload
  >(LEASES_EXPIRATION_SIZED_QUERY, {
    variables: {
      search: {
        ...search,
        marketIds: marketsFilter?.map(market => market.id) || [],
      },
    },
  });

  const expiringLeases = (
    data?.leasesExpirationSized?.aggregateLeasesExpirationSizedResponse || []
  ).filter((el: IExpiringLease) => el.value && el.count);

  const unitOfMeasurement = getUnitOfMeasurementForProperty(
    'buildingSize',
    ModelsWithUnitsOfMeasurement.Property,
    expiringLeases[0]?.measurementSystem ||
      property?.propertyCountry?.code ||
      property?.measurementSystem,
  );

  useEffect(() => {
    if (hasDataChanges) refetch?.();
    // eslint-disable-next-line
  }, [hasDataChanges]);

  const compsPopupAction = () => {
    const markets = marketsFilter || userMarkets.map(convertIMarketIntoIdName);

    const ownerFilter = isTenant
      ? {}
      : { owners: [{ id: company?.id, name: company?.name }] };
    const tenantFilter = isTenant
      ? { tenants: [{ id: company?.id, name: company?.name }] }
      : {};

    history.push(
      locations.findComps({
        resultActiveTab: FindCompTabs.leases,
        urlParams: getCompsQueryParams({
          property,
          company,
          customCriteria: {
            markets,
            ...ownerFilter,
          },
          leasesCriteria: {
            sort: LEASE_SORT_OPTIONS.find(
              option => option.id === EXPIRING_LEASES_ID,
            ),
            ...tenantFilter,
          },
        }),
      }),
    );
  };

  const onClickSegment = async (data?: ISegmentLease) => {
    if (!data) return;

    const companyFilter = isTenant
      ? { tenants: [{ id: company?.id, name: company?.name }] }
      : {
          owners: [{ id: company?.id, name: company?.name }],
          longLeaseHolders: [{ id: company?.id, name: company?.name }],
        };

    const marketsFilterVariable = !!marketsFilter?.length
      ? { markets: marketsFilter }
      : {};

    const search = property?.id ? { propertyId: property.id } : companyFilter;

    const [startYear, endYear] = data.text.split(DASH_PLACEHOLDER);
    // if the option is 7+ we get all leases > currentYear + 6 years
    const yearsToAdd = +startYear === 7 ? 6 : +startYear;

    const graphVariables = {
      search: {
        filter: {
          fields: {
            and: [
              {
                op: SearchOperations.gte,
                key: 'expirationDate',
                value: dayjs()
                  .add(yearsToAdd, 'year')
                  .utc()
                  .format(dateFormats.ISO_DATE),
              },
              endYear
                ? {
                    op: SearchOperations.lte,
                    key: 'expirationDate',
                    value: dayjs()
                      .add(+endYear, 'year')
                      .utc()
                      .format(dateFormats.ISO_DATE),
                  }
                : {},
            ],
          },
          ...search,
          ...marketsFilterVariable,
        },
      },
    };

    setPopUp({
      title: translateText(
        `${I18N_AVANT_PROPERTY_CHART_TEXT_PATH}.expiringLeases.expiringMessage`,
        {
          number: data.count,
          text: data.text,
        },
      ),
      graphqlVariables: graphVariables,
      isOpen: true,
      action: compsPopupAction,
      graphqlQuery: SEARCH_LEASES_QUERY,
      onClose: () => setPopUp(DEFAULT_POPUP_SETTINGS),
      onChangeData: onChangeData,
    });
  };

  const hasNoData = !expiringLeases || expiringLeases.length === 0;
  showComponent?.(!hasNoData);

  if (hasNoData) {
    return null;
  }

  const maxSf = sum(expiringLeases, d => d.value)!;

  const MIN_SEGMENT_WIDTH = 10;
  const xScale: ScaleLinear<number, number> = scaleLinear()
    .domain([0, maxSf])
    .range([
      MIN_SEGMENT_WIDTH,
      0,
      MIN_SEGMENT_WIDTH * (expiringLeases.length - 1),
    ]);

  let sumWidths = 0;
  const graphData = expiringLeases.map(el => {
    const segmentWidth = xScale(el.value);
    const currentXPosition = sumWidths;

    sumWidths += segmentWidth;

    return {
      ...el,
      xPosition: currentXPosition,
      width: segmentWidth,
    } as ISegmentLease;
  });

  return (
    <HorizontalSegmentedBarChart
      graphId={graphId}
      unitOfMeasurement={unitOfMeasurement}
      data={graphData!}
      title={translateText(
        `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.expiringLeases`,
      )}
      onClick={onClickSegment}
      popUpSettings={popUp}
    />
  );
};

export default ExpiringLeasesGraph;
