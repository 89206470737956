import { IAssetPerformance } from 'interfaces/IAssetPerformance';
import { convertToNumber } from 'utils/parsers/convertToNumber';
import { getDateISOString } from 'utils/date';
import { formatNumberWithCommas } from 'utils/formatters/number';

const filterChangedRows = <T>(
  tableData: T[],
  changedRowsIndex: number[],
): T[] => {
  return tableData.filter((_, index) => changedRowsIndex.includes(index)) ?? [];
};

export const preparePayloadToSubmit = (
  tableData: Partial<IAssetPerformance>[],
  isDataCenterType: boolean,
  propertyId: number,
  changedRowsIndex: number[],
) => {
  if (isDataCenterType) {
    return filterChangedRows(tableData, changedRowsIndex)?.map(d => ({
      id: d.id,
      date: d.date,
      availableSfShell: convertToNumber(d.availableSfShell),
      vacantSfShell: convertToNumber(d.vacantSfShell),
      availableKwTurnkey: convertToNumber(d.availableKwTurnkey),
      vacantKwTurnkey: convertToNumber(d.vacantKwTurnkey),
      baseRent: convertToNumber(d.baseRent),
      rentType: d.rentType,
      timeMeasurement: d.timeMeasurement,
      notes: d.notes,
      propertyId,
    }));
  }
  return filterChangedRows(tableData, changedRowsIndex)?.map(d => ({
    id: d.id,
    date: d.date,
    availableSfDirect: convertToNumber(d.availableSfDirect),
    vacantSfDirect: convertToNumber(d.vacantSfDirect),
    availableSfSublet: convertToNumber(d.availableSfSublet),
    vacantSfSublet: convertToNumber(d.vacantSfSublet),
    rentType: d.rentType,
    baseRent: convertToNumber(d.baseRent),
    timeMeasurement: d.timeMeasurement,
    notes: d.notes,
    propertyId,
  }));
};

export const prepareTableData = (
  data: IAssetPerformance[],
  isDataCenterType: boolean,
) => {
  if (isDataCenterType) {
    return data?.map(d => ({
      id: d.id!,
      date: getDateISOString(d.date),
      availableSfShell: formatNumberWithCommas(d.availableSfShell) || '0',
      vacantSfShell: formatNumberWithCommas(d.vacantSfShell) || '0',
      availableKwTurnkey: formatNumberWithCommas(d.availableKwTurnkey) || '0',
      vacantKwTurnkey: formatNumberWithCommas(d.vacantKwTurnkey) || '0',
      baseRent: formatNumberWithCommas(d.baseRent) || '0',
      rentType: d.rentType,
      timeMeasurement: d.timeMeasurement,
      notes: d.notes,
    }));
  }

  return data?.map(d => ({
    id: d.id!,
    date: getDateISOString(d.date),
    availableSfDirect: formatNumberWithCommas(d.availableSfDirect) || '0',
    vacantSfDirect: formatNumberWithCommas(d.vacantSfDirect) || '0',
    availableSfSublet: formatNumberWithCommas(d.availableSfSublet) || '0',
    vacantSfSublet: formatNumberWithCommas(d.vacantSfSublet) || '0',
    baseRent: formatNumberWithCommas(d.baseRent) || '0',
    rentType: d.rentType,
    timeMeasurement: d.timeMeasurement,
    notes: d.notes,
  }));
};
