import React from 'react';
import { IMarketPricing } from 'interfaces/IMarketPricing';
import { getCurrentYear } from 'utils/date';

import { SegmentPricingParams } from '../interfaces';
import styles from '../HistoricalPerformanceModal.module.scss';

interface Props {
  localData: IMarketPricing[];
  setLocalData: React.Dispatch<React.SetStateAction<IMarketPricing[]>>;
  segmentPricingParams: SegmentPricingParams | undefined;
}
export const useExtraRows = ({
  localData,
  setLocalData,
  segmentPricingParams,
}: Props) => {
  const currentYear = getCurrentYear();
  const yearPlaceholder = localData?.length
    ? localData[localData?.length - 1]?.year - 1
    : currentYear;

  const placeholder = {
    darPercent: 0,
    directRent: 0,
    freeRentLongTerm: 0,
    id: localData?.length,
    tiLongTerm: 0,
    tiPercent: 0,
    date: `${yearPlaceholder}-12-31`,
    year: yearPlaceholder,
    rentType: segmentPricingParams?.rentType!,
    timeMeasurement: segmentPricingParams?.timeMeasurement!,
  };

  const onExtraRowClick = () => {
    setLocalData(prev => [...prev, placeholder]);
  };

  return {
    rows: [placeholder],
    onExtraRowClick,
    extraRowClassName: styles['extra-row'],
    extraCelLClassName: styles['extra-cell'],
  };
};
