import { IRadioItem } from 'components/Inputs/RadioInput';

export const getEscalationSuggestionsCurrency = (
  currencySymbol: string | null,
): IRadioItem[] => [
  {
    label: `${currencySymbol}2`,
    value: '2',
  },
  {
    label: `${currencySymbol}2.50`,
    value: '2.50',
  },
  {
    label: `${currencySymbol}3`,
    value: '3',
  },
];

export const ESCALATION_SUGGESTIONS: IRadioItem[] = [
  {
    label: '2',
    value: '2',
  },
  {
    label: '2.50',
    value: '2.5',
  },
  {
    label: '3',
    value: '3',
  },
];

export const generateRadioInputsLabels = (): IRadioItem[] =>
  ESCALATION_SUGGESTIONS.map(({ value, label }) => ({
    value,
    label: `${label}%`,
  }));

export const getEscalationSuggestionsValue = (
  escalation: string,
): typeof ESCALATION_SUGGESTIONS =>
  ESCALATION_SUGGESTIONS.filter(({ value }) => value === escalation);
