import { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import {
  ASSET_PERFORMANCE_MUTATION,
  ASSET_PERFORMANCES_TABLE_PROPERTY,
} from 'graphql/historicalPerformance';
import {
  AssetPerformanceTablePropertyResponse,
  IAssetPerformance,
} from 'interfaces/IAssetPerformance';
import { isValidDate } from 'utils/date';

import { preparePayloadToSubmit, prepareTableData } from './utils';

interface Props {
  propertyId: number;
  isDataCenterType: boolean;
  onClose: () => void;
}

export const useAvailabilityData = ({
  propertyId,
  isDataCenterType,
  onClose,
}: Props) => {
  const [data, setData] = useState<Partial<IAssetPerformance>[]>([]);
  const [changedRowsIndex, setChangedRowsIndex] = useState<number[]>([]);

  const { loading: loadingAssetPerformance } = useQuery<
    AssetPerformanceTablePropertyResponse
  >(ASSET_PERFORMANCES_TABLE_PROPERTY, {
    variables: { propertyId },
    fetchPolicy: 'no-cache',
    skip: !propertyId,
    onCompleted: d => {
      const preparedData = prepareTableData(
        d.assetPerformances,
        isDataCenterType,
      );
      setData(preparedData ?? []);
    },
  });

  const [
    saveAvailabilityVacancy,
    { loading: loadingSaveAvailabilityVacancy },
  ] = useMutation(ASSET_PERFORMANCE_MUTATION, {
    variables: {
      items: preparePayloadToSubmit(
        data,
        isDataCenterType,
        propertyId,
        changedRowsIndex,
      ),
    },
    onCompleted: onClose,
  });

  const isDisabledSubmit = useMemo(() => {
    const isAnyInvalidDate = data?.some(d => !isValidDate(d.date!));

    return (
      isAnyInvalidDate ||
      !changedRowsIndex.length ||
      loadingSaveAvailabilityVacancy
    );
  }, [changedRowsIndex.length, data, loadingSaveAvailabilityVacancy]);

  return {
    variables: {
      data,
      changedRowsIndex,
      isDisabledSubmit,
    },
    loadings: { loadingAssetPerformance, loadingSaveAvailabilityVacancy },
    handlers: { setData, saveAvailabilityVacancy, setChangedRowsIndex },
  };
};
