import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { extent } from 'd3-array';
import { scaleTime } from 'd3-scale';

import VerticalBubbleChart from 'components/Graphs/VerticalBubbleChart';
import { RecentLeasesIds } from 'components/Graphs/RecentLeases/graphIds';
import { LeaseToggleOptions } from 'components/Leases/LeaseToggles';
import {
  parseLeaseDataToBubbleDataPoints,
  parseMarketDataToBubbleDataPoints,
} from 'components/Graphs/RecentLeases/lineGraphData';
import CompsPopup from 'components/CompsPopup';
import { IDataPoint } from 'components/Graphs/RecentLeases/interfaces';
import {
  getCompsGraphqlQuery,
  getCompsVariables,
} from 'components/Graphs/RecentLeases/utils';
import { RECORD_TYPE_OPTIONS } from 'components/CreateComps/FormSection/LeaseForm/staticData';
import { goToFindCompsResult } from 'components/NavigationButtonGroup/utils';
import { getSubmarketLeasesLegends } from 'components/SubmarketProfile/Sections/Performance/HistoricalLeasing/utils';
import { getRecentLeasesLegends } from 'components/MarketProfile/Sections/Performance/HistoricalLeasing/utils';
import { I18N_LEASE_LABEL_PATH } from 'components/Leases/constants';
import { IBubbleDataPoint } from 'components/Graphs/BubbleChartsElements/interfaces';
import { X_AXIS_PADDING } from 'components/Graphs/RecentLeases/graphScales';
import dateFormats from 'constants/dateFormats';
import { FindCompTabs, FindCompTabsViews } from 'constants/FindCompTabs';
import { RECENT_LEASES_OPTION } from 'constants/leaseSort';
import { LEASE_RECENT_TIME_OPTIONS } from 'constants/leaseTime';
import { authContext } from 'contexts/AuthContext';
import { IHistoricalLeasingData } from 'interfaces/IHistoricalLeasingData';
import { IMarket, ISubmarket } from 'interfaces/IMarket';
import { IdName } from 'interfaces/IdName';
import { parseGraphData } from 'utils/historicalLeasing';
import { getCurrencySymbol } from 'utils/formatters/currency';

import {
  getCompsPopupLeasesSubtitle,
  getCompsPopupLeasesTitle,
  I18N_MOST_RECENT_LEASES_PATH,
} from './utils';
import {
  DEFAULT_NUMBER_OF_PAST_YEARS,
  dimensions,
  QUARTERLY_NUMBER_OF_PAST_YEARS,
} from './constants';
import styles from './MostRecentLeases.module.scss';

type Props = {
  data: IHistoricalLeasingData;
  toggleOptions: LeaseToggleOptions;
  unitOfMeasurement?: string;
  submarket?: ISubmarket;
  market?: IMarket;
  propertyType?: IdName;
  isQuarterly?: boolean;
};

const Y_AXIS_HEIGHT = 13.8125;
const LEGEND_TOP_POSITION = -0.5625;
const LEGEND_BOTTOM_POSITION = 0;

const MostRecentLeases: React.FC<Props> = ({
  data,
  toggleOptions,
  unitOfMeasurement,
  submarket,
  market,
  propertyType,
  isQuarterly = true,
}) => {
  const { user } = useContext(authContext);
  const [isCompsPopupVisible, setIsCompsPopupVisible] = useState(false);
  const [
    activeLeaseDataPoint,
    setActiveLeaseDataPoint,
  ] = useState<IDataPoint | null>(null);
  const [
    leaseMarketDataPoint,
    setLeaseMarketDataPoint,
  ] = useState<IDataPoint | null>();
  const history = useHistory();
  const { t } = useTranslation();
  const graphDataId = submarket
    ? RecentLeasesIds.SubmarketPerformancePage
    : RecentLeasesIds.MarketPerformancePage;

  const refMaxYears = isQuarterly
    ? QUARTERLY_NUMBER_OF_PAST_YEARS
    : DEFAULT_NUMBER_OF_PAST_YEARS;

  const graphData = parseGraphData(
    undefined,
    graphDataId,
    data!,
    toggleOptions,
    refMaxYears,
    isQuarterly,
  );

  const graphParams = {
    currencySymbol: getCurrencySymbol(market?.country?.currencyCode),
    rentType: data.historicalLeases?.rentType ?? '',
    unitOfMeasurement:
      market?.country?.areaMeasurement || unitOfMeasurement || '',
  };

  const marketBubbleDataPoints = parseMarketDataToBubbleDataPoints(
    graphData.graphDataPoints,
    graphData.graphParams.rentType,
    isQuarterly,
  );
  const bubbleItemsDataPoints = parseLeaseDataToBubbleDataPoints(
    graphData.graphDataLeases,
    graphData.graphParams.rentType,
    isQuarterly,
  );

  const handleLeaseBubbleClick = (d: IDataPoint) => {
    setActiveLeaseDataPoint(d);
  };

  const handleMarketLeaseClick = (d: IDataPoint) => {
    setLeaseMarketDataPoint(d);
  };

  useEffect(() => {
    if (activeLeaseDataPoint || leaseMarketDataPoint) {
      setIsCompsPopupVisible(true);
    }
  }, [activeLeaseDataPoint, leaseMarketDataPoint]);

  const currencySymbol = getCurrencySymbol(activeLeaseDataPoint?.currencyCode);
  const rentType = activeLeaseDataPoint?.rentType || '';
  const markets = market?.id ? [{ id: market?.id }] : undefined;
  const customSearchLeasesCriteria = {
    markets,
    submarkets: [{ id: submarket?.id }],
    types: [{ id: propertyType?.id, name: propertyType?.name }],
    propertyTypes: [{ id: propertyType?.id, name: propertyType?.name }],
  };

  const onSeeAllLeases = () => {
    if (activeLeaseDataPoint) {
      // Bubble
      goToFindCompsResult(
        user,
        history,
        markets,
        FindCompTabs.leases,
        customSearchLeasesCriteria,
        {
          sort: RECENT_LEASES_OPTION,
          signTime: LEASE_RECENT_TIME_OPTIONS[0],
          recordType: RECORD_TYPE_OPTIONS[0],
        },
        FindCompTabsViews.list,
      );
      return;
    }
    if (leaseMarketDataPoint) {
      const year = dayjs(leaseMarketDataPoint?.date)
        .utc()
        .year();
      const startDateByYear = dayjs()
        .year(year)
        .startOf('year')
        .format(dateFormats.ISO_DATE);

      const endDateByYear = dayjs()
        .year(year)
        .endOf('year')
        .format(dateFormats.ISO_DATE);

      const startDateByQuarter = dayjs(leaseMarketDataPoint?.date)
        .utc()
        .startOf('quarter')
        .format(dateFormats.ISO_DATE);

      const endDateByQuarter = dayjs(leaseMarketDataPoint?.date)
        .utc()
        .endOf('quarter')
        .format(dateFormats.ISO_DATE);
      goToFindCompsResult(
        user,
        history,
        markets,
        FindCompTabs.leases,
        customSearchLeasesCriteria,
        {
          sort: RECENT_LEASES_OPTION,
          signTimeFrom: isQuarterly ? startDateByQuarter : startDateByYear,
          signTimeTo: isQuarterly ? endDateByQuarter : endDateByYear,
        },
        FindCompTabsViews.list,
      );
    }
  };

  const generateXScale = () => {
    const currentYear = dayjs().startOf('year');
    const initialValue = isQuarterly
      ? {
          ...bubbleItemsDataPoints[0],
          date: currentYear
            .subtract(QUARTERLY_NUMBER_OF_PAST_YEARS, 'year')
            .valueOf(),
        }
      : {
          ...bubbleItemsDataPoints[0],
          date: currentYear
            .subtract(DEFAULT_NUMBER_OF_PAST_YEARS, 'year')
            .valueOf(),
        };

    const finalValue = {
      ...bubbleItemsDataPoints[0],
      date: dayjs().valueOf(),
    };

    const datesExtent: any = extent(
      [initialValue, ...bubbleItemsDataPoints, finalValue],
      (d: IBubbleDataPoint) => d.date,
    );

    return scaleTime()
      .domain(datesExtent)
      .range([-X_AXIS_PADDING, dimensions.width - X_AXIS_PADDING]);
  };

  return (
    <div className={styles.container}>
      <VerticalBubbleChart
        hasHorizontalLines
        useYPadding={false}
        yHeight={Y_AXIS_HEIGHT}
        legendTopPosition={LEGEND_TOP_POSITION}
        legendBottomPosition={LEGEND_BOTTOM_POSITION}
        defaultXScale={generateXScale()}
        title={t(`${I18N_MOST_RECENT_LEASES_PATH}.title`)}
        data={{
          ...graphData,
          bubbleItems: bubbleItemsDataPoints,
          marketItems: marketBubbleDataPoints,
          graphParams: graphParams,
        }}
        graphId={graphDataId}
        graphDimensions={dimensions}
        legends={
          submarket ? getSubmarketLeasesLegends(t) : getRecentLeasesLegends(t)
        }
        onClickLeaseBubble={handleLeaseBubbleClick}
        onClickAvgLinePoint={handleMarketLeaseClick}
      />
      {isCompsPopupVisible && (
        <CompsPopup
          action={onSeeAllLeases}
          actionLabel={
            activeLeaseDataPoint
              ? t(`${I18N_LEASE_LABEL_PATH}.allRecentLeases`)
              : t(`${I18N_LEASE_LABEL_PATH}.allLeases`)
          }
          graphqlVariables={getCompsVariables(
            undefined,
            activeLeaseDataPoint,
            leaseMarketDataPoint,
            undefined,
            isQuarterly,
          )}
          onClose={() => {
            setIsCompsPopupVisible(false);
            setActiveLeaseDataPoint(null);
            setLeaseMarketDataPoint(null);
          }}
          showPropertyData
          title={getCompsPopupLeasesTitle({
            activeLeaseDataPoint,
            currencySymbol,
            leaseMarketDataPoint,
            rentType,
            translate: t,
          })}
          subtitle={
            leaseMarketDataPoint
              ? getCompsPopupLeasesSubtitle(
                  leaseMarketDataPoint,
                  t,
                  isQuarterly,
                )
              : ''
          }
          useLeaseTitle
          graphqlQuery={
            getCompsGraphqlQuery(activeLeaseDataPoint, leaseMarketDataPoint)!
          }
        />
      )}
    </div>
  );
};

export default MostRecentLeases;
