import { isNil } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { fieldHasValue } from 'utils/objects';
import { ONE_THOUSAND, TEN_THOUSAND } from 'constants/numbers';
import { I18nLanguage } from 'constants/i18n';
import numeralInstance from '../../numeralInstance';

/**
 * Returns the decimal part of the format string of Numeral JS
 */
const getDecimalString = (numOfDecimals: number) => {
  if (!numOfDecimals) {
    return '';
  }

  return Array(numOfDecimals + 1).join('0');
};

/**
 * Returns a number without locale formatting
 */
export const removeLocaleFormatting = (number: string) => {
  const currentLocale = numeralInstance.locale();
  const decimalSeparator = getDecimalSeparator();
  const [integer, decimal] = number.split(decimalSeparator);
  let formattedInteger;

  // Unfortunately, we can't rely on Numeral JS to remove locale formatting,
  // as it doesn't work well when trying to convert strings like "100 000,1" to "100000.1".
  switch (currentLocale) {
    case I18nLanguage.en:
    case I18nLanguage.en_CA:
    case I18nLanguage.en_GB:
      formattedInteger = integer.replace(/,/g, '');
      break;
    case I18nLanguage.fr:
      formattedInteger = integer.replace(/\s/g, '');
      break;
    default:
      formattedInteger = integer;
      break;
  }

  const formattedNumber = decimal
    ? `${formattedInteger}.${decimal}`
    : formattedInteger;
  return formattedNumber.replace(/(^[\d-][^\d\.]|[^\d]$)/, '');
};

/**
 * Returns the decimal separator string based on the user's locale
 */
export const getDecimalSeparator = () => {
  // 1.1 is just an arbitrary rational number.
  const number = numeralInstance(1.1).format('0.0');
  const idx = number.search(/[,.]/);

  return number.substring(idx, idx + 1);
};

/**
 * Returns the amount of decimals of the given number.
 */
export const getNumberOfDecimals = (number: number) => {
  if (Math.floor(number) === number) {
    return 0;
  }

  return number.toString().split('.')[1].length;
};

export const formatNumberWithDecimals = (
  number?: string | number | null,
  fractionDigits = 2,
): string => {
  if (fieldHasValue(number)) {
    try {
      const decimalString = getDecimalString(fractionDigits);

      return numeralInstance(+number!).format(`0.${decimalString}`);
    } catch (__) {}
  }
  return '';
};

export const formatNumberPercentage = (
  number?: string | number | null,
  fractionDigits = 1,
): string => {
  const valueAsNumber = Number(number);

  if (!fieldHasValue(number) || isNaN(valueAsNumber)) {
    return '';
  }

  const isHalfValueNumber = valueAsNumber % 1 === 0.5 && fractionDigits === 0;
  const numOfDecimals = getNumberOfDecimals(valueAsNumber);
  const formattedNumber = formatNumberWithDecimals(
    valueAsNumber,
    isHalfValueNumber ? 1 : numOfDecimals === 0 ? 0 : fractionDigits,
  );

  return `${formattedNumber}%`;
};

export const formatNumberWithCommas = (
  value?: string | number | null,
  decimals?: number,
) => {
  if (isNil(value)) return '';

  const number = +removeLocaleFormatting(value.toString());

  if (isNil(value) || isNaN(number)) return '';

  const numOfDecimals = !isNil(decimals)
    ? decimals
    : getNumberOfDecimals(number);
  const decimalString = getDecimalString(numOfDecimals);

  // If the number is less than 1000, it doesn't need a thousands separator.
  if (Math.abs(number) < ONE_THOUSAND) {
    return !isNil(numOfDecimals)
      ? formatNumberWithDecimals(number, numOfDecimals)
      : value + '';
  }

  const formatString = numOfDecimals ? `0,0.${decimalString}` : '0,0';

  return numeralInstance(number).format(formatString);
};

export const roundNumberWithDecimals = (
  number: number,
  fractionDigits = 2,
): string => {
  if (isNaN(+number)) return '';

  const value = (Math.round(number * 10 * 10) / 100).toFixed(fractionDigits);

  return formatNumberWithCommas(value, fractionDigits);
};

export const truncateFloat = (value: number | string, size: number): number => {
  const [integer, decimals] = String(value).split('.');

  return +Number([integer, decimals?.slice(0, size)].join('.'));
};

export const convertFloatToDecimal = (number: number, size?: number) =>
  size ? truncateFloat(number / 100, size) : number / 100;

export const convertDecimalToFloat = (number: number, size?: number) =>
  parseFloat((number * 10 * 10).toFixed(size || 10));

export const formatNumberWithNoDecimals = (number?: number): string => {
  if (!fieldHasValue(number)) {
    return '';
  }

  const formattedNumber = formatNumberWithDecimals(number);
  const [integer] = formattedNumber.toString().split(/[.,]/);
  return formatNumberWithCommas(integer);
};

/**
 * Format the number adding commas to separate every 3 digits
 * @param value
 */
export const formatPunctuationNumber = (value: number) =>
  formatNumberWithNoDecimals(value);

/**
 * This function will abbreviate a number to millions, keeping the first decimal if it has.
 * Ex: 1000000 -> 1M, 1100000 -> 1.1M, 1000 -> 1K
 *
 * @param value
 * @param considerSmallNumbers
 */
export const abbreviateNumber = (
  value?: number | null,
  strFormat = '0,0.0a',
) => {
  if (!fieldHasValue(value)) return '';

  if (value! < ONE_THOUSAND && value! > -ONE_THOUSAND) {
    return Number(formatNumberWithNoDecimals(value!));
  }

  const decimalSeparator = getDecimalSeparator();
  const [integer, decimalsWithSymbol] = numeralInstance(value)
    .format(strFormat)
    .split(decimalSeparator);
  const decimals = decimalsWithSymbol.slice(0, -1);
  const symbol = decimalsWithSymbol
    .slice(decimalsWithSymbol.length - 1)
    .toUpperCase();

  if (Number(decimals) === 0) {
    return `${integer}${symbol}`;
  }

  return `${integer}${decimalSeparator}${decimals}${symbol}`;
};

export const applyPercentageFromNumber = (
  number: number,
  percentage: number,
): number => {
  return (number * percentage) / 100;
};

export const roundNumberToNearestTenThousand = (value: number) => {
  return Math.ceil(value / TEN_THOUSAND) * TEN_THOUSAND;
};

export const roundNumberToNearestInt = (value: number) => {
  return Math.ceil(value);
};

export const roundNumberToNearestTen = (value: number) => {
  return Math.ceil(value / 10) * 10;
};

export const removeCommaFromNumber = (value: string) => {
  return value.replaceAll(',', '');
};

export const formatFloatOrInteger = (value: number, fractionDigits: number) => {
  return Number.isInteger(value) ? value : +value.toFixed(fractionDigits);
};

export const generateNumericId = () => {
  const uuid = uuidv4();
  return parseInt(uuid.replace(/-/g, '').slice(0, 12), 16);
};

/**
 * Formats a number by removing leading zeros.
 * Example:
 *  0123 => 123
 *  0.10 => 0.10
 */
export const normalizeNumber = (
  value: string | number | null,
): number | string | null => {
  if (value === null) return null;
  if (typeof value === 'number') return value;

  const num = value.trim().replace(/^0+(?=\d)/, '');
  return num.includes('.') ? num : Number(num);
};
