import { SortDirections } from 'constants/sortDirections';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { SearchOptionsType } from 'interfaces/ISearchOptionsType';
import { translateText } from 'utils/i18n';

export const RECENT_LEASES_ID = 1;
export const EXPIRING_LEASES_ID = 2;
export const CURRENT_LEASES_ID = 3;

export const RECENT_LEASES_OPTION = {
  id: RECENT_LEASES_ID,
  name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.recent`),
  field: 'signDate',
  sort: {
    direction: SortDirections.desc,
  },
};

export const EXPIRING_LEASES_OPTION = {
  id: EXPIRING_LEASES_ID,
  name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.expiring`),
  field: 'expirationDate',
  sort: {
    direction: SortDirections.asc,
  },
};

export const ALL_LEASES_OPTION = {
  id: CURRENT_LEASES_ID,
  name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.allLeases`),
  field: 'size',
  sort: {
    direction: SortDirections.desc,
  },
};

export const LEASE_SORT_OPTIONS: SearchOptionsType[] = [
  ALL_LEASES_OPTION,
  RECENT_LEASES_OPTION,
  EXPIRING_LEASES_OPTION,
];
