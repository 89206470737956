export enum PropertyTypes {
  industrial = 'Industrial',
  office = 'Office',
  flexIndustrial = 'Flex Industrial',
  multifamily = 'Multifamily',
  dataCenter = 'Data Center',
  healthcare = 'Healthcare',
  transportation = 'Transportation',
  selfStorage = 'Self Storage',
  retail = 'Retail',
  hospitality = 'Hospitality',
  landAndDevelopment = 'Land and Development',
  lifeScience = 'Life Science',
}

export const PropertyTypeIds: { [key: string]: number } = {
  office: 1,
  industrial: 2,
  flexIndustrial: 3,
  'flex industrial': 3,
  multifamily: 4,
  dataCenter: 5,
  'data center': 5,
  healthcare: 6,
  transportation: 7,
  selfStorage: 8,
  'self storage': 8,
  retail: 9,
  hospitality: 10,
  landAndDevelopment: 11,
  'land and development': 11,
  lifeScience: 12,
  'life science': 12,
};

export const PropertyTypeNames: { [key: string]: PropertyTypes } = {
  1: PropertyTypes.office,
  2: PropertyTypes.industrial,
  3: PropertyTypes.flexIndustrial,
  4: PropertyTypes.multifamily,
  5: PropertyTypes.dataCenter,
  6: PropertyTypes.healthcare,
  7: PropertyTypes.transportation,
  8: PropertyTypes.selfStorage,
  9: PropertyTypes.retail,
  10: PropertyTypes.hospitality,
  11: PropertyTypes.landAndDevelopment,
  12: PropertyTypes.lifeScience,
};
