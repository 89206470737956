import { IMarket } from 'interfaces/IMarket';
import { IUser } from 'interfaces/IUser';
import { PropertyTypeNames } from 'constants/propertyTypes';

export const getDefaultPreferencesFilter = (
  markets: IMarket[],
  user: IUser,
) => {
  const { marketIds = [], propertyTypes = [] } = user?.preferences ?? {};

  return {
    markets:
      markets
        ?.filter(m => marketIds.includes(m.id))
        .map(({ id, name }) => ({ id, name })) ?? [],
    propertyTypes:
      propertyTypes.map(id => ({ id, name: PropertyTypeNames[id] })) ?? [],
  };
};
