import React, { useEffect, useState, useCallback, useMemo } from 'react';
import styles from '../FormSection.module.scss';
import { ILeaseInput } from 'interfaces/inputs/ILeaseInput';
import classNames from 'classnames';
import { handleFormStatusUpdate } from '../formUtils';
import { processLeaseBeforeSubmit } from 'components/CreateComps/FormSection/LeaseForm/processLeaseBeforeSubmit';
import { processLeaseBeforeEdit } from 'components/CreateComps/FormSection/LeaseForm/processLeaseBeforeEdit';
import { IProperty } from 'interfaces/IProperty';
import { ILease } from 'interfaces/ILease';
import { isValidForm } from './utils';
import { getCurrencySymbol } from 'utils/formatters/currency';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { LeaseTypeEnum } from 'constants/leases';
import { useProperty } from 'hooks/useProperty';
import { getUnitOfMeasurementForLease } from 'utils/unitsOfMeasurement';
import BasicInformationSection from './BasicInformation';
import LeaseDetails from './LeaseDetails';
import LeaseTermsSection from './LeaseTerms';
import FormFooter from 'components/FormFooter';
import UpdateSummary, { getUpdateSummaryData } from 'components/UpdateSummary';
import { PropertyTypeNames, PropertyTypes } from 'constants/propertyTypes';
import { useCalculateSizeGrowth } from './useCalculateSizeGrowth';

export interface LeaseFormSectionProps {
  leaseInput: ILeaseInput;
  updateLeaseProperty: (property: string, propertyValue: any) => void;
}

export interface Props {
  sourceLease?: ILease;
  lease?: ILeaseInput;
  onSubmitForm: (leaseInput: ILeaseInput) => void;
  onCancel?: () => void;
  submitLabel?: string;
  isSubmitting?: boolean;
  sourceProperty?: IProperty;
}

const LeaseForm: React.FC<Props> = props => {
  const lease = processLeaseBeforeEdit(props.lease || {});

  const [propertyType, setPropertyType] = useState<string | undefined>();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [leaseInput, setLeaseInput] = useState<ILeaseInput>(lease);
  const [unitOfMeasurement, setUnitOfMeasurement] = useState<UnitOfMeasurement>(
    UnitOfMeasurement.sf,
  );
  const { property: leaseProperty } = useProperty(leaseInput.property?.id);

  const leaseCurrencySymbol = getCurrencySymbol(leaseProperty?.currencyCode);

  const isIndustrial = useMemo(() => {
    const isIndustrialType = (type?: string) =>
      type === PropertyTypes.industrial ||
      type === PropertyTypes.flexIndustrial;

    return (
      isIndustrialType(propertyType) || isIndustrialType(lease.property?.type)
    );
  }, [lease.property?.type, propertyType]);
  const onSubmit = () =>
    props.onSubmitForm(processLeaseBeforeSubmit(leaseInput));

  const updateLeaseProperty = useCallback(
    (property: string, propertyValue: any) =>
      setLeaseInput(value => ({ ...value, [property]: propertyValue })),
    [],
  );

  const updateLeaseBrokersProperty = useCallback(
    (brokerField: string, brokers: any) =>
      setLeaseInput((value: ILeaseInput) => ({
        ...value,
        [brokerField]: brokers ? [brokers] : [],
      })),
    [],
  );

  const {
    handleChangedSizeGrowth,
    handleTriggeredCalculation,
    handleChangeTransactionType,
  } = useCalculateSizeGrowth({
    leaseInput,
    updateLeaseProperty,
    unitOfMeasurement,
  });

  useEffect(() => {
    if (!leaseProperty) return;

    const setUnitOfMeasurementForTenantInMarket = () => {
      const unitMin = getUnitOfMeasurementForLease(
        'tenantInMarketSizeMin',
        leaseProperty?.propertyCountry?.code ||
          leaseProperty?.measurementSystem,
      );
      const unitMax = getUnitOfMeasurementForLease(
        'tenantInMarketSizeMax',
        leaseProperty?.propertyCountry?.code ||
          leaseProperty?.measurementSystem,
      );

      if (
        unitMin === UnitOfMeasurement.sf ||
        unitMax === UnitOfMeasurement.sf
      ) {
        updateLeaseProperty('tenantInMarketSizeMinMt', undefined);
        updateLeaseProperty('tenantInMarketSizeMaxMt', undefined);
      } else {
        updateLeaseProperty('tenantInMarketSizeMin', undefined);
        updateLeaseProperty('tenantInMarketSizeMax', undefined);
      }
    };

    const setUnitOfMeasurementForLeaseSize = () => {
      const unit =
        leaseInput.type !== LeaseTypeEnum.TURN_KEY
          ? getUnitOfMeasurementForLease(
              'size',
              leaseProperty.propertyCountry?.code ||
                leaseProperty.measurementSystem,
            )
          : UnitOfMeasurement.kw;

      if (unit === UnitOfMeasurement.sf) {
        updateLeaseProperty('sizeMt', undefined);
      } else {
        if (unit === UnitOfMeasurement.kw) {
          updateLeaseProperty('sizeMt', undefined);
          updateLeaseProperty('baseRent', undefined);
        } else {
          updateLeaseProperty('size', undefined);
          updateLeaseProperty('baseRentPerKw', undefined);
        }
      }

      setUnitOfMeasurement(unit as UnitOfMeasurement);
    };

    setUnitOfMeasurementForLeaseSize();
    setUnitOfMeasurementForTenantInMarket();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaseProperty, leaseInput.type]);

  useEffect(() => handleFormStatusUpdate(lease, leaseInput, setIsDirty), [
    leaseInput,
    isDirty,
    lease,
  ]);

  useEffect(() => {
    if (props.sourceProperty) {
      updateLeaseProperty('property', {
        id: props.sourceProperty.id,
        name: props.sourceProperty.name,
        primaryAddress: props.sourceProperty.primaryAddress,
        propertyTypeId: props.sourceProperty?.propertyType?.id,
      });
      setPropertyType(PropertyTypeNames[props.sourceProperty.propertyType?.id]);
    }
  }, [props.sourceProperty, updateLeaseProperty]);

  const updateSummaryValue =
    props.sourceLease &&
    props.sourceLease.createdAt &&
    getUpdateSummaryData(
      props.sourceLease.createdAt,
      props.sourceLease.createdUser,
      props.sourceLease.updatedAt,
      props.sourceLease.updatedUser,
    );
  return (
    <>
      <div
        className={classNames(styles.container, {
          [styles['is-last']]: !updateSummaryValue,
        })}
      >
        <BasicInformationSection
          marketId={leaseProperty?.market?.id}
          marketName={leaseProperty?.market?.name}
          leaseInput={leaseInput}
          setPropertyType={setPropertyType}
          updateLeaseProperty={updateLeaseProperty}
          sourceTenantInMarketId={leaseInput?.tenantInMarketId}
        />
        <LeaseDetails
          lease={lease}
          leaseCurrencySymbol={leaseCurrencySymbol}
          leaseInput={leaseInput}
          leaseProperty={leaseProperty}
          propertyType={propertyType}
          unitOfMeasurement={unitOfMeasurement}
          updateLeaseProperty={updateLeaseProperty}
          sourceLease={props.sourceLease}
          isPropertyIndustrialType={isIndustrial}
          handleTriggeredCalculation={handleTriggeredCalculation}
          handleChangeTransactionType={handleChangeTransactionType}
        />
        <LeaseTermsSection
          leaseCurrencySymbol={leaseCurrencySymbol}
          leaseInput={leaseInput}
          leaseProperty={leaseProperty}
          updateLeaseBrokersProperty={updateLeaseBrokersProperty}
          updateLeaseProperty={updateLeaseProperty}
          unitOfMeasurement={unitOfMeasurement}
          handleChangedSizeGrowth={handleChangedSizeGrowth}
          handleTriggeredCalculation={handleTriggeredCalculation}
        />
      </div>
      {updateSummaryValue && (
        <UpdateSummary
          data={updateSummaryValue}
          wrapperClassName={classNames(styles.container, styles['is-last'])}
        />
      )}
      <FormFooter
        onSubmit={onSubmit}
        onCancel={props.onCancel!}
        isCancelDisabled={props.isSubmitting}
        submitButtonLabel={props.submitLabel}
        isSubmitDisabled={
          props.isSubmitting ||
          !isValidForm(leaseInput, leaseProperty) ||
          !isDirty
        }
      />
    </>
  );
};

export default LeaseForm;
