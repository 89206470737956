import { SearchOperations } from 'constants/searchOperations';
import { SearchOptionsType } from 'interfaces/ISearchOptionsType';
import { addYearsDateUTCTime, getTodayUTCTime } from 'utils/date';

export const PAST_2_YEARS_ID = 1;

export const SALE_DATE_OPTIONS: SearchOptionsType[] = [
  {
    id: PAST_2_YEARS_ID,
    name: 'Past 2 Years',
    field: 'date',
    filter: {
      operator: SearchOperations.between,
      range: {
        start: addYearsDateUTCTime(-2),
        end: getTodayUTCTime(),
      },
    },
  },
  {
    id: 2,
    name: 'Past 4 Years',
    field: 'date',
    filter: {
      operator: SearchOperations.between,
      range: {
        start: addYearsDateUTCTime(-4),
        end: getTodayUTCTime(),
      },
    },
  },
  {
    id: 3,
    name: 'Past 6 Years',
    field: 'date',
    filter: {
      operator: SearchOperations.between,
      range: {
        start: addYearsDateUTCTime(-6),
        end: getTodayUTCTime(),
      },
    },
  },
];
