import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Col, Row } from 'components/@codelitt/ay-design-library';

import { NAVBAR_HEIGHT } from 'components/Navbar';
import InfiniteScroll from 'components/InfiniteScroll';
import LoadingMessage from 'components/LoadingMessage';
import NotificationMessage from 'components/NotificationMessage';
import ScoopsDropdownFilter from 'components/ScoopsDropdownFilter';
import ActivityFeedContent from 'components/ActivityFeed/ActivityFeedContent';
import ActivityFeedEmptyMessage from 'components/ActivityFeed/ActivityFeedEmptyMessage';
import { ActivityFeedCriteriaType } from 'components/ActivityFeed/types';
import ManageScoopModal from 'components/ManageScoopModal';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { ICompany } from 'interfaces/ICompany';
import { IdName } from 'interfaces/IdName';
import { IProperty } from 'interfaces/IProperty';
import { IActivity } from 'interfaces/IActivity';

import styles from './ActivityFeedScrollContent.module.scss';

interface Props {
  loading: boolean;
  showAddScoopModal: boolean;
  refetch: () => void;
  activities: IActivity[];
  loadNextPage: () => void;
  setShowAddScoopModal: (value: boolean) => void;
  scoopsOnChange: (tags: IdName[]) => void;
  noResultsMessage?: string;
  isLoadingFilters?: boolean;
  onMarketClick?: () => void;
  isLoadingNextPage?: boolean;
  header?: JSX.Element | null;
  filters?: ActivityFeedCriteriaType;
  preselectedProperty?: IProperty;
  preselectedCompany?: ICompany;
  feedContentClassName?: string;
  isDropdownFilterSticked?: boolean;
}

const TAGS_FILTER_TOP_DISTANCE = 24;

const ActivityFeedScrollContent: React.FC<Props> = ({
  header,
  loading,
  refetch,
  filters,
  activities,
  loadNextPage,
  onMarketClick,
  scoopsOnChange,
  isLoadingFilters,
  noResultsMessage,
  isLoadingNextPage,
  showAddScoopModal,
  preselectedProperty,
  preselectedCompany,
  setShowAddScoopModal,
  feedContentClassName,
  isDropdownFilterSticked = false,
}) => {
  const { t } = useTranslation();
  const tagsFilterContainerRef = useRef<HTMLDivElement>(null);
  const [isSticked, setIsSticked] = useState(isDropdownFilterSticked);
  const [successMessage, setSuccessMessage] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [selectedFilterItems, setSelectedFilterItems] = useState<IdName[]>([]);

  useEffect(() => {
    setIsSticked(isDropdownFilterSticked);
  }, [isDropdownFilterSticked]);

  useLayoutEffect(() => {
    const onScroll = () => {
      if (!tagsFilterContainerRef.current) return;

      const tagsFilterPosition =
        tagsFilterContainerRef.current.getBoundingClientRect().y +
        NAVBAR_HEIGHT +
        TAGS_FILTER_TOP_DISTANCE;

      if (isDropdownFilterSticked && window.pageYOffset > tagsFilterPosition) {
        setIsSticked(true);
      } else if (window.pageYOffset < tagsFilterPosition) {
        setIsSticked(false);
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [isDropdownFilterSticked]);

  const onSuccessScoop = (message: string) => {
    setSuccessMessage(message);
    setShowNotification(true);
  };

  const renderEmptyMessage = () =>
    !activities.length &&
    !loading &&
    !isLoadingFilters && (
      <ActivityFeedEmptyMessage
        message={noResultsMessage}
        wrapperClassName={styles['empty-activities-container']}
        onAddScoopButtonClick={() => setShowAddScoopModal(true)}
      />
    );

  const renderScrollContent = () => {
    const shouldRenderLoadingMsg = loading && !isLoadingNextPage;
    const markets = filters
      ? filters?.customCriteria?.markets || []
      : undefined;
    const activitiesValue =
      (!loading && !isLoadingNextPage) || isLoadingNextPage ? activities : [];

    return (
      <InfiniteScroll
        onPageEndReached={loadNextPage}
        loading={!!isLoadingNextPage}
        loadingMessage={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.scoop_plural`)}
      >
        {shouldRenderLoadingMsg && (
          <LoadingMessage
            itemBeingLoaded={t(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.scoop_plural`,
            )}
          />
        )}
        <ActivityFeedContent
          groupByDate
          markets={markets}
          refetchScoopList={refetch}
          activities={activitiesValue}
          onMarketClick={onMarketClick}
          preselectedProperty={preselectedProperty}
        />
      </InfiniteScroll>
    );
  };

  const renderContent = () => renderEmptyMessage() || renderScrollContent();

  const renderTagsFilter = () => (
    <div className={styles['tags-filter-container']}>
      <div
        ref={tagsFilterContainerRef}
        className={classnames(styles['tags-filter'], {
          [styles['is-sticked']]: isSticked,
        })}
      >
        <ScoopsDropdownFilter
          defaultSelectedItems={selectedFilterItems}
          onChange={(value: IdName[]) => {
            scoopsOnChange(value);
            setSelectedFilterItems(value);
          }}
        />
      </div>
    </div>
  );

  return (
    <>
      <Row>
        <Col
          wrapperClassName={classnames(
            styles['feed-content'],
            feedContentClassName,
          )}
        >
          {header}
          {!loading && renderTagsFilter()}
          {renderContent()}
        </Col>
      </Row>
      <ManageScoopModal
        isVisible={showAddScoopModal}
        onSuccessNotification={onSuccessScoop}
        preselectedProperty={preselectedProperty}
        preselectedCompany={preselectedCompany}
        onCloseModal={() => setShowAddScoopModal(false)}
      />
      <NotificationMessage
        isSuccess
        text={successMessage}
        show={showNotification}
        onClose={() => setShowNotification(false)}
      />
    </>
  );
};

export default ActivityFeedScrollContent;
