import { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { MARKETING_PRICING_MUTATION } from 'graphql/historicalPerformance';
import { MARKET_PRICING_QUERY } from 'graphql/historicalPerformance/queries';
import {
  IMarketPricing,
  IMarketPricingResponse,
} from 'interfaces/IMarketPricing';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { ICustomValidation } from 'components/Inputs/DateInput/constants';
import { isUniqueYearAndQuarter, isValidDate } from 'utils/date';
import { translateText } from 'utils/i18n';

import { SegmentPricingParams } from '../interfaces';
import {
  getSegmentPricingQueryVariables,
  preparePayloadToSubmit,
  prepareTableData,
} from './utils';

interface Props {
  segmentPricingParams: SegmentPricingParams | undefined;
  onClose: () => void;
}
export const useSegmentPricingData = ({
  segmentPricingParams,
  onClose,
}: Props) => {
  const [localData, setLocalData] = useState<IMarketPricing[]>([]);
  const [dateValidations, setDateValidations] = useState<
    Record<number, ICustomValidation>
  >({});

  const segmentQueryVariables = getSegmentPricingQueryVariables(
    segmentPricingParams,
  );
  const { market, propertyType, submarket, propertyId } =
    segmentPricingParams ?? {};

  const { loading: marketPricingLoading } = useQuery<IMarketPricingResponse>(
    MARKET_PRICING_QUERY,
    {
      variables: segmentQueryVariables,
      fetchPolicy: 'no-cache',
      skip:
        !!market?.id &&
        !!propertyType?.id &&
        !!submarket?.id &&
        !!propertyId &&
        !segmentQueryVariables,
      onCompleted: data => {
        setLocalData(prepareTableData(data.marketPricing));
      },
    },
  );

  const [saveSegmentPricing, { loading: saveSegmentLoading }] = useMutation(
    MARKETING_PRICING_MUTATION,
    {
      variables: {
        input: preparePayloadToSubmit(segmentPricingParams, localData),
      },
      onCompleted: onClose,
    },
  );

  const validateDuplicateQuarters = (
    rowId: string,
    id: number,
    inputDate: string | null,
  ) => {
    const columnDates = localData?.filter(d => d.id !== id).map(t => t.date);

    const isUniqueQuarter = isUniqueYearAndQuarter(
      columnDates,
      inputDate ?? '',
    );

    setDateValidations(prev => ({
      ...prev,
      [rowId]: !isUniqueQuarter
        ? {
            isValid: false,
            message: translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.oneRecordPerQuarterError`,
            ),
          }
        : { isValid: true, message: '' },
    }));
  };

  const isDisabledSubmit = useMemo(() => {
    const isAnyInvalidDate = localData?.some(d => !isValidDate(d.date));
    const isAnyCustomInvalidDate = Object.values(dateValidations).some(
      v => !v.isValid,
    );

    return (
      !localData?.length ||
      saveSegmentLoading ||
      isAnyInvalidDate ||
      isAnyCustomInvalidDate
    );
  }, [localData, saveSegmentLoading, dateValidations]);

  return {
    variables: { localData, isDisabledSubmit, dateValidations },
    loadings: {
      marketPricingLoading,
      saveSegmentLoading,
    },
    handlers: { saveSegmentPricing, setLocalData, validateDuplicateQuarters },
  };
};
