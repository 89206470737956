export const SUBSCRIPTIONS = {
  AVANT_ACTIVITY_FEED: {
    name: 'Avant.Page.ActivityFeed',
    query: 'apaf',
  },
  AVANT_FIND_COMPS: {
    name: 'Avant.Page.FindComps',
    query: 'apfc',
  },
  AVANT_COMPANY_PROFILE: {
    name: 'Avant.Page.CompanyProfile',
    query: 'apcp',
  },
  AVANT_PROPERTY_PROFILE: {
    name: 'Avant.Page.PropertyProfile',
    query: 'appp',
  },
  AVANT_SUBMARKET_PROFILE: {
    name: 'Avant.Page.SubmarketProfile',
    query: 'apsp',
  },
  AVANT_MARKET_ANALYTICS: {
    name: 'Avant.Page.MarketAnalytics',
    query: 'apma',
  },
  AVANT_FUNCTIONALITY_EDITOR: {
    name: 'Avant.Functionality.Editor',
    query: 'afe',
  },
  AVANT_FUNCTIONALITY_GRANULAR: {
    name: 'Avant.Functionality.Granular',
    query: 'afg',
  },
  AVANT_APPLICATION_PROPERTIES: {
    name: 'Avant.Application.Properties',
  },
  AVANT_APPLICATION_CITIES: {
    name: 'Avant.Application.Cities',
  },
  AVANT_EXTERNAL_LINK_HUBSPOT: {
    name: 'Avant.ExternalLink.Hubspot',
  },
  AVANT_EXTERNAL_LINK_COSTAR: {
    name: 'Avant.ExternalLink.CoStar',
  },
  AVANT_EXTERNAL_LINK_BUILD_OUT: {
    name: 'Avant.ExternalLink.Buildout',
  },
  AVANT_EXTERNAL_LINK_ALTUS: {
    name: 'Avant.ExternalLink.Altus',
  },
};

export const PAGES = {
  PAGE_ACTIVITY_FEED: 'Activity Feed',
  PAGE_PROPERTY_PROFILE: 'Property Profile',
  PAGE_COMPANY_PROFILE: 'Company Profile',
  PAGE_FIND_COMPS: 'Find Comps',
  PAGE_MARKET_ANALYTICS: 'Market Analytics',
  PAGE_SUBMARKET_PROFILE: 'Submarket Profile',
  PAGE_ADD_COMPS: 'Add Comps',
  PAGE_UPLOAD_COMPS: 'Upload Comps',
  PAGE_MARKET_PROFILE: 'Market Profile',
  PAGE_TENANT_TOUR_CREATION: 'Tenant Tour Creation',
  PAGE_TENANT_TOURS: 'Tenant Tours',
  PAGE_HOME: 'Home',
  PAGE_AGENCY_ASSIGNMENTS: 'Agency Leasing Assignment',
  PAGE_ADD_SCOOP: 'Add Scoop',
  SHOW_COMPS: 'Show Comps',
  SHOW_PURSUITS: 'Show Pursuits',
  SHOW_PROPERTY_COMP_SETS: 'Show Property Compsets',
  SHOW_PROJECTS: 'Show Projects',
  SHOW_ADDRESS: 'Show Address',
};

export const FUNCTIONALITIES = {
  EDITOR: 'Editor',
  GRANULAR: 'Granular',
};

export const APPLICATIONS = {
  PROPERTIES: 'Properties',
  CITIES: 'Cities',
};
export const EXTERNAL_LINKS = {
  SALESFORCE: 'Salesforce',
  HUBSPOT: 'Hubspot',
  COSTAR: 'CoStar',
  BUILD_OUT: 'Buildout',
  SHARP_LAUNCH: 'SharpLaunch',
  ALTUS: 'Altus',
};
