import { orderBy } from 'lodash';
import { IMarketPricing } from 'interfaces/IMarketPricing';
import { getDateISOString, getYearFromDate } from 'utils/date';
import { convertToNumber } from 'utils/parsers/convertToNumber';
import { formatNumberWithCommas } from 'utils/formatters/number';

import { SegmentPricingIds, SegmentPricingParams } from '../interfaces';
import { isNumber } from 'lodash';

export const getSegmentPricingQueryVariables = (
  segmentParams: SegmentPricingParams | undefined,
) => {
  const {
    market,
    submarket,
    propertyType,
    propertySubtype,
    propertyClass,
    propertyId,
  } = segmentParams ?? {};

  const hasMarketParams = !!market?.id && !!propertyType?.id && !!submarket?.id;

  if (!hasMarketParams && !propertyId) return undefined;

  return {
    filter: hasMarketParams
      ? {
          marketId: market?.id,
          submarketId: submarket?.id,
          propertyTypeId: propertyType?.id,
          propertySubtypeId: propertySubtype?.id,
          thirdPartyPropertyClassId: propertyClass?.id,
        }
      : { propertyId },
  };
};

export const prepareTableData = (data: IMarketPricing[]) => {
  return data?.map(d => ({
    ...d,
    date: getDateISOString(d.date),
    directRent: formatNumberWithCommas(d.directRent),
  }));
};

export const preparePayloadToSubmit = (
  segmentPricingParams: SegmentPricingParams | undefined,
  tableData: IMarketPricing[],
) => {
  if (!segmentPricingParams) return;

  const {
    market,
    submarket,
    propertyType,
    propertyClass,
    propertySubtype,
    rentType,
    timeMeasurement,
  } = segmentPricingParams;

  const data = tableData?.map(d => ({
    id: d.id,
    date: d.date,
    year: +getYearFromDate(d.date),
    darPercent: convertToNumber(d.darPercent),
    directRent: convertToNumber(d.directRent),
    freeRentLongTerm: convertToNumber(d.freeRentLongTerm),
    tiLongTerm: convertToNumber(d.tiLongTerm),
    tiPercent: convertToNumber(d.tiPercent),
  }));

  return {
    marketId: market?.id,
    submarketId: submarket?.id,
    propertyTypeId: propertyType?.id,
    classId: propertyClass?.id,
    propertySubTypeId: propertySubtype?.id,
    timeMeasurement: timeMeasurement,
    rentType: rentType,
    data: orderBy(data, ['date'], ['desc']),
  };
};

export const getCalculationData = (
  rowIndex: number,
  data: IMarketPricing[],
  columnId: SegmentPricingIds,
) => {
  const row = data[rowIndex];
  return Number(row[columnId]);
};

export const calculatePercentage = (previous: number, current: number) => {
  if (previous === 0 || current === 0) return null;
  return Math.round(((current - previous) / previous) * 100);
};
export const findNearestDateIndex = (
  targetDate: Date,
  dates: Date[],
  nearestPastDate: boolean,
): number => {
  if (dates.length === 0) return -1;

  const targetTime = targetDate.getTime();

  const validIndexes = dates
    .map((date, index) => ({ date, index }))
    .filter(({ date }) =>
      nearestPastDate
        ? date.getTime() < targetTime
        : date.getTime() > targetTime,
    );

  if (validIndexes.length === 0) return -1;

  return validIndexes.reduce(
    (nearest, current) =>
      Math.abs(current.date.getTime() - targetTime) <
      Math.abs(nearest.date.getTime() - targetTime)
        ? current
        : nearest,
    validIndexes[0],
  ).index;
};

export const calculatePercentages = (
  rowIndex: number,
  value: string | number | null,
  columnId: SegmentPricingIds,
  localData: IMarketPricing[],
  handleTableDataChange: (
    rowIndex: number,
    columnId: string,
    value: string | number | null,
    isDate: boolean,
  ) => void,
) => {
  const rowDate = localData[rowIndex].date;
  const previousDateIndex = findNearestDateIndex(
    new Date(rowDate),
    localData.map(data => new Date(data.date)),
    true,
  );
  const furtherDateIndex = findNearestDateIndex(
    new Date(rowDate),
    localData.map(data => new Date(data.date)),
    false,
  );
  const previousData =
    previousDateIndex !== -1
      ? getCalculationData(previousDateIndex, localData, columnId)
      : undefined;

  const furtherData =
    furtherDateIndex !== -1
      ? getCalculationData(furtherDateIndex, localData, columnId)
      : undefined;

  if (isNumber(previousData)) {
    handleTableDataChange(
      rowIndex,
      columnId === SegmentPricingIds.directRent
        ? SegmentPricingIds.darPercent
        : SegmentPricingIds.tiPercent,
      calculatePercentage(previousData, Number(value)),
      false,
    );
  }

  if (isNumber(furtherData) && previousDateIndex !== furtherDateIndex) {
    handleTableDataChange(
      furtherDateIndex,
      columnId === SegmentPricingIds.directRent
        ? SegmentPricingIds.darPercent
        : SegmentPricingIds.tiPercent,
      calculatePercentage(Number(value), furtherData),
      false,
    );
  }
};
