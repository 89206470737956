import React from 'react';
import { CellProps, Column } from 'react-table';

import { I18N_HISTORICAL_PERFORMANCE_LABEL_PATH } from 'components/HistoricalPerformance/constants';
import DateInput from 'components/Inputs/DateInput';
import Dropdown from 'components/Dropdown';
import {
  I18N_AVANT_PLATFORM_LEASE_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { LeaseRentType } from 'constants/leases';
import { TimeMeasurement } from 'constants/timeMeasurement';
import { IAssetPerformance } from 'interfaces/IAssetPerformance';
import { translateText } from 'utils/i18n';

import styles from '../HistoricalPerformanceModal.module.scss';
import { AvailabilityVacancyIds } from '../interfaces';

interface TableField {
  header: string;
  accessor: AvailabilityVacancyIds;
  rightSymbol?: string;
  leftSymbol?: string;
}

const getTableArrayFields = (
  isDataCenterType: boolean,
  unitOfMeasurement: UnitOfMeasurement,
  currencySymbol: string,
): TableField[] => {
  const DATA_CENTER_FIELDS = [
    {
      header: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.shellAvailable`),
      accessor: AvailabilityVacancyIds.availableSfShell,
      rightSymbol: unitOfMeasurement,
    },
    {
      header: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.shellVacant`),
      accessor: AvailabilityVacancyIds.vacantSfShell,
      rightSymbol: unitOfMeasurement,
    },
    {
      header: translateText(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.turnkeyAvailable`,
      ),
      accessor: AvailabilityVacancyIds.availableKwTurnkey,
      rightSymbol: UnitOfMeasurement.kw,
    },
    {
      header: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.turnkeyVacant`),
      accessor: AvailabilityVacancyIds.vacantKwTurnkey,
      rightSymbol: UnitOfMeasurement.kw,
    },
  ];

  const TABLE_LEADING_FIELDS = [
    {
      header: translateText(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.directAvailable`,
      ),
      accessor: AvailabilityVacancyIds.availableSfDirect,
      rightSymbol: unitOfMeasurement,
    },
    {
      header: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.directVacant`),
      accessor: AvailabilityVacancyIds.vacantSfDirect,
      rightSymbol: unitOfMeasurement,
    },
    {
      header: translateText(
        `${I18N_HISTORICAL_PERFORMANCE_LABEL_PATH}.subleaseAvailable`,
      ),
      accessor: AvailabilityVacancyIds.availableSfSublet,
      rightSymbol: unitOfMeasurement,
    },
    {
      header: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.subleaseVacant`),
      accessor: AvailabilityVacancyIds.vacantSfSublet,
      rightSymbol: unitOfMeasurement,
    },
  ];

  const TABLE_GENERAL_FIELDS = [
    {
      header: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.rent`),
      accessor: AvailabilityVacancyIds.baseRent,
      leftSymbol: currencySymbol,
    },
  ];

  const dynamicFields = isDataCenterType
    ? DATA_CENTER_FIELDS
    : TABLE_LEADING_FIELDS;

  return [...dynamicFields, ...TABLE_GENERAL_FIELDS];
};

export const getTableColumns = (
  handleTableDataChange: (
    rowIndex: number,
    columnId: AvailabilityVacancyIds,
    value: string | number | null,
    isDate: boolean,
  ) => void,
  onInputBlur: (
    rowIndex: number,
    value: string | number | null,
    columnId: AvailabilityVacancyIds,
  ) => void,
  defineIsFocusedCell: (rowIndex: number, columnId: string) => boolean,
  setFocusedCell: (rowIndex: number, columnId: string) => void,
  unitOfMeasurement: UnitOfMeasurement,
  isDataCenterType: boolean,
  currencySymbol: string,
): Column<IAssetPerformance>[] => [
  {
    Header: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.year`),
    accessor: AvailabilityVacancyIds.date,
    Cell: ({ row, cell }) => (
      <div className={styles['cell-content']}>
        <DateInput
          id="availability-date"
          disableFocusShift
          wrapperClassName={styles['date-input']}
          errorMessageClass={styles['date-input-error-message']}
          value={row.original.date}
          onBlur={value => {
            handleTableDataChange(
              row.index,
              cell.column.id as AvailabilityVacancyIds,
              value,
              true,
            );
          }}
        />
      </div>
    ),
  },
  ...getTableArrayFields(
    isDataCenterType,
    unitOfMeasurement,
    currencySymbol,
  ).map(field => {
    return {
      Header: () => <span>{field.header}</span>,
      accessor: field.accessor,
      Cell: ({
        row,
        cell,
        value,
      }: CellProps<IAssetPerformance, string | number | null>) => (
        <div className={styles['cell-content']}>
          {field.leftSymbol && <span>{field.leftSymbol}</span>}
          <input
            className={styles['cell-input']}
            onFocus={() => setFocusedCell(row.index, cell.column.id)}
            autoFocus={defineIsFocusedCell(row.index, cell.column.id)}
            value={value ?? ''}
            onBlur={e => {
              onInputBlur(row.index, e.target.value, field.accessor);
            }}
            onChange={e =>
              handleTableDataChange(
                row.index,
                field.accessor,
                e.target.value,
                false,
              )
            }
          />
          {field?.rightSymbol && <span>{field.rightSymbol}</span>}
        </div>
      ),
    } as Column<IAssetPerformance>;
  }),
  {
    Header: translateText(`${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.rentType`),
    accessor: AvailabilityVacancyIds.rentType,
    Cell: ({ row }) => {
      return (
        <div className={styles['cell-content']}>
          <Dropdown
            hideDropdownArrow
            inputSpanClassName={styles['cell-input']}
            selectedId={row.original.rentType!}
            editOnFocus
            onChange={v => {
              handleTableDataChange(
                row.index,
                AvailabilityVacancyIds.rentType,
                v,
                false,
              );
            }}
            defaultOpen
            options={[
              {
                id: LeaseRentType.FS,
                label: translateText(
                  'avantPlatform.attributes.lease.label.rentTypeFS',
                ),
                value: LeaseRentType.FS,
              },
              {
                id: LeaseRentType.NNN,
                label: translateText(
                  'avantPlatform.attributes.lease.label.rentTypeNNN',
                ),
                value: LeaseRentType.NNN,
              },
              {
                id: LeaseRentType.MG,
                label: LeaseRentType.MG,
                value: LeaseRentType.MG,
              },
              {
                id: LeaseRentType.IG,
                label: LeaseRentType.IG,
                value: LeaseRentType.IG,
              },
              {
                id: LeaseRentType.NN,
                label: LeaseRentType.NN,
                value: LeaseRentType.NN,
              },
              {
                id: LeaseRentType.N,
                label: LeaseRentType.N,
                value: LeaseRentType.N,
              },
            ]}
          />
        </div>
      );
    },
  },
  {
    Header: translateText(
      `${I18N_HISTORICAL_PERFORMANCE_LABEL_PATH}.annualMonthly`,
    ),
    accessor: AvailabilityVacancyIds.timeMeasurement,
    Cell: ({ row }) => {
      return (
        <div className={styles['cell-content']}>
          <Dropdown
            hideDropdownArrow
            inputSpanClassName={styles['cell-input']}
            selectedId={row.original.timeMeasurement!}
            editOnFocus
            onChange={v => {
              handleTableDataChange(
                row.index,
                AvailabilityVacancyIds.timeMeasurement,
                v,
                false,
              );
            }}
            defaultOpen
            options={[
              {
                id: TimeMeasurement.ANNUAL,
                label: TimeMeasurement.ANNUAL,
                value: TimeMeasurement.ANNUAL,
              },
              {
                id: TimeMeasurement.MONTHLY,
                label: TimeMeasurement.MONTHLY,
                value: TimeMeasurement.MONTHLY,
              },
            ]}
          />
        </div>
      );
    },
  },
  {
    Header: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.note`),
    accessor: AvailabilityVacancyIds.notes,
    Cell: ({ row, cell, value }) => (
      <div className={styles['cell-content']}>
        <input
          className={styles['cell-input']}
          onFocus={() => setFocusedCell(row.index, cell.column.id)}
          autoFocus={defineIsFocusedCell(row.index, cell.column.id)}
          onBlur={e => {
            onInputBlur(
              row.index,
              e.target.value,
              AvailabilityVacancyIds.notes,
            );
          }}
          value={value ?? ''}
          onChange={e =>
            handleTableDataChange(
              row.index,
              AvailabilityVacancyIds.notes,
              e.target.value,
              false,
            )
          }
        />
      </div>
    ),
  },
];
