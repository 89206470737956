import { SearchOptionsType } from 'interfaces/ISearchOptionsType';
import { SearchOperations } from 'constants/searchOperations';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { translateText } from 'utils/i18n';

export const LEASE_COMP_STATUSES: SearchOptionsType[] = [
  {
    id: 1,
    name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.completeComps`),
    field: 'compStatus',
    filter: {
      operator: SearchOperations.eq,
      value: 'COMPLETE_COMP',
    },
  },
  {
    id: 2,
    name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.partialComps`),
    field: 'compStatus',
    filter: {
      operator: SearchOperations.eq,
      value: 'PARTIAL_COMP',
    },
  },
  {
    id: 3,
    name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.shellComps`),
    field: 'compStatus',
    filter: {
      operator: SearchOperations.eq,
      value: 'SHELL_COMP',
    },
  },
];
