import { gql } from '@apollo/client';

export const PROPERTY_CLICKTHROUGHS_FRAGMENT = gql`
  fragment propertyClickthroughsFragment on Property {
    id
    name
    type
    primaryAddress
    secondaryAddress
    tertiaryAddress
    quaternaryAddress
    numberOfAlternativeAddresses
    displayAddress
    buildingSize
    buildingSizeMt
    stories
    currencyCode
    country
    propertyCountry {
      id
      name
      code
      flagURL
      distanceMeasurement
      areaMeasurement
      currencyCode
    }
    latitude
    longitude
    latitudeDisplay
    longitudeDisplay
    propertyType {
      id
      name
    }
    propertySubtype {
      id
      name
    }
    market {
      id
      name
    }
    measurementSystem
    submarket {
      id
      name
    }
    micromarket {
      id
      name
    }
    propertyClass {
      id
      name
    }
    coverMediaUrl
    coverMediaType
  }
`;

export const PROPERTY_BASIC_DATA_FRAGMENT = gql`
  fragment propertyBasicDataFragment on Property {
    id
    name
    type
    builtYear
    primaryAddress
    secondaryAddress
    tertiaryAddress
    quaternaryAddress
    numberOfAlternativeAddresses
    displayAddress
    buildingSize
    buildingSizeMt
    stories
    currencyCode
    country
    city
    propertyCountry {
      id
      name
      code
      flagURL
      distanceMeasurement
      areaMeasurement
      currencyCode
    }
    expenses {
      id
      tax
      operatingExpenses
      taxAndOpex
      year
      timeMeasurement
    }
    latitude
    longitude
    latitudeDisplay
    longitudeDisplay
    propertyType {
      id
      name
    }
    propertySubtype {
      id
      name
    }
    tenancyType {
      id
      name
    }
    market {
      id
      name
    }
    measurementSystem
    submarket {
      id
      name
    }
    micromarket {
      id
      name
    }
    propertyClass {
      id
      name
    }
    locationType {
      id
      name
    }
    status {
      id
      name
    }
    owners {
      id
      name
    }
    longLeaseHolders {
      id
      name
      website
      logoUrl
    }
    businessPark {
      id
      name
    }
    coverMediaUrl
    coverMediaType
    buildDate
    brokeGroundDate
    demolishedConverted
    renovatedYears {
      year
    }
  }
`;

export const PROPERTY_COMPLETE_DATA_FRAGMENT = gql`
  fragment propertyCompleteDataFragment on Property {
    amenities {
      id
      name
    }
    parkingSpots
    parkingRatio
    buildDate
    brokeGroundDate
    convertedFromPropertyId
    demolishedConverted
    builtYear
    buildingSize
    buildingSizeMt
    city
    state
    country
    postalCode
    currencyCode
    createdAt
    updatedAt
    createdUser {
      id
      firstName
      lastName
      email
    }
    updatedUser {
      id
      firstName
      lastName
      email
    }
    comments {
      id
      text
    }
    description
    expense {
      tax
      operatingExpenses
      taxAndOpex
      year
      timeMeasurement
    }
    floorPlateSize
    floorPlateSizeMt
    id
    healthcare {
      id
      numBeds
      providerCompanies {
        id
        name
      }
    }
    siteSize
    siteSizeSf
    siteSizeMt
    siteSizeHectares
    zoning
    industrial {
      id
      businessParkType
      transportationConnectivity
      loadingDocks
      dockConfiguration
      typicalClearHeightMin
      typicalClearHeightMinMt
      typicalClearHeightMax
      typicalClearHeightMaxMt
      columnSpacingMin
      columnSpacingMax
      columnSpacingMinMt
      columnSpacingMaxMt
      driveInDoors
      trailerParkingSpaces
      outsideStorage
      heavyPower
      sprinklers
      buildingDepth
    }
    multifamily {
      id
      totalUnits
      avgUnitSizeMt
      avgUnitSizeSf
      unitsZeroBed
      unitsOneBed
      unitsTwoBed
      unitsThreeBed
    }
    dataCenter {
      id
      createdAt
      updatedAt
      fiberLinesCompanies {
        id
        name
      }
      cloudServicesCompanies {
        id
        name
      }
      typicalCeilingHeightFeet
      typicalCeilingHeightInches
      typicalCeilingHeightMt
      underConstPower
      underConstSf
      underConstMt
      plannedPower
      plannedSf
      plannedMt
      commissionedPower
      commissionedSf
      commissionedMt
      costPerKwhMin
      costPerKwhMax
      feedType
      hasOnsiteSubstation
      powerProviderCompany {
        id
        name
      }
      numSubstationsServingFacility
      raisedFloorHeight
      raisedFloorHeightMt
      ups
      generator
      pdu
      crah
      cooling
      totalPower
    }
    latitude
    latitudeDisplay
    leasingCompany {
      id
      name
      website
      logoUrl
    }
    siteNumberProviderCompany {
      id
      name
    }
    architectCompany {
      id
      name
    }
    uarn
    currentYearTaxAssessment
    siteNumber
    fitwell
    well
    planningStatus
    numberOfElevators
    longitude
    longitudeDisplay
    market {
      id
      name
    }
    measurementSystem
    micromarket {
      id
      name
    }
    name
    office {
      columnSpacingMax
      columnSpacingMaxMt
      columnSpacingMin
      columnSpacingMinMt
      typicalCeilingHeightFeet
      typicalCeilingHeightInches
      typicalCeilingHeightMt
      storiesAboveGround
      storiesBelowGround
    }
    lifeScience {
      loadingDocks
      dockConfiguration
      driveInDoors
      typicalClearHeightMin
      typicalClearHeightMax
      conversion
      slabCeilingHeightFeet
      slabCeilingHeightInches
      columnSpacingMax
      columnSpacingMaxMt
      columnSpacingMin
      columnSpacingMinMt
      typicalCeilingHeightFeet
      typicalCeilingHeightInches
      typicalCeilingHeightMt
      storiesAboveGround
      storiesBelowGround
    }
    constructionType {
      name
    }
    owners {
      id
      name
      website
      logoUrl
    }
    businessPark {
      name
    }
    longLeaseHolders {
      id
      name
      website
      logoUrl
    }
    developmentCompanies {
      id
      name
      website
      logoUrl
    }
    managementCompany {
      id
      name
      website
      logoUrl
    }
    operatorCompany {
      id
      name
      website
      logoUrl
    }
    primaryAddress
    secondaryAddress
    tertiaryAddress
    quaternaryAddress
    numberOfAlternativeAddresses
    displayAddress
    propertyCountry {
      id
      name
      code
      flagURL
      distanceMeasurement
      areaMeasurement
      currencyCode
    }
    propertyClass {
      id
      name
    }
    propertyType {
      id
      name
    }
    propertySubtype {
      id
      name
    }
    propertySubtypeDetail {
      id
      name
    }
    tenancyType {
      id
      name
    }
    locationType {
      id
      name
    }
    ownerOccupied {
      id
      name
    }
    renovatedYears {
      id
      year
    }
    siteSize
    siteSizeHectares
    siteCoverageRatio
    status {
      id
      name
    }
    stories
    submarket {
      id
      name
    }
    type
    coverMediaType
    coverMediaUrl
    thirdPartyId
    includeInStats
    expenses {
      id
      tax
      operatingExpenses
      taxAndOpex
      year
      timeMeasurement
    }
  }
`;

export const PROPERTY_COMPLETE_DATA_FRAGMENT_WITHOUT_COMMENTS = gql`
  fragment propertyCompleteDataFragment on Property {
    amenities {
      id
      name
    }
    certificationObjects {
      id
      objectTypeId
      objectType {
        id
        name
      }
      objectId
      certificationTypeId
    }
    parkingSpots
    parkingRatio
    buildDate
    brokeGroundDate
    convertedFromPropertyId
    demolishedConverted
    preLeasedPct
    preLeasedSm
    preLeasedSf
    preLeasedCustomSf
    preLeasedCustomSm
    preLeasedCustomPct
    builtYear
    buildingSize
    buildingSizeMt
    officeSize
    officeSizeMt
    warehouseSize
    warehouseSizeMt
    city
    state
    country
    postalCode
    currencyCode
    createdAt
    updatedAt
    createdUser {
      id
      firstName
      lastName
      email
    }
    updatedUser {
      id
      firstName
      lastName
      email
    }
    description
    expense {
      tax
      operatingExpenses
      taxAndOpex
      year
      timeMeasurement
    }
    floorPlateSize
    floorPlateSizeMt
    id
    healthcare {
      id
      numBeds
      providerCompanies {
        id
        name
      }
    }

    siteSize
    siteSizeSf
    siteSizeMt
    siteSizeHectares
    zoning

    industrial {
      id
      amps
      railServed {
        id
        name
      }
      railProvider {
        id
        name
      }
      businessParkType
      craneServed
      craneCapacity
      transportationConnectivity
      loadingDocks
      dockConfiguration
      dockConfigurationType {
        id
        name
      }
      typicalClearHeightMin
      typicalClearHeightMinMt
      typicalClearHeightMax
      typicalClearHeightMaxMt
      columnSpacingMin
      columnSpacingMax
      columnSpacingMinMt
      columnSpacingMaxMt
      driveInDoors
      hookHeight
      hookHeightMt
      trailerParkingSpaces
      outsideStorage
      heavyPower
      sprinklers
      buildingDepth
    }
    multifamily {
      id
      totalUnits
      avgUnitSizeMt
      avgUnitSizeSf
      unitsZeroBed
      unitsOneBed
      unitsTwoBed
      unitsThreeBed
    }
    dataCenter {
      id
      createdAt
      updatedAt
      dataCenterBuildOutType {
        id
        name
      }
      fiberLinesCompanies {
        id
        name
      }
      cloudServicesCompanies {
        id
        name
      }

      typicalCeilingHeightFeet
      typicalCeilingHeightInches
      typicalCeilingHeightMt
      underConstPower
      underConstSf
      underConstMt
      plannedPower
      plannedSf
      plannedMt
      commissionedPower
      commissionedSf
      commissionedMt
      costPerKwhMin
      costPerKwhMax
      feedType
      hasOnsiteSubstation
      powerProviderCompany {
        id
        name
      }
      powerUseEfficiency
      numSubstationsServingFacility
      raisedFloorHeight
      raisedFloorHeightMt
      ups
      upsDescription
      generator
      generatorDescription
      pdu
      pduDescription
      crah
      crahDescription
      cooling
      totalPower
      retrofitYear
    }
    latitude
    latitudeDisplay
    leasingCompany {
      id
      name
      website
      logoUrl
    }
    siteNumberProviderCompany {
      id
      name
    }
    architectCompany {
      id
      name
    }
    uarn
    currentYearTaxAssessment
    siteNumber
    fitwell
    well
    planningStatus
    numberOfElevators
    longitude
    longitudeDisplay
    market {
      id
      name
    }
    measurementSystem
    micromarket {
      id
      name
    }
    name
    office {
      columnSpacingMax
      columnSpacingMaxMt
      columnSpacingMin
      columnSpacingMinMt
      typicalCeilingHeightFeet
      typicalCeilingHeightInches
      typicalCeilingHeightMt
      storiesAboveGround
      storiesBelowGround
    }
    lifeScience {
      dockConfigurationType {
        id
        name
      }
      loadingDocks
      dockConfiguration
      driveInDoors
      typicalClearHeightMin
      typicalClearHeightMax
      conversion
      slabCeilingHeightFeet
      slabCeilingHeightInches
      columnSpacingMax
      columnSpacingMaxMt
      columnSpacingMin
      columnSpacingMinMt
      typicalCeilingHeightFeet
      typicalCeilingHeightInches
      typicalCeilingHeightMt
      storiesAboveGround
      storiesBelowGround
    }
    constructionType {
      name
    }
    owners {
      id
      name
      website
      logoUrl
    }
    businessPark {
      name
    }
    longLeaseHolders {
      id
      name
      website
      logoUrl
    }
    developmentCompanies {
      id
      name
      website
      logoUrl
    }
    managementCompany {
      id
      name
      website
      logoUrl
    }
    operatorCompany {
      id
      name
      website
      logoUrl
    }

    primaryAddress
    secondaryAddress
    tertiaryAddress
    quaternaryAddress
    numberOfAlternativeAddresses
    displayAddress
    propertyCountry {
      id
      name
      code
      flagURL
      distanceMeasurement
      areaMeasurement
      currencyCode
    }
    propertyClass {
      id
      name
    }
    propertyType {
      id
      name
    }
    propertySubtype {
      id
      name
    }
    propertySubtypeDetail {
      id
      name
    }
    tenancyType {
      id
      name
    }
    locationType {
      id
      name
    }
    ownerOccupied {
      id
      name
    }
    renovatedYears {
      id
      year
    }
    siteSize
    siteSizeHectares
    siteCoverageRatio
    status {
      id
      name
    }
    stories
    submarket {
      id
      name
    }
    type
    coverMediaType
    coverMediaUrl
    thirdPartyId
    includeInStats
    expenses {
      id
      tax
      operatingExpenses
      taxAndOpex
      year
      timeMeasurement
    }
  }
`;

export const PROPERTY_COMP_SET_FRAGMENT = gql`
  fragment propertyCompSetFragment on Property {
    amenities {
      id
      name
    }
    builtYear
    buildingSizeMt
    floorPlateSizeMt
    buildingSize
    country
    city
    state
    currencyCode
    coverMediaType
    coverMediaUrl
    dataCenter {
      id
      createdAt
      updatedAt
      fiberLinesCompanies {
        id
        name
      }
      cloudServicesCompanies {
        id
        name
      }
      typicalCeilingHeightFeet
      typicalCeilingHeightInches
      typicalCeilingHeightMt
      underConstPower
      underConstSf
      underConstMt
      plannedPower
      plannedSf
      plannedMt
      commissionedPower
      commissionedSf
      commissionedMt
      costPerKwhMin
      costPerKwhMax
      feedType
      hasOnsiteSubstation
      powerProviderCompany {
        id
        name
      }
      numSubstationsServingFacility
      raisedFloorHeight
      raisedFloorHeightMt
      ups
      generator
      pdu
      crah
      cooling
      totalPower
    }
    displayAddress
    expenses {
      id
      tax
      operatingExpenses
      taxAndOpex
      year
      timeMeasurement
    }
    floorPlateSize
    id
    industrial {
      id
      businessParkType
      transportationConnectivity
      loadingDocks
      dockConfiguration
      typicalClearHeightMin
      typicalClearHeightMinMt
      typicalClearHeightMax
      typicalClearHeightMaxMt
      columnSpacingMin
      columnSpacingMax
      columnSpacingMinMt
      columnSpacingMaxMt
      driveInDoors
      trailerParkingSpaces
      outsideStorage
      heavyPower
      sprinklers
      buildingDepth
    }

    siteSize
    siteSizeSf
    siteSizeMt
    siteSizeHectares
    zoning

    latitude
    latitudeDisplay
    longitude
    longitudeDisplay
    leasingCompany {
      id
      name
      website
      logoUrl
    }
    lifeScience {
      loadingDocks
      dockConfiguration
      driveInDoors
      typicalClearHeightMin
      typicalClearHeightMax
      conversion
      slabCeilingHeightFeet
      slabCeilingHeightInches
      columnSpacingMax
      columnSpacingMaxMt
      columnSpacingMin
      columnSpacingMinMt
      typicalCeilingHeightFeet
      typicalCeilingHeightInches
      typicalCeilingHeightMt
      storiesAboveGround
      storiesBelowGround
    }
    market {
      id
      name
    }
    measurementSystem
    micromarket {
      id
      name
    }
    multifamily {
      id
      totalUnits
      avgUnitSizeMt
      avgUnitSizeSf
      unitsZeroBed
      unitsOneBed
      unitsTwoBed
      unitsThreeBed
    }
    numberOfAlternativeAddresses
    name
    office {
      columnSpacingMax
      columnSpacingMaxMt
      columnSpacingMin
      columnSpacingMinMt
      typicalCeilingHeightFeet
      typicalCeilingHeightInches
      typicalCeilingHeightMt
      storiesAboveGround
      storiesBelowGround
    }
    owners {
      id
      name
      website
      logoUrl
    }
    parkingRatio
    primaryAddress
    propertyClass {
      id
      name
    }
    propertyType {
      id
      name
    }
    propertySubtype {
      id
      name
    }
    renovatedYears {
      id
      year
    }
    quaternaryAddress
    secondaryAddress
    siteCoverageRatio
    submarket {
      id
      name
    }
    tertiaryAddress
  }
`;
