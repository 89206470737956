import { useCallback } from 'react';
import {
  REGEX_DECIMALS_WITH_COMMA,
  REGEX_NEGATIVE_PERCENTAGE,
} from 'constants/regex';
import { SegmentPricingIds } from '../interfaces';

export const useInputHandlers = (
  onChangeData: (row: number, col: string, val: any) => void,
) => {
  const handleDateChange = useCallback(
    (rowIndex: number, columnId: string, value: string | number | null) => {
      onChangeData(rowIndex, columnId, value);
    },
    [onChangeData],
  );

  const handleInputChange = useCallback(
    (rowIndex: number, columnId: string, value: string | number | null) => {
      switch (columnId as SegmentPricingIds) {
        case SegmentPricingIds.darPercent:
          if (REGEX_NEGATIVE_PERCENTAGE.test(String(value))) {
            onChangeData(rowIndex, columnId, value);
          }
          break;

        case SegmentPricingIds.tiPercent:
          if (REGEX_NEGATIVE_PERCENTAGE.test(String(value))) {
            onChangeData(rowIndex, columnId, value); // Allow negative values
          }
          break;

        default:
          if (REGEX_DECIMALS_WITH_COMMA.test(String(value)) || !value) {
            onChangeData(rowIndex, columnId, value);
          }
      }
    },
    [onChangeData],
  );

  return { handleDateChange, handleInputChange };
};
