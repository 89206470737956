import {
  ActivityFeedCriteriaType,
  BuildingSetType,
} from 'components/ActivityFeed/types';
import { SearchParamsService } from 'services/SearchParamsService';

export class ActivitiesSearchService {
  public static getSearchCriteria(
    searchParams?: string,
  ): ActivityFeedCriteriaType {
    if (!searchParams) {
      return {};
    }

    //extracting criteria param
    const customCriteriaParam = searchParams.match(/c\[(.*?)\]/)?.[0] || '';
    const customCriteria = SearchParamsService.parseURLParamsIntoCustomCriteriaObject(
      customCriteriaParam,
      ['markets', 'submarkets', 'propertyTypes', 'companies'],
    );

    //extracting buildingSet param
    const buildingSetParam = searchParams.match(/b\[(.*?)\]/)?.[0] || '';
    const buildingSet = SearchParamsService.parseURLParamsIntoBuildingSetObject(
      buildingSetParam,
    );

    return {
      customCriteria,
      buildingSet,
    };
  }

  public static parseCustomCriteriaToUrlParams(
    filters?: ActivityFeedCriteriaType,
  ): string {
    if (!filters) {
      return '';
    }

    return SearchParamsService.parseCustomCriteriaObjectToURLParams(
      filters.customCriteria,
      ['markets', 'submarkets', 'propertyTypes', 'companies'],
    );
  }

  public static parseBuildingSetToUrlParams(
    filters: ActivityFeedCriteriaType,
  ): string {
    if (!filters.buildingSet) return '';

    const buildingSetFilters: BuildingSetType = {
      id: filters.buildingSet.id,
      name: filters.buildingSet.name,
      propertyIds: filters.buildingSet.properties?.map(p => p.id!),
    };

    return SearchParamsService.parseBuildingSetToURLParams(buildingSetFilters);
  }
}
