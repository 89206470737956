import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

export const LEED_CERTIFICATION_TYPES = [
  {
    id: 4,
    name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.platinum`),
  },
  {
    id: 2,
    name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.gold`),
  },
  {
    id: 3,
    name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.silver`),
  },
  {
    id: 5,
    name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.certified`),
  },
  {
    id: 1,
    name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.none`),
  },
];

export const BREEAM_CERTIFICATION_TYPES = [
  {
    id: 6,
    name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.outstanding`),
  },
  { id: 7, name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.excellent`) },
  { id: 8, name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.veryGood`) },
  { id: 9, name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.good`) },
  { id: 10, name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.pass`) },
  {
    id: 11,
    name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.unclassified`),
  },
];

export const ENERGY_PERFORMANCE_TYPES = [
  { id: 34, name: 'A+' },
  { id: 20, name: 'A' },
  { id: 21, name: 'B' },
  { id: 22, name: 'C' },
  { id: 23, name: 'D' },
  { id: 24, name: 'E' },
  { id: 25, name: 'F' },
  { id: 26, name: 'G' },
];

export enum CertificationType {
  LEED,
  BREEAM,
  ENERGY_PERFORMANCE,
}

export enum ThirdPartyId {
  LEED = 5,
  BREEAM = 3,
  ENERGY = 18,
}
