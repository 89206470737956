import { IProperty } from 'interfaces/IProperty';
import { useQuery } from '@apollo/client';
import { GET_PROPERTY_QUERY } from 'graphql/property';

interface IUsePropertyResult {
  property: IProperty | undefined;
  isLoading: boolean;
}

export const useProperty = (
  id: number | undefined,
  onComplete?: (property: IProperty) => void,
): IUsePropertyResult => {
  const { data: propertyData, loading } = useQuery<
    { property: IProperty },
    { id: number | undefined }
  >(GET_PROPERTY_QUERY, {
    variables: { id },
    skip: !id,
    onCompleted: data => {
      onComplete?.(data.property);
    },
  });

  return { property: propertyData?.property, isLoading: loading };
};
