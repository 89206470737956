import React from 'react';

import { I18N_HISTORICAL_PERFORMANCE_LABEL_PATH } from 'components/HistoricalPerformance/constants';
import { formatStringWithDot } from 'utils/formatters/string';
import { formatPropertyClass } from 'utils/formatters/property';
import { translateText } from 'utils/i18n';

import styles from './SegmentPricing.module.scss';
import { SegmentPricingParams } from '../interfaces';

type Props = {
  segmentPricingParams: SegmentPricingParams | undefined;
};

const SegmentControl: React.FC<Props> = ({ segmentPricingParams }) => {
  const getSegmentDescription = () => {
    if (!segmentPricingParams)
      return (
        <span className={styles['segment-description']}>
          {translateText(
            `${I18N_HISTORICAL_PERFORMANCE_LABEL_PATH}.selectSegment`,
          )}
        </span>
      );

    const {
      market,
      submarket,
      propertyType,
      propertyClass,
      propertySubtype,
    } = segmentPricingParams;

    const areTypeAndSubTypeEqual =
      propertyType?.name?.toLowerCase() ===
      propertySubtype?.name?.toLowerCase();

    return (
      <span className={styles['segment-description']}>
        {formatStringWithDot([
          market?.name,
          submarket?.name,
          propertyType?.name,
          areTypeAndSubTypeEqual ? undefined : propertySubtype?.name,
          !formatPropertyClass(propertyClass)
            ? undefined
            : formatPropertyClass(propertyClass),
        ])}
      </span>
    );
  };

  return (
    <div className={styles['segment-control']}>
      <span className={styles['segment-title']}>
        {translateText(`${I18N_HISTORICAL_PERFORMANCE_LABEL_PATH}.segment`)}:
      </span>
      {getSegmentDescription()}
    </div>
  );
};

export default SegmentControl;
