import { IdName } from 'interfaces/IdName';

export enum MarketPricingRentType {
  NNN = 'NNN',
  FS = 'FS',
}

export enum MarketPricingTimeMeasurement {
  Annual = 'Annual',
  Monthly = 'Monthly',
}

export interface IMarketPricingYear {
  id?: number | null;
  year?: number;
  date?: string;
  directRent?: number | string;
  darPercent?: number | string;
  tiLongTerm?: number | string;
  tiPercent?: number | string;
  freeRentLongTerm?: number;
}

export interface IMarketPricingInput extends IMarketPricingYear {
  market?: IdName;
  submarket?: IdName;
  propertyType?: IdName;
  propertyClass?: IdName;
  propertySubtype?: IdName;
  createdUserId?: number;
  updatedUserId?: number;
  createdAt?: Date;
  updatedAt?: Date;
  rentType?: MarketPricingRentType | string;
  timeMeasurement?: MarketPricingTimeMeasurement | string;
}
