import { isUndefined, uniq } from 'lodash';

import { ICountry } from 'interfaces/IMarket';
import { IRangeValue } from 'interfaces/IRangeValue';
import { SearchOptionsType } from 'interfaces/ISearchOptionsType';
import { IPropertySearchFilters } from 'interfaces/IPropertySearch';
import {
  ModelsWithUnitsOfMeasurement,
  UnitOfMeasurement,
  UNITS_OF_MEASUREMENT_CODE_MAPPING,
} from 'constants/unitOfMeasurement';
import { PropertyTypeIds } from 'constants/propertyTypes';
import {
  EXPIRING_LEASES_OPTION,
  RECENT_LEASES_OPTION,
} from 'constants/leaseSort';
import { CountryCodes } from 'constants/countryCodes';
import { RECORD_TYPE_OPTIONS } from 'components/CreateComps/FormSection/LeaseForm/staticData';
import {
  getMeasurementSystemCode,
  getUnitOfMeasurementForProperty,
} from 'utils/unitsOfMeasurement';
import { getCurrencySymbolFromCountries } from 'utils/formatters/currency';
import { getDateISOString, getYearFromDate } from 'utils/date';

import {
  LeasesSearchCriteriaType,
  SalesSearchCriteriaType,
} from './EditSearchCriteria/types';
import {
  LEASE_INDUSTRIAL_SIZE_OPTIONS,
  LEASE_SIZE_OPTIONS,
} from './EditSearchCriteria/StaticFilters/leaseSize';
import { SearchCriteriaType } from './EditTimSearchCriteria/types';

const YEAR_LIMIT = 3000;

export const updateMeasurementAndCurrency = (
  countries?: ICountry[],
  setCurrencySymbol?: (value: string) => void,
  setUnitOfMeasurement?: (value: string) => void,
) => {
  const currencySymbol = getCurrencySymbolFromCountries(countries);
  setCurrencySymbol?.(currencySymbol);

  setUnitOfMeasurement?.(
    getUnitOfMeasurementForProperty(
      'buildingSize',
      ModelsWithUnitsOfMeasurement.Property,
      getMeasurementSystemCode(countries),
    ),
  );
};

/**
 * @param leasesCriteria
 * @param timeType - passing field name, which concatenates with suffix "TimeFrom", "TimeTo" in most cases
 * @param customFieldNames - for passing custom field name which are not ending with "TimeFrom", "TimeTo"
 */
export const getCustomLeaseTimeRange = (
  leasesCriteria?: LeasesSearchCriteriaType,
  timeType?: string,
  customFieldNames?: string[],
): IRangeValue => {
  const from =
    leasesCriteria?.[
      customFieldNames ? customFieldNames[0] : `${timeType}TimeFrom`
    ];
  const to =
    leasesCriteria?.[
      customFieldNames ? customFieldNames[1] : `${timeType}TimeTo`
    ];

  return {
    from,
    to,
  };
};

export const getCustomSaleDateRange = (
  salesCriteria?: SalesSearchCriteriaType,
): IRangeValue => {
  const from = salesCriteria?.saleDateFrom;
  const to = salesCriteria?.saleDateTo;

  return {
    from,
    to,
  };
};

export const getLeaseSizeOptions = (types?: SearchOptionsType[]) => {
  const typesIds = types?.map(t => t.id);
  if (
    typesIds?.includes(PropertyTypeIds.industrial) ||
    typesIds?.includes(PropertyTypeIds.flexIndustrial)
  )
    return LEASE_INDUSTRIAL_SIZE_OPTIONS;

  return LEASE_SIZE_OPTIONS;
};

const IGNORED_FIELD_KEYS = ['size', 'pricePerSF', 'buildingSize'];

export const getStartYearAndEndYearFromFilters = (
  defaultStartYear: number,
  defaultEndYear: number,
  filters?: IPropertySearchFilters,
  keyType?: string,
) => {
  const date = filters?.fields?.and?.filter(
    i => i.op == 'between' && i.key && !IGNORED_FIELD_KEYS.includes(i.key),
  );

  let keyTypeDate;
  if (keyType) {
    keyTypeDate = date?.find(x => x.key === keyType);
  } else {
    keyTypeDate = date?.[0];
  }

  if (!keyTypeDate) {
    return {
      startYear: defaultStartYear,
      endYear: defaultEndYear,
    };
  }

  let startYear = defaultStartYear;
  let endYear = defaultEndYear;
  if (keyTypeDate && keyTypeDate.range) {
    if (
      keyTypeDate.range.start < YEAR_LIMIT &&
      keyTypeDate.range.end < YEAR_LIMIT
    ) {
      startYear = keyTypeDate.range.start;
      endYear = keyTypeDate.range.end;
    } else {
      startYear = parseInt(
        getYearFromDate(getDateISOString(keyTypeDate.range.start)),
      );
      endYear = parseInt(
        getYearFromDate(getDateISOString(keyTypeDate.range.end)),
      );
    }
  }

  return {
    startYear,
    endYear,
  };
};

export const isThereCustomTimeRange = (rangeValue: IRangeValue) => {
  return !Object.values(rangeValue).some(value => isUndefined(value));
};

export const getLeaseTimeOptions = (
  leasesCriteria?: LeasesSearchCriteriaType,
) => {
  const customTimeRange =
    isThereCustomTimeRange(getCustomLeaseTimeRange(leasesCriteria, 'sign')) ||
    isThereCustomTimeRange(
      getCustomLeaseTimeRange(leasesCriteria, 'expiration'),
    ) ||
    isThereCustomTimeRange(
      getCustomLeaseTimeRange(leasesCriteria, 'targetCommencement'),
    );

  return (
    !!leasesCriteria?.expirationTime ||
    !!leasesCriteria?.signTime ||
    !!leasesCriteria?.targetCommencementTime ||
    customTimeRange
  );
};

export const getSortAndRecordFromLeasesCriteria = (
  leasesCriteria?: LeasesSearchCriteriaType,
) => {
  const customExpirationRange = getCustomLeaseTimeRange(
    leasesCriteria,
    'expiration',
  );
  if (
    leasesCriteria?.expirationTime ||
    isThereCustomTimeRange(customExpirationRange)
  ) {
    return {
      recordType: RECORD_TYPE_OPTIONS[1],
      sort: EXPIRING_LEASES_OPTION,
    };
  }

  const customSignRange = getCustomLeaseTimeRange(leasesCriteria, 'sign');
  if (leasesCriteria?.signTime || isThereCustomTimeRange(customSignRange)) {
    return {
      recordType: undefined,
      sort: RECENT_LEASES_OPTION,
    };
  }

  return {
    recordType: undefined,
    sort: undefined,
  };
};

export const buildLeasesCriteriaByTime = (
  fieldName: string,
  fieldValue: any,
  fieldFromName: string,
  fieldToName: string,
  leasesCriteria?: LeasesSearchCriteriaType,
) => {
  const newLeasesCriteria = {
    ...leasesCriteria,
    [fieldName]: fieldValue,
    [fieldFromName]: undefined,
    [fieldToName]: undefined,
  };
  return {
    ...newLeasesCriteria,
    ...getSortAndRecordFromLeasesCriteria(newLeasesCriteria),
  };
};

export const buildLeasesCriteriaByCustomTime = (
  rangeFieldName: string,
  rangeFieldValue: any,
  leasesCriteria?: LeasesSearchCriteriaType,
) => {
  const newLeasesCriteria = {
    ...leasesCriteria,
    [rangeFieldName]: rangeFieldValue || undefined,
  };
  return {
    ...newLeasesCriteria,
    ...getSortAndRecordFromLeasesCriteria(newLeasesCriteria),
  };
};

export const getMeasurementSystem = (data: any) => {
  if (data.properties.results) {
    const measurementSystems: any[] = [];
    data.properties.results.forEach(
      (property: { propertyCountry: ICountry }) => {
        if (property.propertyCountry.code) {
          measurementSystems.push(
            UNITS_OF_MEASUREMENT_CODE_MAPPING[property.propertyCountry.code],
          );
        }
      },
    );
    const uniqueMeasurementSystems = uniq(measurementSystems);
    if (
      uniqueMeasurementSystems.length === 1 &&
      uniqueMeasurementSystems[0] === CountryCodes.EU
    ) {
      return UnitOfMeasurement.sm;
    } else {
      return UnitOfMeasurement.sf;
    }
  } else {
    return UnitOfMeasurement.sf;
  }
};

export const getCountValue = (
  data: any,
  typeOfData: 'properties' | 'leases' | 'sales' | 'TIMs',
  isSelectAllChecked?: boolean,
  selectedIds?: number[],
  uncheckedIds?: number[],
) => {
  if (uncheckedIds?.length) {
    //If the users deselect all items by the header checkbox
    if (!isSelectAllChecked && !selectedIds?.length) {
      return 0;
    }

    if (!selectedIds?.length) {
      return 0;
    }

    //If users deselect all items by the header checkbox and starts to select item by item
    if (!isSelectAllChecked && selectedIds.length) {
      return selectedIds.length;
    } else {
      // when unchecked all and select each item one by one
      return data?.[typeOfData].total - uncheckedIds!.length;
    }
  } else {
    //Returns total
    return data?.[typeOfData]?.total;
  }
};

export const isIndustrialOrLifeScience = (
  filters: SearchCriteriaType | undefined,
) => {
  const industrialIds = [2, 3, 12];
  return filters?.customCriteria?.types?.some(f =>
    industrialIds.includes(f.id),
  );
};

export const isIndustrial = (filters: SearchCriteriaType | undefined) => {
  const industrialIds = [2, 3];
  return filters?.customCriteria?.types?.some(f =>
    industrialIds.includes(f.id),
  );
};
