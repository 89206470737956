import * as queryString from 'query-string';
import { isObject, set, isEmpty } from 'lodash';
import {
  CustomSearchCriteriaType,
  LeasesSearchCriteriaType,
  OpenSelectionSearchCriteriaType,
  SalesSearchCriteriaType,
} from 'components/FindComps/EditSearchCriteria/types';
import { ICoordinates } from 'interfaces/ICoordinates';
import { IdName } from 'interfaces/IdName';
import {
  BUILDING_SET_PREFIX,
  CUSTOM_CRITERIA_PREFIX,
  LEASES_PREFIX,
  OPEN_SELECTION_PREFIX,
  SALES_PREFIX,
  SHORT_VERSION_PREFIX,
} from './FindCompsSearchCriteriaService';
import { BuildingSetType } from '../components/ActivityFeed/types';

const MULTI_CHOICE_FIELDS = [
  'buildOutTypes',
  'dockConfiguration',
  'certificationTypes',
];

const ADVANCED_ATTRIBUTE_FIELDS = [
  'clearHeightFrom',
  'clearHeightTo',
  'loadingDocksFrom',
  'loadingDocksTo',
  'driveInDoorsFrom',
  'driveInDoorsTo',
  'trailerParkingSpacesFrom',
  'trailerParkingSpacesTo',
  'expandingContracting',
];

const CRANE_SPECIFIC_FIELDS = [
  'craneCapacityFrom',
  'craneCapacityTo',
  'hookHeightFrom',
  'hookHeightTo',
  'ampsFrom',
  'ampsTo',
];

const LEASE_FIELDS_DIRECT_VALUES = [
  'confidential',
  'upwardOnly',
  'multipleBasis',
  'leaseSizeFrom',
  'leaseSizeTo',
  'signTimeFrom',
  'signTimeTo',
  'leaseOptionStartDate',
  'leaseOptionEndDate',
  'expirationTimeFrom',
  'expirationTimeTo',
  'targetCommencementTimeFrom',
  'targetCommencementTimeTo',
  ...ADVANCED_ATTRIBUTE_FIELDS,
  ...CRANE_SPECIFIC_FIELDS,
];

const LEASE_RANGE_FIELDS = ['rentPriceRange', 'securityDeposit'];

const SALE_RANGE_FIELDS = [
  'pricesPerSFRange',
  'waultToExpiryRange',
  'waultToBreakDateRange',
  'totalRentRange',
];

const SALE_FIELDS_DIRECT_VALUES = [
  'saleDateFrom',
  'saleDateTo',
  'dealId',
  ...ADVANCED_ATTRIBUTE_FIELDS,
  ...CRANE_SPECIFIC_FIELDS,
];

const CUSTOM_FIELDS_DIRECT_VALUES = [
  'renovatedYearFrom',
  'renovatedYearTo',
  'builtYearFrom',
  'builtYearTo',
  'sizeFrom',
  'sizeTo',
  'includeInStats',
  'craneServed',
  'heavyPower',
  'newToMarket',
  'brokerContact',
  ...CRANE_SPECIFIC_FIELDS,
  ...ADVANCED_ATTRIBUTE_FIELDS,
];

const REQUIRED_NAME_FIELDS = [
  'owners',
  'businessPark',
  'tenants',
  'tenantBrokers',
  'landlordBrokers',
  'sellers',
  'sellersBrokers',
  'buyersBrokers',
  'buyers',
  'leasingCompanies',
  'cities',
  'developmentCompanies',
  'managementCompanies',
  'longLeaseHolders',
];

const NAMES_SUFFIX = 'NMs';

export class SearchParamsService {
  public static serializeObject(obj: any, prefix?: string): string {
    if (!obj) return '';

    const str = [];
    for (const objProperty in obj) {
      if (obj.hasOwnProperty(objProperty)) {
        const paramKey = prefix
          ? prefix + '[' + objProperty + ']'
          : objProperty;
        const paramValue = obj[objProperty];

        // must consider Zero values
        if (paramValue !== undefined && paramValue !== null) {
          str.push(
            isObject(paramValue)
              ? this.serializeObject(paramValue, paramKey)
              : paramKey + '=' + encodeURIComponent(paramValue),
          );
        }
      }
    }
    return str.join('&');
  }

  /**
   * Receive a custom criteria object and create a URL param like c[builtYears=1&markets=1&types=1]
   *
   * @param custom
   * @param requiredNameFields
   * @returns
   */
  public static parseCustomCriteriaObjectToURLParams(
    custom?: CustomSearchCriteriaType,
    requiredNameFields = REQUIRED_NAME_FIELDS,
  ): string {
    if (!custom) return '';
    const url: string[] = [];
    for (const [key, value] of Object.entries(custom)) {
      let param = '';
      if (value != undefined && value != null) {
        if (requiredNameFields.includes(key) && !isEmpty(value)) {
          const ids = value.map((e: IdName) => e.id);
          const names = value.map((e: IdName) => encodeURIComponent(e.name));
          param = `${key}=${ids.join(',')}&${key}${NAMES_SUFFIX}=${names.join(
            ',',
          )}`;
          url.push(param);
        } else if (CUSTOM_FIELDS_DIRECT_VALUES.includes(key)) {
          param = key + '=' + value;
          url.push(param);
        } else if (Array.isArray(value) && !isEmpty(value)) {
          const ids = value.map((e: { id: any }) => e.id).filter(Boolean);
          if (ids.length) {
            param = key + '=' + ids.join(',');
            url.push(param);
          }
        }
      }
    }

    if (!url.length) return '';

    return `${CUSTOM_CRITERIA_PREFIX}[${url.join('&')}]`;
  }

  public static parseMapBoxDetailsToUrlParams(
    mapBoxDetail?: ICoordinates,
    address?: string,
  ): string {
    return `?lat=${mapBoxDetail?.latitude!}&lng=${mapBoxDetail?.longitude!}&displayAddress=${address!}`;
  }

  public static parseOpenSelectionCriteriaToURLParams(
    openSelection?: OpenSelectionSearchCriteriaType,
    defaultCompSet?: boolean,
  ): string {
    if (!openSelection) return '';

    const url: string[] = [];

    let key: keyof OpenSelectionSearchCriteriaType;

    for (key in openSelection) {
      let param = '';
      const value = openSelection[key];
      if (Array.isArray(value)) {
        param = key + '=' + value;
        url.push(param);
      } else {
        param = key + '=' + value?.id;
        url.push(param);
      }
    }

    if (!url.length) return '';
    return defaultCompSet
      ? `${CUSTOM_CRITERIA_PREFIX}[${url.join('&')}]`
      : `${OPEN_SELECTION_PREFIX}[${url.join('&')}]`;
  }

  public static parseBuildingSetToURLParams(
    buildingSetType?: BuildingSetType,
  ): string {
    if (!buildingSetType) return '';

    const url: string[] = [];

    let key: keyof BuildingSetType;

    for (key in buildingSetType) {
      let param = '';
      const value = buildingSetType[key];
      param = key + '=' + value;
      if (Array.isArray(value)) {
        !isEmpty(value) && url.push(param);
      } else if (value != undefined && value != null) {
        url.push(param);
      }
    }

    if (!url.length) return '';

    return `${BUILDING_SET_PREFIX}[${url.join('&')}]`;
  }

  public static parseLeaseCriteriaToURLParams(
    leaseCriteria?: LeasesSearchCriteriaType,
  ): string {
    if (!leaseCriteria) return '';

    const url: string[] = [];

    for (const [key, value] of Object.entries(leaseCriteria)) {
      let param = '';
      if (value != undefined && value != null) {
        if (REQUIRED_NAME_FIELDS.includes(key) && !isEmpty(value)) {
          const ids = value.map((e: IdName) => e.id);
          const names = value.map((e: IdName) => e.name);
          param = `${key}=${ids.join(',')}&${key}${NAMES_SUFFIX}=${names.join(
            ',',
          )}`;
          url.push(param);
        } else if (key === 'industries') {
          const subItemsIds = value?.map((ind: { subItems: any[] }) =>
            ind?.subItems?.map((sub: { id: any }) => sub.id),
          );
          param = key + '=' + subItemsIds.join(',');
          url.push(param);
        } else if (MULTI_CHOICE_FIELDS.includes(key) && value.length) {
          const itemsIds = value?.map((item: { id: any }) => item.id);
          param = key + '=' + itemsIds.join(',');
          url.push(param);
        } else if (LEASE_FIELDS_DIRECT_VALUES.includes(key)) {
          param = key + '=' + value;
          url.push(param);
        } else if (LEASE_RANGE_FIELDS.includes(key)) {
          param = key + '=' + value.join(',');
          url.push(param);
        } else if (Array.isArray(value) && !isEmpty(value)) {
          const ids = value.map((e: { id: any }) => e.id);
          param = key + '=' + ids.join(',');
          url.push(param);
        } else if (key === 'tenantInMarketType') {
          param = key + '=' + value.join(',');
          if (value?.length) url.push(param);
        } else if (key === 'onlyNotExpired') {
          param = `${key}=${value}`;
          url.push(param);
        } else {
          param = !isEmpty(value) ? key + '=' + value?.id : '';
          if (param) {
            url.push(param);
          }
        }
      }
    }

    if (!url.length) return '';

    return `${LEASES_PREFIX}[${url.join('&')}]`;
  }

  public static parseSaleCriteriaToURLParams(
    saleCriteria?: SalesSearchCriteriaType,
  ): string {
    if (!saleCriteria) return '';

    const url: string[] = [];

    for (const [key, value] of Object.entries(saleCriteria)) {
      let param = '';

      if (value != undefined && value != null) {
        if (REQUIRED_NAME_FIELDS.includes(key) && !isEmpty(value)) {
          const ids = value.map((e: IdName) => e.id);
          const names = value.map((e: IdName) => e.name);
          param = `${key}=${ids.join(',')}&${key}${NAMES_SUFFIX}=${names.join(
            ',',
          )}`;
          url.push(param);
        } else if (SALE_FIELDS_DIRECT_VALUES.includes(key)) {
          param = key + '=' + value;
          url.push(param);
        } else if (SALE_RANGE_FIELDS.includes(key)) {
          param = key + '=' + value.join(',');
          url.push(param);
        } else if (Array.isArray(value) && value.length) {
          const ids = value.map((e: { id: any }) => e.id);
          param = key + '=' + ids.join(',');
          url.push(param);
        } else if (value?.id) {
          param = key + '=' + value?.id;
          url.push(param);
        }
      }
    }

    if (!url.length) return '';

    return `${SALES_PREFIX}[${url.join('&')}]`;
  }

  public static parseURLParamsIntoCustomCriteriaObject(
    url?: string,
    requiredNameFields = REQUIRED_NAME_FIELDS,
  ): CustomSearchCriteriaType {
    if (!url) return {};

    const str = url.replace(`${CUSTOM_CRITERIA_PREFIX}[`, '').replace(']', '');
    const obj = queryString.parse(str, {
      arrayFormat: 'comma',
      parseNumbers: true,
      parseBooleans: true,
      decode: false,
    });

    const custom: CustomSearchCriteriaType = {};
    Object.keys(obj).forEach(key => {
      if (key.includes(NAMES_SUFFIX)) {
        return;
      }
      const val = obj[key];
      if (requiredNameFields.includes(key)) {
        const nameValues = obj[`${key}${NAMES_SUFFIX}`] as any;
        const isNameValuesArray = Array.isArray(nameValues);
        const arrayNameValues = isNameValuesArray
          ? nameValues.map((name: string) => decodeURIComponent(name))
          : nameValues;
        custom[key] = isNameValuesArray
          ? (val as any).map((el: any, index: number) => ({
              id: el,
              name: arrayNameValues?.[index],
            }))
          : [
              {
                id: val,
                name: decodeURIComponent(nameValues),
              },
            ];
      } else if (Array.isArray(val)) {
        custom[key] = val.map(el => ({ id: el }));
      } else if (CUSTOM_FIELDS_DIRECT_VALUES.includes(key)) {
        custom[key] = val as any;
      } else {
        custom[key] = [{ id: val }];
      }
    });

    return custom;
  }

  public static parseURLParamsIntoOpenSelectionCriteriaObject(
    url?: string,
  ): OpenSelectionSearchCriteriaType {
    if (!url) return {};

    const str = url.replace(`${OPEN_SELECTION_PREFIX}[`, '').replace(']', '');
    const obj = queryString.parse(str, {
      arrayFormat: 'comma',
      parseNumbers: true,
    });

    const openSelection: OpenSelectionSearchCriteriaType = { ...obj };
    let key: keyof OpenSelectionSearchCriteriaType;
    for (key in openSelection) {
      const value = obj[key];
      if (key === 'unsavedList') {
        if (Array.isArray(value)) {
          openSelection[key] = value as number[];
        } else {
          if (typeof value === 'string' && value?.includes(',')) {
            openSelection[key] = value.split(',').map(Number);
          }
          openSelection[key] = [value as number];
        }
      } else if (key === 'listSearchPropertiesIds') {
        let properties = undefined;
        if (Array.isArray(value)) {
          properties = value as number[];
        } else if (typeof value === 'string' && value?.includes(',')) {
          properties = value.split(',').map(Number);
        } else {
          properties = [value];
        }

        if (properties) {
          openSelection.listSearch = {
            ...openSelection.listSearch,
            properties: properties as number[],
          };
          openSelection[key] = properties as number[];
        }
      } else {
        openSelection[key] = { id: Number(value) };
      }
    }

    return openSelection;
  }

  public static parseURLParamsIntoLeaseCriteriaObject(
    url?: string,
  ): LeasesSearchCriteriaType {
    if (!url) return {};

    const str = url.replace(`${LEASES_PREFIX}[`, '').replace(']', '');
    const obj = queryString.parse(str, {
      arrayFormat: 'comma',
      parseNumbers: true,
      parseBooleans: true,
    });

    const leaseCriteria: LeasesSearchCriteriaType = {};
    Object.keys(obj).forEach(key => {
      if (key.includes(NAMES_SUFFIX)) {
        return;
      }
      const val = obj[key];
      if (REQUIRED_NAME_FIELDS.includes(key)) {
        const nameValues = obj[`${key}${NAMES_SUFFIX}`] as any;
        const isNameValuesArray = Array.isArray(nameValues);
        const arrayNameValues = isNameValuesArray
          ? nameValues.map((name: string) => name)
          : nameValues;
        leaseCriteria[key] = isNameValuesArray
          ? (val as any).map((el: any, index: number) => ({
              id: el,
              name: arrayNameValues?.[index],
            }))
          : [{ id: val, name: nameValues }];
      } else if (key === 'industries') {
        const subItems = Array.isArray(val)
          ? (val as any)?.map((el: any) => ({ id: el }))
          : [{ id: val }];
        leaseCriteria[key] = [{ subItems }] as any;
      } else if (
        [...LEASE_FIELDS_DIRECT_VALUES, ...LEASE_RANGE_FIELDS].includes(key)
      ) {
        leaseCriteria[key] = val as any;
      } else if (Array.isArray(val)) {
        leaseCriteria[key] = val.map(el => ({ id: el }));
      } else if (
        [
          'financialStatus',
          'recordType',
          'sort',
          'signTime',
          'expirationTime',
        ].includes(key)
      ) {
        leaseCriteria[key] = { id: val };
      } else if (key === 'onlyNotExpired') {
        leaseCriteria[key] = !!val;
      } else {
        leaseCriteria[key] = [{ id: val }];
      }
    });

    return leaseCriteria;
  }

  public static parseURLParamsIntoSaleCriteriaObject(
    url?: string,
  ): SalesSearchCriteriaType {
    if (!url) return {};

    const str = url.replace(`${SALES_PREFIX}[`, '').replace(']', '');
    const obj = queryString.parse(str, {
      arrayFormat: 'comma',
      parseNumbers: true,
    });

    const saleCriteria: SalesSearchCriteriaType = {};
    Object.keys(obj).forEach(key => {
      if (key.includes(NAMES_SUFFIX)) {
        return;
      }
      const val = obj[key];
      if (REQUIRED_NAME_FIELDS.includes(key)) {
        const nameValues = obj[`${key}${NAMES_SUFFIX}`] as any;
        const isNameValuesArray = Array.isArray(nameValues);
        const arrayNameValues = isNameValuesArray
          ? nameValues.map((name: string) => name)
          : nameValues;
        saleCriteria[key] = isNameValuesArray
          ? (val as any).map((el: any, index: number) => ({
              id: el,
              name: arrayNameValues?.[index],
            }))
          : [{ id: val, name: nameValues }];
      } else if (
        [...SALE_FIELDS_DIRECT_VALUES, ...SALE_RANGE_FIELDS].includes(key)
      ) {
        saleCriteria[key] = val as any;
      } else if (Array.isArray(val)) {
        saleCriteria[key] = val.map(el => ({ id: el }));
      } else if (['date', 'partOfPortfolio'].includes(key)) {
        saleCriteria[key] = { id: val };
      } else {
        saleCriteria[key] = [{ id: val }];
      }
    });

    return saleCriteria;
  }

  public static parseURLParamsIntoBuildingSetObject(
    url?: string,
  ): BuildingSetType {
    if (!url) return {};

    const str = url.replace(`${BUILDING_SET_PREFIX}[`, '').replace(']', '');
    const obj = queryString.parse(str, {
      arrayFormat: 'comma',
      parseNumbers: true,
      parseBooleans: true,
    });

    return obj as BuildingSetType;
  }

  private static parseURLFullVersion(urlParams: string) {
    const params = queryString.parse(urlParams, {
      arrayFormat: 'bracket',
      parseNumbers: true,
    });

    const filtersObject: { [key: string]: {} } = {};
    Object.keys(params).forEach(key => {
      if (key.includes(NAMES_SUFFIX)) {
        return;
      }
      if (
        params[key] !== null &&
        params[key] !== undefined &&
        params[key] !== 'undefined'
      ) {
        set(filtersObject, key, params[key]);
      }
    });

    return filtersObject;
  }

  private static parseURLShortVersion(urlParams: string) {
    //removing the short prefix
    const encodedURL = urlParams.replace(SHORT_VERSION_PREFIX, '');
    const url = decodeURIComponent(encodedURL);

    //extracting each criteria param
    const customCriteriaParam = url.match(/c\[(.*?)\]/)?.[0] || '';
    const openSelectionCriteriaParam = url.match(/o\[(.*?)\]/)?.[0] || '';
    const leasesCriteriaParam = url.match(/l\[(.*?)\]/)?.[0] || '';
    const salesCriteriaParam = url.match(/s\[(.*?)\]/)?.[0] || '';
    const timsCriteriaParam = url.match(/t\[(.*?)\]/)?.[0] || '';

    //parsing each criteria object
    const customCriteria = this.parseURLParamsIntoCustomCriteriaObject(
      customCriteriaParam,
    );
    const openSelectionCriteria = this.parseURLParamsIntoOpenSelectionCriteriaObject(
      openSelectionCriteriaParam,
    );
    const leasesCriteria = this.parseURLParamsIntoLeaseCriteriaObject(
      leasesCriteriaParam,
    );

    const salesCriteria = this.parseURLParamsIntoSaleCriteriaObject(
      salesCriteriaParam,
    );

    const timsCriteria = this.parseURLParamsIntoLeaseCriteriaObject(
      timsCriteriaParam,
    );

    return {
      c: customCriteria,
      o: openSelectionCriteria,
      l: leasesCriteria,
      s: salesCriteria,
      t: timsCriteria,
    };
  }

  public static parseUrlParamsToObject(urlParams: string | null): any {
    if (!urlParams) return {};

    try {
      if (urlParams.includes(SHORT_VERSION_PREFIX)) {
        return this.parseURLShortVersion(urlParams);
      }

      return this.parseURLFullVersion(urlParams);
    } catch (e) {
      return {};
    }
  }
}
