import React from 'react';
import { Column } from 'react-table';

import { PropertyTypes } from 'constants/propertyTypes';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { I18N_USER_SETTINGS_LABEL_PATH } from 'constants/i18n';
import LoadingMessage from 'components/LoadingMessage';
import { SimpleTable } from 'components/SimpleTable';
import FormFooter from 'components/FormFooter';
import { useFocusedCell } from 'components/SimpleTable/useFocusedCell';
import { IAssetPerformance } from 'interfaces/IAssetPerformance';
import { formatNumberWithCommas } from 'utils/formatters/number';
import { getCurrencySymbol } from 'utils/formatters/currency';
import { translateText } from 'utils/i18n';

import { getTableColumns } from './tableData';
import { useInputHandlers } from './useInputHandlers';
import { useAvailabilityData } from './useAvailabilityData';
import { AvailabilityVacancyIds, SegmentPricingParams } from '../interfaces';
import styles from '../HistoricalPerformanceModal.module.scss';
import { useExtraRows } from './useExtraRows';

interface Props {
  propertyId: number;
  propertyType: PropertyTypes;
  segmentPricingParams: SegmentPricingParams | undefined;
  onClose: () => void;
}
export const AvailabilityVacancy = ({
  propertyId,
  propertyType,
  segmentPricingParams,
  onClose,
}: Props) => {
  const unitOfMeasurement = segmentPricingParams?.unitOfMeasurement as UnitOfMeasurement;
  const currencySymbol = getCurrencySymbol(segmentPricingParams?.currencyCode);
  const isDataCenterType = propertyType === PropertyTypes.dataCenter;

  const { variables, loadings, handlers } = useAvailabilityData({
    propertyId,
    isDataCenterType,
    onClose,
  });
  const { setFocusedCell, defineIsFocusedCell, onCellFocus } = useFocusedCell();

  const extraRows = useExtraRows({
    localData: variables.data,
    setLocalData: handlers.setData,
    isDataCenterType,
    setChangedDataRowsIndex: handlers.setChangedRowsIndex,
  });

  const onChangeData = (
    rowIndex: number,
    columnId: string,
    value: string | number | null,
  ) => {
    handlers.setData(prevData =>
      prevData.map((row, index) =>
        index === rowIndex ? { ...row, [columnId]: value } : row,
      ),
    );
    setFocusedCell({ row: rowIndex, column: columnId });
  };

  const { handleDateChange, handleInputChange } = useInputHandlers(
    onChangeData,
  );

  const handleChangedRows = (
    rowIndex: number,
    columnId: keyof IAssetPerformance,
    newValue: string | number | null,
  ) => {
    const prevValue = variables.data[rowIndex][columnId];
    const idx = variables.changedRowsIndex.findIndex(i => i === rowIndex);
    // in case when new value is diff and row index does not exist in changedRowsIndex
    if (newValue !== prevValue && idx === -1) {
      handlers.setChangedRowsIndex(prev => [...prev, rowIndex]);
    }
  };

  const handleTableDataChange = (
    rowIndex: number,
    columnId: keyof IAssetPerformance,
    newValue: string | number | null,
    isDate: boolean,
  ) => {
    isDate
      ? handleDateChange(rowIndex, columnId, newValue)
      : handleInputChange(rowIndex, columnId, newValue);
    handleChangedRows(rowIndex, columnId, newValue);
  };

  const onInputBlur = (
    rowIndex: number,
    value: string | number | null,
    columnId: AvailabilityVacancyIds,
  ) => {
    const NON_FORMATTED_FIELDS = [
      AvailabilityVacancyIds.rentType,
      AvailabilityVacancyIds.timeMeasurement,
      AvailabilityVacancyIds.notes,
    ];

    if (NON_FORMATTED_FIELDS.includes(columnId)) {
      handleTableDataChange(rowIndex, columnId, value, false);
    } else {
      handleTableDataChange(
        rowIndex,
        columnId,
        formatNumberWithCommas(value),
        false,
      );
    }
    setFocusedCell(null);
  };

  const columns = getTableColumns(
    handleTableDataChange,
    onInputBlur,
    defineIsFocusedCell,
    onCellFocus,
    unitOfMeasurement,
    isDataCenterType,
    currencySymbol,
  );

  if (loadings.loadingAssetPerformance) return <LoadingMessage />;

  return (
    <div className={styles['container']}>
      <SimpleTable
        data={variables.data}
        columns={columns as Column<Partial<IAssetPerformance>>[]}
        extraRows={extraRows}
      />
      <FormFooter
        onSubmit={handlers.saveAvailabilityVacancy}
        isSubmitDisabled={variables.isDisabledSubmit}
        onCancel={onClose}
        submitButtonLabel={translateText(
          `${I18N_USER_SETTINGS_LABEL_PATH}.saveChanges`,
        )}
      />
    </div>
  );
};
