import React from 'react';
import { Column } from 'react-table';

import { IMarketPricing } from 'interfaces/IMarketPricing';
import DateInput from 'components/Inputs/DateInput';
import { ICustomValidation } from 'components/Inputs/DateInput/constants';
import { I18N_HISTORICAL_PERFORMANCE_LABEL_PATH } from 'components/HistoricalPerformance/constants';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { translateText } from 'utils/i18n';

import styles from '../HistoricalPerformanceModal.module.scss';
import { SegmentPricingIds } from '../interfaces';

export const getTableColumns = (
  handleTableDataChange: (
    rowIndex: number,
    columnId: string,
    value: string | number | null,
    isDate: boolean,
  ) => void,
  onInputBlur: (
    rowIndex: number,
    value: string | number | null,
    columnId: string,
  ) => void,
  defineIsFocusedCell: (rowIndex: number, columnId: string) => boolean,
  setFocusedCell: (rowIndex: number, columnId: string) => void,
  validateDuplicateQuarters: (
    rowId: string,
    id: number,
    value: string | null,
  ) => void,
  dateValidations: Record<number, ICustomValidation>,
): Column<IMarketPricing>[] => [
  {
    Header: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.year`),
    accessor: SegmentPricingIds.date,
    Cell: ({ row, cell }) => (
      <div className={styles['cell-content']}>
        <DateInput
          id="segment-pricing-date"
          disableFocusShift
          customValidation={dateValidations[+row.id]}
          wrapperClassName={styles['date-input']}
          errorMessageClass={styles['date-input-error-message']}
          value={row.original.date}
          onBlur={value => {
            validateDuplicateQuarters(row.id, row.original.id, value);
            handleTableDataChange(row.index, cell.column.id, value, true);
          }}
        />
      </div>
    ),
  },
  {
    Header: translateText(
      `${I18N_HISTORICAL_PERFORMANCE_LABEL_PATH}.directRent`,
    ),
    accessor: SegmentPricingIds.directRent,
    Cell: ({ row, cell, value }) => (
      <div className={styles['cell-content']}>
        <span>$</span>
        <input
          className={styles['cell-input']}
          onFocus={() => setFocusedCell(row.index, cell.column.id)}
          autoFocus={defineIsFocusedCell(row.index, cell.column.id)}
          value={value}
          onBlur={e => {
            onInputBlur(row.index, e.target.value, 'directRent');
          }}
          onChange={e =>
            handleTableDataChange(
              row.index,
              'directRent',
              e.target.value,
              false,
            )
          }
        />
      </div>
    ),
  },
  {
    Header: translateText(
      `${I18N_HISTORICAL_PERFORMANCE_LABEL_PATH}.directRentChange`,
    ),
    accessor: SegmentPricingIds.darPercent,
    Cell: ({ value }) => (
      <div className={styles['cell-content']}>
        <span>{value}</span>
        <span>%</span>
      </div>
    ),
  },
  {
    Header: translateText(`${I18N_HISTORICAL_PERFORMANCE_LABEL_PATH}.ti10Year`),
    accessor: SegmentPricingIds.tiLongTerm,
    Cell: ({ row, cell, value }) => (
      <div className={styles['cell-content']}>
        <input
          className={styles['cell-input']}
          onFocus={() => setFocusedCell(row.index, cell.column.id)}
          autoFocus={defineIsFocusedCell(row.index, cell.column.id)}
          value={value}
          onBlur={e => {
            onInputBlur(row.index, e.target.value, 'tiLongTerm');
          }}
          onChange={e =>
            handleTableDataChange(
              row.index,
              'tiLongTerm',
              e.target.value,
              false,
            )
          }
        />
      </div>
    ),
  },
  {
    Header: translateText(`${I18N_HISTORICAL_PERFORMANCE_LABEL_PATH}.tiChange`),
    accessor: SegmentPricingIds.tiPercent,
    Cell: ({ value }) => (
      <div className={styles['cell-content']}>
        <span>{value}</span>
        <span>%</span>
      </div>
    ),
  },
  {
    Header: translateText(
      `${I18N_HISTORICAL_PERFORMANCE_LABEL_PATH}.freeRentMonths`,
    ),
    accessor: SegmentPricingIds.freeRentLongTerm,
    Cell: ({ row, cell, value }) => (
      <div className={styles['cell-content']}>
        <input
          className={styles['cell-input']}
          onFocus={() => setFocusedCell(row.index, cell.column.id)}
          autoFocus={defineIsFocusedCell(row.index, cell.column.id)}
          onBlur={e => {
            onInputBlur(row.index, e.target.value, 'freeRentLongTerm');
          }}
          value={value}
          onChange={e =>
            handleTableDataChange(
              row.index,
              'freeRentLongTerm',
              e.target.value,
              false,
            )
          }
        />
      </div>
    ),
  },
];
