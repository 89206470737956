import React from 'react';

import { IAssetPerformance } from 'interfaces/IAssetPerformance';
import { LeaseRentType } from 'constants/leases';
import { TimeMeasurement } from 'constants/timeMeasurement';
import { getCurrentYear, getYearFromDate } from 'utils/date';

import styles from '../HistoricalPerformanceModal.module.scss';

interface Props {
  localData: Partial<IAssetPerformance>[];
  setLocalData: React.Dispatch<
    React.SetStateAction<Partial<IAssetPerformance>[]>
  >;
  isDataCenterType: boolean;
  setChangedDataRowsIndex: React.Dispatch<React.SetStateAction<number[]>>;
}
export const useExtraRows = ({
  localData,
  setLocalData,
  isDataCenterType,
  setChangedDataRowsIndex,
}: Props) => {
  const currentYear = getCurrentYear();
  const lastYear = +getYearFromDate(localData[localData?.length - 1]?.date);
  const yearPlaceholder = localData?.length ? lastYear - 1 : currentYear;

  const GENERAL_FIELDS = {
    id: null,
    date: `${yearPlaceholder}-12-31`,
    notes: null,
  };

  const DEFAULT_PLACEHOLDER = {
    availableSfDirect: 0,
    vacantSfDirect: 0,
    availableSfSublet: 0,
    vacantSfSublet: 0,
    baseRent: 0,
    rentType: LeaseRentType.FS,
    timeMeasurement: TimeMeasurement.ANNUAL,
  };

  const DATA_CENTER_PLACEHOLDER = {
    availableSfShell: 0,
    vacantSfShell: 0,
    availableKwTurnkey: 0,
    vacantKwTurnkey: 0,
  };

  const placeholder = isDataCenterType
    ? { ...GENERAL_FIELDS, ...DATA_CENTER_PLACEHOLDER }
    : { ...GENERAL_FIELDS, ...DEFAULT_PLACEHOLDER };

  const onExtraRowClick = () => {
    setLocalData(prev => [...prev, placeholder] as IAssetPerformance[]);
    setChangedDataRowsIndex(prev => [...prev, localData?.length]);
  };

  return {
    rows: [placeholder] as IAssetPerformance[],
    onExtraRowClick,
    extraRowClassName: styles['extra-row'],
    extraCelLClassName: styles['extra-cell'],
  };
};
