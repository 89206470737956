import { TabType } from 'components/Tabs';
import { TimeMeasurement } from 'constants/timeMeasurement';
import { IdName } from 'interfaces/IdName';
import { MarketPricingRentType } from 'interfaces/inputs/IMarketPricingInput';

export type ConfirmUnsavedChanges = {
  hasChanges: boolean;
  isConfirmModalVisible: boolean;
  onConfirm?: (tab?: TabType) => void;
  shouldResetAvailability: boolean;
  shouldResetSegment: boolean;
};

export type SegmentPricingParams = {
  currencyCode: string;
  propertyId: number;
  market: IdName | null;
  propertyClass: IdName | null;
  propertySubtype: IdName | null;
  propertyType: IdName | null;
  submarket: IdName | null;
  unitOfMeasurement: string;
  rentType: MarketPricingRentType;
  timeMeasurement: TimeMeasurement;
};

export enum SegmentPricingIds {
  date = 'date',
  directRent = 'directRent',
  darPercent = 'darPercent',
  tiLongTerm = 'tiLongTerm',
  tiPercent = 'tiPercent',
  freeRentLongTerm = 'freeRentLongTerm',
}

export enum AvailabilityVacancyIds {
  date = 'date',
  availableSfShell = 'availableSfShell',
  vacantSfShell = 'vacantSfShell',
  availableKwTurnkey = 'availableKwTurnkey',
  vacantKwTurnkey = 'vacantKwTurnkey',
  availableSfDirect = 'availableSfDirect',
  vacantSfDirect = 'vacantSfDirect',
  availableSfSublet = 'availableSfSublet',
  vacantSfSublet = 'vacantSfSublet',
  baseRent = 'baseRent',
  rentType = 'rentType',
  notes = 'notes',
  timeMeasurement = 'timeMeasurement',
}
