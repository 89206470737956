import React from 'react';

import FormControl from 'components/FormControl';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import CheckboxGroupInput from 'components/Inputs/CheckboxGroupInput';
import {
  BREEAM_CERTIFICATION_TYPES,
  ENERGY_PERFORMANCE_TYPES,
  LEED_CERTIFICATION_TYPES,
  ThirdPartyId,
} from 'constants/certificationTypes';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { IdName } from 'interfaces/IdName';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import { translateText } from 'utils/i18n';

interface Props {
  wrapperClassName: string;
  property: IPropertyInput;
  itemWrapperClassName: string;
  isUkProperty: boolean;
  onChange: (fieldName: string, fieldValue: unknown) => void;
}

export const CertificationTypes = ({
  wrapperClassName,
  property,
  itemWrapperClassName,
  isUkProperty,
  onChange,
}: Props) => {
  const certificationObjects = property?.certificationObjects ?? [];

  const getValidCertification = (
    category: IdName[],
    isMultiSelect: boolean,
  ) => {
    const filtered = category.filter(({ id }) =>
      certificationObjects.some(
        ({ certificationType }) =>
          certificationType?.id === id && certificationType?.name,
      ),
    );
    return isMultiSelect ? filtered : filtered[0] ?? undefined;
  };

  const categoryMapper = {
    [ThirdPartyId.LEED]: (category: IdName[]) =>
      getValidCertification(category, false),
    [ThirdPartyId.BREEAM]: (category: IdName[]) =>
      getValidCertification(category, false),
    [ThirdPartyId.ENERGY]: (category: IdName[]) =>
      getValidCertification(category, true),
  };

  const getSelectedItem = (category: IdName[], thirdPartyId: ThirdPartyId) =>
    categoryMapper[thirdPartyId]?.(category);

  const onSingleChange = (
    option: IdName | undefined,
    category: IdName[],
    thirdPartyId: ThirdPartyId,
  ) => {
    const updatedCertifications = certificationObjects.map(cert =>
      category.some(cat => cat.id === cert.certificationType?.id)
        ? {
            ...cert,
            certificationType: {
              ...cert.certificationType,
              name: option ? option.name : null,
            },
          }
        : cert,
    );

    if (option) {
      updatedCertifications.push({
        objectTypeId: 1,
        objectId: property.id!,
        certificationType: {
          id: option.id,
          name: option.name,
          thirdPartyTypeId: thirdPartyId,
        },
      });
    }

    onChange('certificationObjects', updatedCertifications);
  };

  const onMultiChange = (
    options: IdName[],
    category: IdName[],
    thirdPartyId: ThirdPartyId,
  ) => {
    const selectedIds = new Set(options.map(opt => opt.id));

    const updatedCertifications = certificationObjects.map(cert =>
      category.some(cat => cat.id === cert.certificationType?.id)
        ? {
            ...cert,
            certificationType: {
              ...cert.certificationType,
              name: selectedIds.has(cert.certificationType.id)
                ? options.find(opt => opt.id === cert.certificationType.id)
                    ?.name ?? null
                : null,
            },
          }
        : cert,
    );

    options.forEach(opt => {
      if (
        !updatedCertifications.some(
          cert => cert.certificationType.id === opt.id,
        )
      ) {
        updatedCertifications.push({
          objectTypeId: 1,
          objectId: property.id!,
          certificationType: {
            id: opt.id,
            name: opt.name,
            thirdPartyTypeId: thirdPartyId,
          },
        });
      }
    });

    onChange('certificationObjects', updatedCertifications);
  };

  const handleCertificationChange = (
    option: IdName | IdName[] | undefined,
    category: IdName[],
    thirdPartyId: ThirdPartyId,
  ) =>
    thirdPartyId === ThirdPartyId.ENERGY && Array.isArray(option)
      ? onMultiChange(option, category, thirdPartyId)
      : onSingleChange(option as IdName, category, thirdPartyId);

  return (
    <>
      <FormControl
        label={translateText(
          `${I18N_PLATFORM_COMMON_WORD_PATH}.leedCertification`,
        )}
        wrapperClassName={wrapperClassName}
      >
        <ChoiceInput
          itemWrapperClassName={itemWrapperClassName}
          data={LEED_CERTIFICATION_TYPES}
          selectedItem={getSelectedItem(
            LEED_CERTIFICATION_TYPES,
            ThirdPartyId.LEED,
          )}
          onChange={option =>
            handleCertificationChange(
              option,
              LEED_CERTIFICATION_TYPES,
              ThirdPartyId.LEED,
            )
          }
        />
      </FormControl>
      <FormControl
        label={translateText(
          `${I18N_PLATFORM_COMMON_WORD_PATH}.breeamCertification`,
        )}
        wrapperClassName={wrapperClassName}
      >
        <ChoiceInput
          data={BREEAM_CERTIFICATION_TYPES}
          selectedItem={getSelectedItem(
            BREEAM_CERTIFICATION_TYPES,
            ThirdPartyId.BREEAM,
          )}
          itemWrapperClassName={itemWrapperClassName}
          onChange={option =>
            handleCertificationChange(
              option,
              BREEAM_CERTIFICATION_TYPES,
              ThirdPartyId.BREEAM,
            )
          }
        />
      </FormControl>

      {isUkProperty && (
        <FormControl
          label={translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.energyPerformanceRating`,
          )}
          wrapperClassName={wrapperClassName}
        >
          <CheckboxGroupInput
            data={ENERGY_PERFORMANCE_TYPES}
            onChange={(selectedItems: IdName[]) => {
              handleCertificationChange(
                selectedItems,
                ENERGY_PERFORMANCE_TYPES,
                ThirdPartyId.ENERGY,
              );
            }}
            selectedItems={
              getSelectedItem(
                ENERGY_PERFORMANCE_TYPES,
                ThirdPartyId.ENERGY,
              ) as IdName[]
            }
            size="regular"
            itemWrapperClassName={itemWrapperClassName}
            hideSelectAndDeselect
          />
        </FormControl>
      )}
    </>
  );
};
