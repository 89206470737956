import React from 'react';
import FormControl from 'components/FormControl';
import CurrencyInput from 'components/Inputs/CurrencyInput';
import parentStyles from 'components/CreateComps/FormSection/FormSection.module.scss';
import { getInputsData } from 'components/CreateComps/FormSection/LeaseForm/LeaseTerms/NetEffectiveRentField/utils';

interface Props {
  leaseCurrencySymbol: string;
  requiredFields: any;
  updateLeaseProperty: (field: string, value: any) => void;
  isCustom?: boolean;
}

const NetEffectiveRentField: React.FC<Props> = ({
  requiredFields,
  updateLeaseProperty,
  leaseCurrencySymbol,
  isCustom = false,
}) => {
  const {
    id,
    label,
    value,
    isDisabled,
    isLabelDisabled,
    placeholder,
    onChange,
    formatter,
  } = getInputsData(
    updateLeaseProperty,
    requiredFields.netEffectiveRentCustom,
    requiredFields.isMonthly
      ? requiredFields?.netEffectiveRentMonthly
      : requiredFields?.netEffectiveRent,
    isCustom,
  );

  return (
    <>
      <FormControl
        key={id}
        label={label}
        disabled={isLabelDisabled}
        wrapperClassName={parentStyles['form-row']}
      >
        <CurrencyInput
          id={id}
          value={value}
          onChange={onChange}
          iconPosition={'left'}
          isDisabled={isDisabled}
          formatter={formatter}
          placeholder={placeholder}
          currencySymbol={leaseCurrencySymbol}
        />
      </FormControl>
    </>
  );
};

export default NetEffectiveRentField;
