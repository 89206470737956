import { gql } from '@apollo/client';

export const HISTORICAL_PERFORMANCE_BY_YEAR = gql`
  query graphAssetPerformanceTrend(
    $propertyId: Int
    $companyId: Int
    $propertyIds: [Int]
    $marketIds: [Int]
    $propertySetId: Int
    $period: YearRangeInput
  ) {
    graphAssetPerformanceTrend(
      propertyId: $propertyId
      companyId: $companyId
      marketIds: $marketIds
      propertyIds: $propertyIds
      propertySetId: $propertySetId
      period: $period
    ) {
      year
      vacantSFDirect
      vacantSFSublet
      availableSFDirect
      availableSFSublet
      propertyAvgRentValue
      compSetAvgRentValue
      occupiedSF
      compSetOccupiedSf
      compSetOccupiedAvgSf
      compSetOccupiedPercentage
      compSetVacantAvgSf
      compSetVacantPercentage
      compSetAvailableAvgSf
      compSetAvailablePercentage
      compSetVacantSf
      compSetAvailableSf
      compSetVacantAvgSm
      compSetAvailableAvgSm
      availableBuildingPercentage
      availableDirectPercentage
      availableSubletPercentage
      vacantBuildingPercentage
      vacantDirectPercentage
      vacantSubletPercentage
      occupiedPercentage
      totalCompSetBuildingSize
      totalBuildingSize
      totalBuildingSizeAvailable
      totalBuildingSizeExisting
    }
  }
`;

export const ASSET_PERFORMANCE_PROPERTY = gql`
  query graphAssetPerformanceCurrentProperty(
    $propertyId: Int!
    $propertySetId: Int
  ) {
    graphAssetPerformanceCurrentProperty(
      propertyId: $propertyId
      propertySetId: $propertySetId
    ) {
      compSetTotalVacantSFDirect
      compSetTotalVacantSFSublet
      compSetTotalAvailableSFDirect
      compSetTotalAvailableSFSublet
      compSetTotalOccupiedSF
      compSetTotalOccupiedSM
      compSetTotalVacantSMDirect
      compSetTotalVacantSMSublet
      compSetTotalAvailableSMDirect
      compSetTotalAvailableSMSublet
      compSetTotalBuildingSize
      compSetVacantPercent
      compSetOccupiedPercent
      compSetAvailablePercent
      propertyTotalVacantSFDirect
      propertyTotalVacantSFSublet
      propertyTotalAvailableSFDirect
      propertyTotalAvailableSFSublet
      propertyTotalOccupiedSF
      propertyTotalOccupiedSM
      propertyTotalVacantSMDirect
      propertyTotalVacantSMSublet
      propertyTotalAvailableSMDirect
      propertyTotalAvailableSMSublet
      propertyVacantPercent
      propertyAvailablePercent
      propertyOccupiedPercent
    }
  }
`;

export const ASSET_PERFORMANCE_COMPANY = gql`
  query graphAssetPerformanceCurrentCompany(
    $companyId: Int!
    $marketIds: [Int]
  ) {
    graphAssetPerformanceCurrentCompany(
      companyId: $companyId
      marketIds: $marketIds
    ) {
      totalPortifolioSize
      totalVacantSFDirect
      totalVacantSFSublet
      totalAvailableSFDirect
      totalAvailableSFSublet
      vacantPercent
      availablePercent
      totalOccupiedSF
      occupiedPercent
      totalBuildingSizeAvailable
      totalBuildingSizeExisting
    }
  }
`;

export const BUILDING_HISTORICAL_RENT = gql`
  query buildingHistoricalRent($propertyId: Int!, $propertySetId: Int) {
    buildingHistoricalRent(
      propertyId: $propertyId
      propertySetId: $propertySetId
    ) {
      rentTypeFS {
        year
        buildingValue
        buildingValueMonthly
        buildingTI
        buildingTIMonthly
        buildingFreeRentMonths
        compSetValue
        compSetValueMonthly
        compSetTI
        compSetTIMonthly
        compSetFreeRentMonths
        timeMeasurement
      }
      rentTypeNNN {
        year
        buildingValue
        buildingValueMonthly
        buildingTI
        buildingTIMonthly
        buildingFreeRentMonths
        compSetValue
        compSetValueMonthly
        compSetTI
        compSetTIMonthly
        compSetFreeRentMonths
        timeMeasurement
      }
    }
  }
`;

export const COMPANY_HISTORICAL_RENT = gql`
  query companyHistoricalRent($companyId: Int!) {
    graphCompanyHistoricalRent(companyId: $companyId) {
      rentTypeFS {
        year
        rentAvg
        rentAvgMonthly
        compSetTI
        compSetTIMonthly
        compSetFreeRentMonths
        timeMeasurement
      }
      rentTypeNNN {
        year
        rentAvg
        rentAvgMonthly
        compSetTI
        compSetTIMonthly
        compSetFreeRentMonths
        timeMeasurement
      }
    }
  }
`;

export const SUBMARKET_HISTORICAL_RENT = gql`
  query submarketHistoricalRent($params: SubmarketHistoricalRentInput!) {
    submarketHistoricalRent(params: $params) {
      rentTypeFS {
        year
        compSetValue
        compSetValueMonthly
        compSetTI
        compSetTIMonthly
        compSetFreeRentMonths
        timeMeasurement
      }
      rentTypeNNN {
        year
        compSetValue
        compSetValueMonthly
        compSetTI
        compSetTIMonthly
        compSetFreeRentMonths
        timeMeasurement
      }
    }
  }
`;

export const SUBMARKET_SALES_VOLUME = gql`
  query submarketSalesVolume($params: SubmarketSalesVolumeInput!) {
    submarketSalesVolume(params: $params) {
      quarter
      year
      total
      topItems
    }
  }
`;

export const MARKET_ANALYTICS_SALES_VOLUME = gql`
  query marketAnalyticsSalesVolume($params: MarketAnalyticsSalesVolumeInput!) {
    marketAnalyticsSalesVolume(params: $params) {
      quarter
      year
      total
      topItems
    }
  }
`;

export const ASSET_PERFORMANCES_TABLE_PROPERTY = gql`
  query assetPerformances($propertyId: Int!) {
    assetPerformances(
      propertyId: $propertyId
      options: { allRecordsPerYear: true }
    ) {
      id
      date
      availableSfDirect
      availableSfSublet
      vacantSfDirect
      vacantSfSublet
      availableSfShell
      availableKwTurnkey
      vacantSfShell
      vacantKwTurnkey
      baseRent
      timeMeasurement
      rentType
      propertyId
      notes
    }
  }
`;

export const MARKET_PRICING_QUERY = gql`
  query marketPricing($filter: ListMarketPricingInput!) {
    marketPricing(filter: $filter) {
      id
      year
      date
      directRent
      darPercent
      tiLongTerm
      tiPercent
      freeRentLongTerm
      rentType
      timeMeasurement
    }
  }
`;
