import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { getCurrencySymbol } from 'utils/formatters/currency';
import { translateText } from 'utils/i18n';

export const PROPERTIES_POPUP_INIT = {
  propertiesFilter: undefined,
  hideAction: undefined,
  isVisible: false,
  popupFilter: undefined,
  popupSubtitle: '',
  popupTitle: '',
};

export const MARKET_CONTEXT_INIT = {
  isLoading: true,
  unitOfMeasurement: UnitOfMeasurement.sf,
  currencySymbol: getCurrencySymbol(),
  propertiesPopup: PROPERTIES_POPUP_INIT,
};

export const COMPOSITION_SECTION = 'composition';
export const PERFORMANCE_SECTION = 'performance';
export const RECENT_ACTIVITY_SECTION = 'recent-activity';
export const DEMOGRAPHICS_SECTION = 'demographics';

export const MARKET_PAGE_SECTIONS = {
  COMPOSITION_SECTION,
  PERFORMANCE_SECTION,
  RECENT_ACTIVITY_SECTION,
  DEMOGRAPHICS_SECTION,
};

export const MARKET_DEFAULT_TITLE = translateText(
  `${I18N_PLATFORM_COMMON_WORD_PATH}.market`,
);
