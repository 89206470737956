import React from 'react';

import FormFooter from 'components/FormFooter';
import LoadingMessage from 'components/LoadingMessage';
import { SimpleTable } from 'components/SimpleTable';
import { useFocusedCell } from 'components/SimpleTable/useFocusedCell';
import { I18N_USER_SETTINGS_LABEL_PATH } from 'constants/i18n';
import { translateText } from 'utils/i18n';
import {
  formatNumberWithCommas,
  normalizeNumber,
} from 'utils/formatters/number';

import { SegmentPricingIds, SegmentPricingParams } from '../interfaces';
import { RadioControl } from './RadioControl';
import SegmentControl from './SegmentControl';
import { useExtraRows } from './useExtraRows';
import { useInputHandlers } from './useInputHandlers';
import { useSegmentPricingData } from './useSegmentPricingData';
import { getTableColumns } from './tableData';
import { calculatePercentages } from './utils';
import styles from '../HistoricalPerformanceModal.module.scss';

interface Props {
  segmentPricingParams: SegmentPricingParams | undefined;
  setSegmentPricingParams: (data: SegmentPricingParams) => void;
  onClose: () => void;
}
export const SegmentPricing = ({
  segmentPricingParams,
  setSegmentPricingParams,
  onClose,
}: Props) => {
  const { variables, handlers, loadings } = useSegmentPricingData({
    segmentPricingParams,
    onClose,
  });

  const onChangeData = (
    rowIndex: number,
    columnId: string,
    value: string | number | null,
  ) => {
    handlers.setLocalData(prevData =>
      prevData.map((row, index) =>
        index === rowIndex ? { ...row, [columnId]: value } : row,
      ),
    );
    setFocusedCell({ row: rowIndex, column: columnId });
  };

  const { setFocusedCell, defineIsFocusedCell, onCellFocus } = useFocusedCell();
  const { handleDateChange, handleInputChange } = useInputHandlers(
    onChangeData,
  );
  const extraRows = useExtraRows({
    localData: variables.localData,
    setLocalData: handlers.setLocalData,
    segmentPricingParams,
  });

  const handleTableDataChange = (
    rowIndex: number,
    columnId: string,
    value: string | number | null,
    isDate: boolean,
  ) => {
    return isDate
      ? handleDateChange(rowIndex, columnId, value)
      : handleInputChange(rowIndex, columnId, value);
  };

  const onInputBlur = (
    rowIndex: number,
    value: string | number | null,
    columnId: string,
  ) => {
    const formatters: Record<
      string,
      (value: string | number | null) => string | number | null
    > = {
      [SegmentPricingIds.directRent]: formatNumberWithCommas,
    };
    const formatter = formatters[columnId] || normalizeNumber;
    handleTableDataChange(rowIndex, columnId, formatter(value), false);

    if (
      columnId === SegmentPricingIds.directRent ||
      columnId === SegmentPricingIds.tiLongTerm
    ) {
      calculatePercentages(
        rowIndex,
        value,
        columnId,
        variables.localData,
        handleTableDataChange,
      );
    }
    setFocusedCell(null);
  };

  const columns = getTableColumns(
    handleTableDataChange,
    onInputBlur,
    defineIsFocusedCell,
    onCellFocus,
    handlers.validateDuplicateQuarters,
    variables.dateValidations,
  );

  if (loadings.marketPricingLoading) return <LoadingMessage />;

  return (
    <div className={styles['container']}>
      <SegmentControl segmentPricingParams={segmentPricingParams} />
      <RadioControl
        segmentPricingParams={segmentPricingParams}
        setSegmentPricingParams={params => {
          setFocusedCell(null);
          setSegmentPricingParams(params);
        }}
      />

      <SimpleTable
        data={variables.localData}
        columns={columns}
        extraRows={extraRows}
      />

      <FormFooter
        onSubmit={handlers.saveSegmentPricing}
        isSubmitDisabled={variables.isDisabledSubmit}
        onCancel={onClose}
        submitButtonLabel={translateText(
          `${I18N_USER_SETTINGS_LABEL_PATH}.saveChanges`,
        )}
      />
    </div>
  );
};
