import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import MarketPropertyDataFilter from 'components/MarketPropertyDataFilter';
import { MarketPropertyDataFiltersType } from 'components/MarketPropertyDataFilter/constants';

import HistoricalPerformanceModal from '.';
import { SegmentPricingParams } from './interfaces';
import { SegmentPricing } from './SegmentPricing';

type Props = {
  onClose: () => void;
};

const CreateHistoricalPerformance: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();

  const [segmentPricingParams, setSegmentPricingParams] = useState<
    SegmentPricingParams
  >();

  const onChangeFilters = (filters: MarketPropertyDataFiltersType) => {
    const {
      market,
      submarket,
      propertyType,
      propertyClass,
      propertySubtype,
    } = filters;

    setSegmentPricingParams({
      ...segmentPricingParams,
      market,
      submarket,
      propertyType,
      propertyClass,
      propertySubtype,
    } as SegmentPricingParams);
  };

  return (
    <HistoricalPerformanceModal
      onClose={onClose}
      title={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.addPerformance`)}
    >
      <MarketPropertyDataFilter
        filters={{
          market: segmentPricingParams?.market,
          submarket: segmentPricingParams?.submarket,
          propertyClass: segmentPricingParams?.propertyClass,
          propertyType: segmentPricingParams?.propertyType,
          propertySubtype: segmentPricingParams?.propertySubtype,
        }}
        onChangeFilters={onChangeFilters}
      />

      {segmentPricingParams && (
        <SegmentPricing
          segmentPricingParams={segmentPricingParams}
          setSegmentPricingParams={setSegmentPricingParams}
          onClose={onClose}
        />
      )}
    </HistoricalPerformanceModal>
  );
};

export default CreateHistoricalPerformance;
