import React from 'react';
import classnames from 'classnames';
import { Column, useTable, TableOptions } from 'react-table';

import styles from './SimpleTable.module.scss';

export interface FocusedCell {
  row: number;
  column: string;
}

interface SimpleTableProps<T extends object> {
  data: readonly T[];
  columns: Column<T>[];
  extraRows?: {
    rows: T[];
    onExtraRowClick: () => void;
    extraRowClassName: string;
    extraCelLClassName: string;
  };
}

export const SimpleTable = <T extends object>({
  data,
  columns,
  extraRows,
}: SimpleTableProps<T>) => {
  const tableOptions: TableOptions<T> = {
    columns,
    data: [...data, ...(extraRows?.rows ?? [])],
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable<T>(tableOptions);

  return (
    <table {...getTableProps()} className={styles['table']}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()} key={`${headerGroup.id}`}>
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps()}
                key={column.id}
                className={styles['header-row']}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          const isExtraRow = index >= data.length;

          return (
            <tr
              {...row.getRowProps()}
              onClick={() => {
                if (isExtraRow && extraRows?.onExtraRowClick) {
                  extraRows.onExtraRowClick();
                }
              }}
              key={`${row.id}${row.getRowProps().key}`}
              className={classnames({
                [extraRows?.extraRowClassName!]:
                  isExtraRow && extraRows?.extraRowClassName,
              })}
            >
              {row.cells.map(cell => (
                <td
                  {...cell.getCellProps()}
                  key={`${cell.row.id}${cell.getCellProps().key}`}
                  className={classnames(styles['row-cell'], {
                    [extraRows?.extraCelLClassName!]:
                      isExtraRow && extraRows?.extraCelLClassName,
                  })}
                >
                  <div className={isExtraRow ? styles['extra-row'] : undefined}>
                    {cell.render('Cell', {
                      readOnly: isExtraRow,
                    })}
                  </div>
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
